/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*====== All Custom Variable Interduce Here ======*/
:root {
  --light-yellow: #FFF59D;
  --twitter: #52AEFF;
  --facebook: #405DE6;
  --google: #F13A30;
  --pinterest: #d32f2f;
  --instagram: #8d6e63;
  --rss: #ff9800;
  --tumblr: #607d8b;
  --linkedin: #0876DE;
  --dribbble: #f06292;
  --youtube: #d50000;
  --github: #424242;
  --skype: #4fc3f7;
  --blue: #5D92F4;
  --indigo: #174176;
  --purple: #9370DB;
  --pink: #FF2B72;
  --red: #FF3739;
  --orange: #FF7F50;
  --yellow: #FFB70F;
  --green: #00D014;
  --teal: #677080;
  --cyan: #00D0BD;
  --white: #fff;
  --gray: #727891;
  --gray-dark: #677080;
  --light-yellow: #FFF59D;
  --twitter: #52AEFF;
  --facebook: #405DE6;
  --google: #F13A30;
  --pinterest: #d32f2f;
  --instagram: #8d6e63;
  --rss: #ff9800;
  --tumblr: #607d8b;
  --linkedin: #0876DE;
  --dribbble: #f06292;
  --youtube: #d50000;
  --github: #424242;
  --skype: #4fc3f7;
  --primary: #FA870E;
  --secondary: #677080;
  --success: #00D014;
  --info: #00D0BD;
  --warning: #FFB70F;
  --danger: #FF3739;
  --light: #FAFAFA;
  --dark: #464D69;
  --orange: #FF7F50;
  --purple: #9370DB;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Heebo", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Heebo", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #464D69;
  text-align: left;
  background-color: #F4F7FA; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #FA870E;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #b86004;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #727891;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 1.5rem; }

h2, .h2 {
  font-size: 1.25rem; }

h3, .h3 {
  font-size: 1.125rem; }

h4, .h4 {
  font-size: 1rem; }

h5, .h5 {
  font-size: 0.875rem; }

h6, .h6 {
  font-size: 0.75rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #727891; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F4F7FA;
  border: 1px solid #EBEDF2;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #727891; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #FF2B72;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #464D69;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #464D69; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #EBEDF2; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #EBEDF2; }
  .table tbody + tbody {
    border-top: 2px solid #EBEDF2; }
  .table .table {
    background-color: #F4F7FA; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #EBEDF2; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #EBEDF2; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(244, 247, 250, 0.7); }

.table-light-yellow,
.table-light-yellow > th,
.table-light-yellow > td {
  background-color: #fffce4; }

.table-hover .table-light-yellow:hover {
  background-color: #fff9cb; }
  .table-hover .table-light-yellow:hover > td,
  .table-hover .table-light-yellow:hover > th {
    background-color: #fff9cb; }

.table-twitter,
.table-twitter > th,
.table-twitter > td {
  background-color: #cfe8ff; }

.table-hover .table-twitter:hover {
  background-color: #b6dcff; }
  .table-hover .table-twitter:hover > td,
  .table-hover .table-twitter:hover > th {
    background-color: #b6dcff; }

.table-facebook,
.table-facebook > th,
.table-facebook > td {
  background-color: #cad2f8; }

.table-hover .table-facebook:hover {
  background-color: #b3bff5; }
  .table-hover .table-facebook:hover > td,
  .table-hover .table-facebook:hover > th {
    background-color: #b3bff5; }

.table-google,
.table-google > th,
.table-google > td {
  background-color: #fbc8c5; }

.table-hover .table-google:hover {
  background-color: #f9b1ad; }
  .table-hover .table-google:hover > td,
  .table-hover .table-google:hover > th {
    background-color: #f9b1ad; }

.table-pinterest,
.table-pinterest > th,
.table-pinterest > td {
  background-color: #f3c5c5; }

.table-hover .table-pinterest:hover {
  background-color: #efb0b0; }
  .table-hover .table-pinterest:hover > td,
  .table-hover .table-pinterest:hover > th {
    background-color: #efb0b0; }

.table-instagram,
.table-instagram > th,
.table-instagram > td {
  background-color: #dfd6d3; }

.table-hover .table-instagram:hover {
  background-color: #d4c8c4; }
  .table-hover .table-instagram:hover > td,
  .table-hover .table-instagram:hover > th {
    background-color: #d4c8c4; }

.table-rss,
.table-rss > th,
.table-rss > td {
  background-color: #ffe2b8; }

.table-hover .table-rss:hover {
  background-color: #ffd89f; }
  .table-hover .table-rss:hover > td,
  .table-hover .table-rss:hover > th {
    background-color: #ffd89f; }

.table-tumblr,
.table-tumblr > th,
.table-tumblr > td {
  background-color: #d2dbdf; }

.table-hover .table-tumblr:hover {
  background-color: #c3cfd4; }
  .table-hover .table-tumblr:hover > td,
  .table-hover .table-tumblr:hover > th {
    background-color: #c3cfd4; }

.table-linkedin,
.table-linkedin > th,
.table-linkedin > td {
  background-color: #bad9f6; }

.table-hover .table-linkedin:hover {
  background-color: #a3cdf3; }
  .table-hover .table-linkedin:hover > td,
  .table-hover .table-linkedin:hover > th {
    background-color: #a3cdf3; }

.table-dribbble,
.table-dribbble > th,
.table-dribbble > td {
  background-color: #fbd3e0; }

.table-hover .table-dribbble:hover {
  background-color: #f9bcd0; }
  .table-hover .table-dribbble:hover > td,
  .table-hover .table-dribbble:hover > th {
    background-color: #f9bcd0; }

.table-youtube,
.table-youtube > th,
.table-youtube > td {
  background-color: #f3b8b8; }

.table-hover .table-youtube:hover {
  background-color: #efa2a2; }
  .table-hover .table-youtube:hover > td,
  .table-hover .table-youtube:hover > th {
    background-color: #efa2a2; }

.table-github,
.table-github > th,
.table-github > td {
  background-color: #cacaca; }

.table-hover .table-github:hover {
  background-color: #bdbdbd; }
  .table-hover .table-github:hover > td,
  .table-hover .table-github:hover > th {
    background-color: #bdbdbd; }

.table-skype,
.table-skype > th,
.table-skype > td {
  background-color: #ceeefd; }

.table-hover .table-skype:hover {
  background-color: #b6e6fc; }
  .table-hover .table-skype:hover > td,
  .table-hover .table-skype:hover > th {
    background-color: #b6e6fc; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #feddbc; }

.table-hover .table-primary:hover {
  background-color: #fed0a3; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #fed0a3; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d4d7db; }

.table-hover .table-secondary:hover {
  background-color: #c6cacf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c6cacf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8f2bd; }

.table-hover .table-success:hover {
  background-color: #a2eea9; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a2eea9; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8f2ed; }

.table-hover .table-info:hover {
  background-color: #a2eee8; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a2eee8; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffebbc; }

.table-hover .table-warning:hover {
  background-color: #ffe3a3; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe3a3; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffc7c8; }

.table-hover .table-danger:hover {
  background-color: #ffaeaf; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffaeaf; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cbcdd5; }

.table-hover .table-dark:hover {
  background-color: #bdbfca; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bdbfca; }

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #ffdbce; }

.table-hover .table-orange:hover {
  background-color: #ffc8b5; }
  .table-hover .table-orange:hover > td,
  .table-hover .table-orange:hover > th {
    background-color: #ffc8b5; }

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #e1d7f5; }

.table-hover .table-purple:hover {
  background-color: #d2c3f0; }
  .table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #d2c3f0; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(244, 247, 250, 0.7); }

.table-hover .table-active:hover {
  background-color: rgba(226, 234, 242, 0.7); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(226, 234, 242, 0.7); }

.table .thead-dark th {
  color: #F4F7FA;
  background-color: #464D69;
  border-color: #555e80; }

.table .thead-light th {
  color: #464D69;
  background-color: #F4F7FA;
  border-color: #EBEDF2; }

.table-dark {
  color: #F4F7FA;
  background-color: #464D69; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #555e80; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #464D69;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #EBEDF2;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #464D69;
    background-color: #fff;
    border-color: #fdc58b;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(250, 135, 14, 0.25); }
  .form-control::placeholder {
    color: #727891;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #F4F7FA;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #464D69;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1.563rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #727891; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00D014; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(0, 208, 20, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #00D014; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #00D014;
    box-shadow: 0 0 0 0.2rem rgba(0, 208, 20, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00D014; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00D014; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #51ff61; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background: #04ff1c linear-gradient(180deg, #28fe3d, #04ff1c) repeat-x; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F4F7FA, 0 0 0 0.2rem rgba(0, 208, 20, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00D014; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(0, 208, 20, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #FF3739; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(255, 55, 57, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #FF3739; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #FF3739;
    box-shadow: 0 0 0 0.2rem rgba(255, 55, 57, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #FF3739; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #FF3739; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #ffb7b7; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background: #ff6a6b linear-gradient(180deg, #fd7f81, #ff6a6b) repeat-x; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F4F7FA, 0 0 0 0.2rem rgba(255, 55, 57, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #FF3739; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 55, 57, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(250, 135, 14, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 135, 14, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-light-yellow {
  color: #464D69;
  background: #FFF59D linear-gradient(180deg, #fdf5ab, #FFF59D) repeat-x;
  border-color: #FFF59D;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light-yellow:hover {
    color: #464D69;
    background: #fff177 linear-gradient(180deg, #fdf28a, #fff177) repeat-x;
    border-color: #fff06a; }
  .btn-light-yellow:focus, .btn-light-yellow.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 245, 157, 0.5); }
  .btn-light-yellow.disabled, .btn-light-yellow:disabled {
    color: #464D69;
    background-color: #FFF59D;
    border-color: #FFF59D; }
  .btn-light-yellow:not(:disabled):not(.disabled):active, .btn-light-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-light-yellow.dropdown-toggle {
    color: #464D69;
    background-color: #fff06a;
    background-image: none;
    border-color: #ffee5d; }
    .btn-light-yellow:not(:disabled):not(.disabled):active:focus, .btn-light-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light-yellow.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 245, 157, 0.5); }

.btn-twitter {
  color: #464D69;
  background: #52AEFF linear-gradient(180deg, #6ab9fe, #52AEFF) repeat-x;
  border-color: #52AEFF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-twitter:hover {
    color: #fff;
    background: #2c9cff linear-gradient(180deg, #4aaafe, #2c9cff) repeat-x;
    border-color: #1f96ff; }
  .btn-twitter:focus, .btn-twitter.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 174, 255, 0.5); }
  .btn-twitter.disabled, .btn-twitter:disabled {
    color: #464D69;
    background-color: #52AEFF;
    border-color: #52AEFF; }
  .btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #1f96ff;
    background-image: none;
    border-color: #1290ff; }
    .btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-twitter.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 174, 255, 0.5); }

.btn-facebook {
  color: #fff;
  background: #405DE6 linear-gradient(180deg, #5b74e9, #405DE6) repeat-x;
  border-color: #405DE6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-facebook:hover {
    color: #fff;
    background: #1e40e2 linear-gradient(180deg, #3e5ce5, #1e40e2) repeat-x;
    border-color: #1c3dd7; }
  .btn-facebook:focus, .btn-facebook.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(64, 93, 230, 0.5); }
  .btn-facebook.disabled, .btn-facebook:disabled {
    color: #fff;
    background-color: #405DE6;
    border-color: #405DE6; }
  .btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #1c3dd7;
    background-image: none;
    border-color: #1b3acc; }
    .btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
    .show > .btn-facebook.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(64, 93, 230, 0.5); }

.btn-google {
  color: #fff;
  background: #F13A30 linear-gradient(180deg, #f1564e, #F13A30) repeat-x;
  border-color: #F13A30;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-google:hover {
    color: #fff;
    background: #eb1b10 linear-gradient(180deg, #ec3c33, #eb1b10) repeat-x;
    border-color: #df1a0f; }
  .btn-google:focus, .btn-google.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(241, 58, 48, 0.5); }
  .btn-google.disabled, .btn-google:disabled {
    color: #fff;
    background-color: #F13A30;
    border-color: #F13A30; }
  .btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active,
  .show > .btn-google.dropdown-toggle {
    color: #fff;
    background-color: #df1a0f;
    background-image: none;
    border-color: #d3180e; }
    .btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus,
    .show > .btn-google.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(241, 58, 48, 0.5); }

.btn-pinterest {
  color: #fff;
  background: #d32f2f linear-gradient(180deg, #d84d4d, #d32f2f) repeat-x;
  border-color: #d32f2f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-pinterest:hover {
    color: #fff;
    background: #b52626 linear-gradient(180deg, #bf4646, #b52626) repeat-x;
    border-color: #ab2424; }
  .btn-pinterest:focus, .btn-pinterest.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(211, 47, 47, 0.5); }
  .btn-pinterest.disabled, .btn-pinterest:disabled {
    color: #fff;
    background-color: #d32f2f;
    border-color: #d32f2f; }
  .btn-pinterest:not(:disabled):not(.disabled):active, .btn-pinterest:not(:disabled):not(.disabled).active,
  .show > .btn-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #ab2424;
    background-image: none;
    border-color: #a02222; }
    .btn-pinterest:not(:disabled):not(.disabled):active:focus, .btn-pinterest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pinterest.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(211, 47, 47, 0.5); }

.btn-instagram {
  color: #fff;
  background: #8d6e63 linear-gradient(180deg, #9c837a, #8d6e63) repeat-x;
  border-color: #8d6e63;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-instagram:hover {
    color: #fff;
    background: #775c53 linear-gradient(180deg, #89746c, #775c53) repeat-x;
    border-color: #6f574e; }
  .btn-instagram:focus, .btn-instagram.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(141, 110, 99, 0.5); }
  .btn-instagram.disabled, .btn-instagram:disabled {
    color: #fff;
    background-color: #8d6e63;
    border-color: #8d6e63; }
  .btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active,
  .show > .btn-instagram.dropdown-toggle {
    color: #fff;
    background-color: #6f574e;
    background-image: none;
    border-color: #685149; }
    .btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus,
    .show > .btn-instagram.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(141, 110, 99, 0.5); }

.btn-rss {
  color: #464D69;
  background: #ff9800 linear-gradient(180deg, #fda626, #ff9800) repeat-x;
  border-color: #ff9800;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-rss:hover {
    color: #fff;
    background: #d98100 linear-gradient(180deg, #dd9326, #d98100) repeat-x;
    border-color: #cc7a00; }
  .btn-rss:focus, .btn-rss.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .btn-rss.disabled, .btn-rss:disabled {
    color: #464D69;
    background-color: #ff9800;
    border-color: #ff9800; }
  .btn-rss:not(:disabled):not(.disabled):active, .btn-rss:not(:disabled):not(.disabled).active,
  .show > .btn-rss.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    background-image: none;
    border-color: #bf7200; }
    .btn-rss:not(:disabled):not(.disabled):active:focus, .btn-rss:not(:disabled):not(.disabled).active:focus,
    .show > .btn-rss.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.btn-tumblr {
  color: #fff;
  background: #607d8b linear-gradient(180deg, #768f9c, #607d8b) repeat-x;
  border-color: #607d8b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-tumblr:hover {
    color: #fff;
    background: #506974 linear-gradient(180deg, #697e88, #506974) repeat-x;
    border-color: #4b626d; }
  .btn-tumblr:focus, .btn-tumblr.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(96, 125, 139, 0.5); }
  .btn-tumblr.disabled, .btn-tumblr:disabled {
    color: #fff;
    background-color: #607d8b;
    border-color: #607d8b; }
  .btn-tumblr:not(:disabled):not(.disabled):active, .btn-tumblr:not(:disabled):not(.disabled).active,
  .show > .btn-tumblr.dropdown-toggle {
    color: #fff;
    background-color: #4b626d;
    background-image: none;
    border-color: #465b65; }
    .btn-tumblr:not(:disabled):not(.disabled):active:focus, .btn-tumblr:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tumblr.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(96, 125, 139, 0.5); }

.btn-linkedin {
  color: #fff;
  background: #0876DE linear-gradient(180deg, #2b89e2, #0876DE) repeat-x;
  border-color: #0876DE;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-linkedin:hover {
    color: #fff;
    background: #0762b9 linear-gradient(180deg, #2a79c3, #0762b9) repeat-x;
    border-color: #065cad; }
  .btn-linkedin:focus, .btn-linkedin.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(8, 118, 222, 0.5); }
  .btn-linkedin.disabled, .btn-linkedin:disabled {
    color: #fff;
    background-color: #0876DE;
    border-color: #0876DE; }
  .btn-linkedin:not(:disabled):not(.disabled):active, .btn-linkedin:not(:disabled):not(.disabled).active,
  .show > .btn-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #065cad;
    background-image: none;
    border-color: #0655a0; }
    .btn-linkedin:not(:disabled):not(.disabled):active:focus, .btn-linkedin:not(:disabled):not(.disabled).active:focus,
    .show > .btn-linkedin.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(8, 118, 222, 0.5); }

.btn-dribbble {
  color: #fff;
  background: #f06292 linear-gradient(180deg, #f178a2, #f06292) repeat-x;
  border-color: #f06292;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dribbble:hover {
    color: #fff;
    background: #ed3f7a linear-gradient(180deg, #ee5b8d, #ed3f7a) repeat-x;
    border-color: #ec3372; }
  .btn-dribbble:focus, .btn-dribbble.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(240, 98, 146, 0.5); }
  .btn-dribbble.disabled, .btn-dribbble:disabled {
    color: #fff;
    background-color: #f06292;
    border-color: #f06292; }
  .btn-dribbble:not(:disabled):not(.disabled):active, .btn-dribbble:not(:disabled):not(.disabled).active,
  .show > .btn-dribbble.dropdown-toggle {
    color: #fff;
    background-color: #ec3372;
    background-image: none;
    border-color: #ea286a; }
    .btn-dribbble:not(:disabled):not(.disabled):active:focus, .btn-dribbble:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dribbble.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(240, 98, 146, 0.5); }

.btn-youtube {
  color: #fff;
  background: #d50000 linear-gradient(180deg, #da2526, #d50000) repeat-x;
  border-color: #d50000;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-youtube:hover {
    color: #fff;
    background: #af0000 linear-gradient(180deg, #b92526, #af0000) repeat-x;
    border-color: #a20000; }
  .btn-youtube:focus, .btn-youtube.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(213, 0, 0, 0.5); }
  .btn-youtube.disabled, .btn-youtube:disabled {
    color: #fff;
    background-color: #d50000;
    border-color: #d50000; }
  .btn-youtube:not(:disabled):not(.disabled):active, .btn-youtube:not(:disabled):not(.disabled).active,
  .show > .btn-youtube.dropdown-toggle {
    color: #fff;
    background-color: #a20000;
    background-image: none;
    border-color: #950000; }
    .btn-youtube:not(:disabled):not(.disabled):active:focus, .btn-youtube:not(:disabled):not(.disabled).active:focus,
    .show > .btn-youtube.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(213, 0, 0, 0.5); }

.btn-github {
  color: #fff;
  background: #424242 linear-gradient(180deg, #5d5d5e, #424242) repeat-x;
  border-color: #424242;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-github:hover {
    color: #fff;
    background: #2f2f2f linear-gradient(180deg, #4c4d4d, #2f2f2f) repeat-x;
    border-color: #292929; }
  .btn-github:focus, .btn-github.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }
  .btn-github.disabled, .btn-github:disabled {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
  .btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active,
  .show > .btn-github.dropdown-toggle {
    color: #fff;
    background-color: #292929;
    background-image: none;
    border-color: #222222; }
    .btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus,
    .show > .btn-github.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }

.btn-skype {
  color: #464D69;
  background: #4fc3f7 linear-gradient(180deg, #68cbf7, #4fc3f7) repeat-x;
  border-color: #4fc3f7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-skype:hover {
    color: #fff;
    background: #2ab7f5 linear-gradient(180deg, #49c0f6, #2ab7f5) repeat-x;
    border-color: #1eb2f5; }
  .btn-skype:focus, .btn-skype.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(79, 195, 247, 0.5); }
  .btn-skype.disabled, .btn-skype:disabled {
    color: #464D69;
    background-color: #4fc3f7;
    border-color: #4fc3f7; }
  .btn-skype:not(:disabled):not(.disabled):active, .btn-skype:not(:disabled):not(.disabled).active,
  .show > .btn-skype.dropdown-toggle {
    color: #fff;
    background-color: #1eb2f5;
    background-image: none;
    border-color: #12aef4; }
    .btn-skype:not(:disabled):not(.disabled):active:focus, .btn-skype:not(:disabled):not(.disabled).active:focus,
    .show > .btn-skype.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(79, 195, 247, 0.5); }

.btn-primary {
  color: #464D69;
  background: #FA870E linear-gradient(180deg, #f99831, #FA870E) repeat-x;
  border-color: #FA870E;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: #fff;
    background: #dd7405 linear-gradient(180deg, #e18729, #dd7405) repeat-x;
    border-color: #d16d04; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(250, 135, 14, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #464D69;
    background-color: #FA870E;
    border-color: #FA870E; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #d16d04;
    background-image: none;
    border-color: #c46704; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(250, 135, 14, 0.5); }

.btn-secondary {
  color: #fff;
  background: #677080 linear-gradient(180deg, #7c8492, #677080) repeat-x;
  border-color: #677080;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover {
    color: #fff;
    background: #565d6b linear-gradient(180deg, #6e7480, #565d6b) repeat-x;
    border-color: #505764; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(103, 112, 128, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #677080;
    border-color: #677080; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #505764;
    background-image: none;
    border-color: #4b515d; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(103, 112, 128, 0.5); }

.btn-success {
  color: #fff;
  background: #00D014 linear-gradient(180deg, #25d637, #00D014) repeat-x;
  border-color: #00D014;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: #fff;
    background: #00aa10 linear-gradient(180deg, #25b533, #00aa10) repeat-x;
    border-color: #009d0f; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 208, 20, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #00D014;
    border-color: #00D014; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #009d0f;
    background-image: none;
    border-color: #00900e; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 208, 20, 0.5); }

.btn-info {
  color: #fff;
  background: #00D0BD linear-gradient(180deg, #25d6c6, #00D0BD) repeat-x;
  border-color: #00D0BD;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: #fff;
    background: #00aa9a linear-gradient(180deg, #25b5a9, #00aa9a) repeat-x;
    border-color: #009d8f; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 208, 189, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00D0BD;
    border-color: #00D0BD; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #009d8f;
    background-image: none;
    border-color: #009083; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 208, 189, 0.5); }

.btn-warning {
  color: #464D69;
  background: #FFB70F linear-gradient(180deg, #fdc132, #FFB70F) repeat-x;
  border-color: #FFB70F;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: #464D69;
    background: #e8a200 linear-gradient(180deg, #eaaf26, #e8a200) repeat-x;
    border-color: #db9900; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 183, 15, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #464D69;
    background-color: #FFB70F;
    border-color: #FFB70F; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #464D69;
    background-color: #db9900;
    background-image: none;
    border-color: #ce9000; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 183, 15, 0.5); }

.btn-danger {
  color: #fff;
  background: #FF3739 linear-gradient(180deg, #fd5456, #FF3739) repeat-x;
  border-color: #FF3739;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: #fff;
    background: #ff1113 linear-gradient(180deg, #fd3336, #ff1113) repeat-x;
    border-color: #ff0407; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 55, 57, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #FF3739;
    border-color: #FF3739; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff0407;
    background-image: none;
    border-color: #f60002; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 55, 57, 0.5); }

.btn-light {
  color: #464D69;
  background: #FAFAFA linear-gradient(180deg, #f9fafa, #FAFAFA) repeat-x;
  border-color: #FAFAFA;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover {
    color: #464D69;
    background: #e7e7e7 linear-gradient(180deg, #e9e9ea, #e7e7e7) repeat-x;
    border-color: #e1e1e1; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #464D69;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #464D69;
    background-color: #e1e1e1;
    background-image: none;
    border-color: #dadada; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-dark {
  color: #fff;
  background: #464D69 linear-gradient(180deg, #60677f, #464D69) repeat-x;
  border-color: #464D69;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: #fff;
    background: #373c52 linear-gradient(180deg, #53586b, #373c52) repeat-x;
    border-color: #32374a; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(70, 77, 105, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #464D69;
    border-color: #464D69; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #32374a;
    background-image: none;
    border-color: #2d3143; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(70, 77, 105, 0.5); }

.btn-orange {
  color: #464D69;
  background: #FF7F50 linear-gradient(180deg, #fd916a, #FF7F50) repeat-x;
  border-color: #FF7F50;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-orange:hover {
    color: #fff;
    background: #ff632a linear-gradient(180deg, #fd7949, #ff632a) repeat-x;
    border-color: #ff5a1d; }
  .btn-orange:focus, .btn-orange.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 127, 80, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #464D69;
    background-color: #FF7F50;
    border-color: #FF7F50; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #ff5a1d;
    background-image: none;
    border-color: #ff5010; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 127, 80, 0.5); }

.btn-purple {
  color: #fff;
  background: #9370DB linear-gradient(180deg, #a284e0, #9370DB) repeat-x;
  border-color: #9370DB;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-purple:hover {
    color: #fff;
    background: #7c51d3 linear-gradient(180deg, #8e6ad9, #7c51d3) repeat-x;
    border-color: #7447d1; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(147, 112, 219, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #9370DB;
    border-color: #9370DB; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #7447d1;
    background-image: none;
    border-color: #6d3dce; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(147, 112, 219, 0.5); }

.btn-outline-light-yellow {
  color: #FFF59D;
  background-color: transparent;
  background-image: none;
  border-color: #FFF59D; }
  .btn-outline-light-yellow:hover {
    color: #464D69;
    background-color: #FFF59D;
    border-color: #FFF59D; }
  .btn-outline-light-yellow:focus, .btn-outline-light-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 245, 157, 0.5); }
  .btn-outline-light-yellow.disabled, .btn-outline-light-yellow:disabled {
    color: #FFF59D;
    background-color: transparent; }
  .btn-outline-light-yellow:not(:disabled):not(.disabled):active, .btn-outline-light-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light-yellow.dropdown-toggle {
    color: #464D69;
    background-color: #FFF59D;
    border-color: #FFF59D; }
    .btn-outline-light-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-light-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light-yellow.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 245, 157, 0.5); }

.btn-outline-twitter {
  color: #52AEFF;
  background-color: transparent;
  background-image: none;
  border-color: #52AEFF; }
  .btn-outline-twitter:hover {
    color: #464D69;
    background-color: #52AEFF;
    border-color: #52AEFF; }
  .btn-outline-twitter:focus, .btn-outline-twitter.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 174, 255, 0.5); }
  .btn-outline-twitter.disabled, .btn-outline-twitter:disabled {
    color: #52AEFF;
    background-color: transparent; }
  .btn-outline-twitter:not(:disabled):not(.disabled):active, .btn-outline-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-outline-twitter.dropdown-toggle {
    color: #464D69;
    background-color: #52AEFF;
    border-color: #52AEFF; }
    .btn-outline-twitter:not(:disabled):not(.disabled):active:focus, .btn-outline-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-twitter.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 174, 255, 0.5); }

.btn-outline-facebook {
  color: #405DE6;
  background-color: transparent;
  background-image: none;
  border-color: #405DE6; }
  .btn-outline-facebook:hover {
    color: #fff;
    background-color: #405DE6;
    border-color: #405DE6; }
  .btn-outline-facebook:focus, .btn-outline-facebook.focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 93, 230, 0.5); }
  .btn-outline-facebook.disabled, .btn-outline-facebook:disabled {
    color: #405DE6;
    background-color: transparent; }
  .btn-outline-facebook:not(:disabled):not(.disabled):active, .btn-outline-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-outline-facebook.dropdown-toggle {
    color: #fff;
    background-color: #405DE6;
    border-color: #405DE6; }
    .btn-outline-facebook:not(:disabled):not(.disabled):active:focus, .btn-outline-facebook:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-facebook.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(64, 93, 230, 0.5); }

.btn-outline-google {
  color: #F13A30;
  background-color: transparent;
  background-image: none;
  border-color: #F13A30; }
  .btn-outline-google:hover {
    color: #fff;
    background-color: #F13A30;
    border-color: #F13A30; }
  .btn-outline-google:focus, .btn-outline-google.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 58, 48, 0.5); }
  .btn-outline-google.disabled, .btn-outline-google:disabled {
    color: #F13A30;
    background-color: transparent; }
  .btn-outline-google:not(:disabled):not(.disabled):active, .btn-outline-google:not(:disabled):not(.disabled).active,
  .show > .btn-outline-google.dropdown-toggle {
    color: #fff;
    background-color: #F13A30;
    border-color: #F13A30; }
    .btn-outline-google:not(:disabled):not(.disabled):active:focus, .btn-outline-google:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-google.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(241, 58, 48, 0.5); }

.btn-outline-pinterest {
  color: #d32f2f;
  background-color: transparent;
  background-image: none;
  border-color: #d32f2f; }
  .btn-outline-pinterest:hover {
    color: #fff;
    background-color: #d32f2f;
    border-color: #d32f2f; }
  .btn-outline-pinterest:focus, .btn-outline-pinterest.focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 47, 47, 0.5); }
  .btn-outline-pinterest.disabled, .btn-outline-pinterest:disabled {
    color: #d32f2f;
    background-color: transparent; }
  .btn-outline-pinterest:not(:disabled):not(.disabled):active, .btn-outline-pinterest:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #d32f2f;
    border-color: #d32f2f; }
    .btn-outline-pinterest:not(:disabled):not(.disabled):active:focus, .btn-outline-pinterest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pinterest.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(211, 47, 47, 0.5); }

.btn-outline-instagram {
  color: #8d6e63;
  background-color: transparent;
  background-image: none;
  border-color: #8d6e63; }
  .btn-outline-instagram:hover {
    color: #fff;
    background-color: #8d6e63;
    border-color: #8d6e63; }
  .btn-outline-instagram:focus, .btn-outline-instagram.focus {
    box-shadow: 0 0 0 0.2rem rgba(141, 110, 99, 0.5); }
  .btn-outline-instagram.disabled, .btn-outline-instagram:disabled {
    color: #8d6e63;
    background-color: transparent; }
  .btn-outline-instagram:not(:disabled):not(.disabled):active, .btn-outline-instagram:not(:disabled):not(.disabled).active,
  .show > .btn-outline-instagram.dropdown-toggle {
    color: #fff;
    background-color: #8d6e63;
    border-color: #8d6e63; }
    .btn-outline-instagram:not(:disabled):not(.disabled):active:focus, .btn-outline-instagram:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-instagram.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(141, 110, 99, 0.5); }

.btn-outline-rss {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .btn-outline-rss:hover {
    color: #464D69;
    background-color: #ff9800;
    border-color: #ff9800; }
  .btn-outline-rss:focus, .btn-outline-rss.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .btn-outline-rss.disabled, .btn-outline-rss:disabled {
    color: #ff9800;
    background-color: transparent; }
  .btn-outline-rss:not(:disabled):not(.disabled):active, .btn-outline-rss:not(:disabled):not(.disabled).active,
  .show > .btn-outline-rss.dropdown-toggle {
    color: #464D69;
    background-color: #ff9800;
    border-color: #ff9800; }
    .btn-outline-rss:not(:disabled):not(.disabled):active:focus, .btn-outline-rss:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-rss.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.btn-outline-tumblr {
  color: #607d8b;
  background-color: transparent;
  background-image: none;
  border-color: #607d8b; }
  .btn-outline-tumblr:hover {
    color: #fff;
    background-color: #607d8b;
    border-color: #607d8b; }
  .btn-outline-tumblr:focus, .btn-outline-tumblr.focus {
    box-shadow: 0 0 0 0.2rem rgba(96, 125, 139, 0.5); }
  .btn-outline-tumblr.disabled, .btn-outline-tumblr:disabled {
    color: #607d8b;
    background-color: transparent; }
  .btn-outline-tumblr:not(:disabled):not(.disabled):active, .btn-outline-tumblr:not(:disabled):not(.disabled).active,
  .show > .btn-outline-tumblr.dropdown-toggle {
    color: #fff;
    background-color: #607d8b;
    border-color: #607d8b; }
    .btn-outline-tumblr:not(:disabled):not(.disabled):active:focus, .btn-outline-tumblr:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-tumblr.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(96, 125, 139, 0.5); }

.btn-outline-linkedin {
  color: #0876DE;
  background-color: transparent;
  background-image: none;
  border-color: #0876DE; }
  .btn-outline-linkedin:hover {
    color: #fff;
    background-color: #0876DE;
    border-color: #0876DE; }
  .btn-outline-linkedin:focus, .btn-outline-linkedin.focus {
    box-shadow: 0 0 0 0.2rem rgba(8, 118, 222, 0.5); }
  .btn-outline-linkedin.disabled, .btn-outline-linkedin:disabled {
    color: #0876DE;
    background-color: transparent; }
  .btn-outline-linkedin:not(:disabled):not(.disabled):active, .btn-outline-linkedin:not(:disabled):not(.disabled).active,
  .show > .btn-outline-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #0876DE;
    border-color: #0876DE; }
    .btn-outline-linkedin:not(:disabled):not(.disabled):active:focus, .btn-outline-linkedin:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-linkedin.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(8, 118, 222, 0.5); }

.btn-outline-dribbble {
  color: #f06292;
  background-color: transparent;
  background-image: none;
  border-color: #f06292; }
  .btn-outline-dribbble:hover {
    color: #fff;
    background-color: #f06292;
    border-color: #f06292; }
  .btn-outline-dribbble:focus, .btn-outline-dribbble.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 98, 146, 0.5); }
  .btn-outline-dribbble.disabled, .btn-outline-dribbble:disabled {
    color: #f06292;
    background-color: transparent; }
  .btn-outline-dribbble:not(:disabled):not(.disabled):active, .btn-outline-dribbble:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dribbble.dropdown-toggle {
    color: #fff;
    background-color: #f06292;
    border-color: #f06292; }
    .btn-outline-dribbble:not(:disabled):not(.disabled):active:focus, .btn-outline-dribbble:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dribbble.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(240, 98, 146, 0.5); }

.btn-outline-youtube {
  color: #d50000;
  background-color: transparent;
  background-image: none;
  border-color: #d50000; }
  .btn-outline-youtube:hover {
    color: #fff;
    background-color: #d50000;
    border-color: #d50000; }
  .btn-outline-youtube:focus, .btn-outline-youtube.focus {
    box-shadow: 0 0 0 0.2rem rgba(213, 0, 0, 0.5); }
  .btn-outline-youtube.disabled, .btn-outline-youtube:disabled {
    color: #d50000;
    background-color: transparent; }
  .btn-outline-youtube:not(:disabled):not(.disabled):active, .btn-outline-youtube:not(:disabled):not(.disabled).active,
  .show > .btn-outline-youtube.dropdown-toggle {
    color: #fff;
    background-color: #d50000;
    border-color: #d50000; }
    .btn-outline-youtube:not(:disabled):not(.disabled):active:focus, .btn-outline-youtube:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-youtube.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(213, 0, 0, 0.5); }

.btn-outline-github {
  color: #424242;
  background-color: transparent;
  background-image: none;
  border-color: #424242; }
  .btn-outline-github:hover {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
  .btn-outline-github:focus, .btn-outline-github.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }
  .btn-outline-github.disabled, .btn-outline-github:disabled {
    color: #424242;
    background-color: transparent; }
  .btn-outline-github:not(:disabled):not(.disabled):active, .btn-outline-github:not(:disabled):not(.disabled).active,
  .show > .btn-outline-github.dropdown-toggle {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
    .btn-outline-github:not(:disabled):not(.disabled):active:focus, .btn-outline-github:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-github.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }

.btn-outline-skype {
  color: #4fc3f7;
  background-color: transparent;
  background-image: none;
  border-color: #4fc3f7; }
  .btn-outline-skype:hover {
    color: #464D69;
    background-color: #4fc3f7;
    border-color: #4fc3f7; }
  .btn-outline-skype:focus, .btn-outline-skype.focus {
    box-shadow: 0 0 0 0.2rem rgba(79, 195, 247, 0.5); }
  .btn-outline-skype.disabled, .btn-outline-skype:disabled {
    color: #4fc3f7;
    background-color: transparent; }
  .btn-outline-skype:not(:disabled):not(.disabled):active, .btn-outline-skype:not(:disabled):not(.disabled).active,
  .show > .btn-outline-skype.dropdown-toggle {
    color: #464D69;
    background-color: #4fc3f7;
    border-color: #4fc3f7; }
    .btn-outline-skype:not(:disabled):not(.disabled):active:focus, .btn-outline-skype:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-skype.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(79, 195, 247, 0.5); }

.btn-outline-primary {
  color: #FA870E;
  background-color: transparent;
  background-image: none;
  border-color: #FA870E; }
  .btn-outline-primary:hover {
    color: #464D69;
    background-color: #FA870E;
    border-color: #FA870E; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 135, 14, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #FA870E;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #464D69;
    background-color: #FA870E;
    border-color: #FA870E; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(250, 135, 14, 0.5); }

.btn-outline-secondary {
  color: #677080;
  background-color: transparent;
  background-image: none;
  border-color: #677080; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #677080;
    border-color: #677080; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(103, 112, 128, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #677080;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #677080;
    border-color: #677080; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(103, 112, 128, 0.5); }

.btn-outline-success {
  color: #00D014;
  background-color: transparent;
  background-image: none;
  border-color: #00D014; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #00D014;
    border-color: #00D014; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 208, 20, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #00D014;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00D014;
    border-color: #00D014; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 208, 20, 0.5); }

.btn-outline-info {
  color: #00D0BD;
  background-color: transparent;
  background-image: none;
  border-color: #00D0BD; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00D0BD;
    border-color: #00D0BD; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 208, 189, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00D0BD;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00D0BD;
    border-color: #00D0BD; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 208, 189, 0.5); }

.btn-outline-warning {
  color: #FFB70F;
  background-color: transparent;
  background-image: none;
  border-color: #FFB70F; }
  .btn-outline-warning:hover {
    color: #464D69;
    background-color: #FFB70F;
    border-color: #FFB70F; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 183, 15, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FFB70F;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #464D69;
    background-color: #FFB70F;
    border-color: #FFB70F; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 183, 15, 0.5); }

.btn-outline-danger {
  color: #FF3739;
  background-color: transparent;
  background-image: none;
  border-color: #FF3739; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #FF3739;
    border-color: #FF3739; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 55, 57, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #FF3739;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #FF3739;
    border-color: #FF3739; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 55, 57, 0.5); }

.btn-outline-light {
  color: #FAFAFA;
  background-color: transparent;
  background-image: none;
  border-color: #FAFAFA; }
  .btn-outline-light:hover {
    color: #464D69;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #FAFAFA;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #464D69;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-outline-dark {
  color: #464D69;
  background-color: transparent;
  background-image: none;
  border-color: #464D69; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #464D69;
    border-color: #464D69; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(70, 77, 105, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #464D69;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #464D69;
    border-color: #464D69; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(70, 77, 105, 0.5); }

.btn-outline-orange {
  color: #FF7F50;
  background-color: transparent;
  background-image: none;
  border-color: #FF7F50; }
  .btn-outline-orange:hover {
    color: #464D69;
    background-color: #FF7F50;
    border-color: #FF7F50; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 127, 80, 0.5); }
  .btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #FF7F50;
    background-color: transparent; }
  .btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #464D69;
    background-color: #FF7F50;
    border-color: #FF7F50; }
    .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 127, 80, 0.5); }

.btn-outline-purple {
  color: #9370DB;
  background-color: transparent;
  background-image: none;
  border-color: #9370DB; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #9370DB;
    border-color: #9370DB; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(147, 112, 219, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #9370DB;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #9370DB;
    border-color: #9370DB; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(147, 112, 219, 0.5); }

.btn-link {
  font-weight: 400;
  color: #FA870E;
  background-color: transparent; }
  .btn-link:hover {
    color: #b86004;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #EBEDF2; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #464D69;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  transition: all .2s linear !important; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #F4F7FA; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #464D69;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #3c425a;
    text-decoration: none;
    background: #EBEDF2 linear-gradient(180deg, #eceff3, #EBEDF2) repeat-x; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background: #FA870E linear-gradient(180deg, #f99831, #FA870E) repeat-x; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #EBEDF2;
    background-color: transparent;
    background-image: none; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #727891;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #464D69;
  text-align: center;
  white-space: nowrap;
  background-color: #EBEDF2;
  border: 1px solid #EBEDF2;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background: #FA870E linear-gradient(180deg, #f99831, #FA870E) repeat-x;
    box-shadow: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #F4F7FA, 0 0 0 0.2rem rgba(250, 135, 14, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #fedebd;
    box-shadow: none; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #F5F9FB; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #F4F7FA; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #EBEDF2;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: #FA870E linear-gradient(180deg, #f99831, #FA870E) repeat-x; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background: #FA870E linear-gradient(180deg, #f99831, #FA870E) repeat-x;
  box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(250, 135, 14, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(250, 135, 14, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: #FA870E linear-gradient(180deg, #f99831, #FA870E) repeat-x; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(250, 135, 14, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #464D69;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23677080' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #EBEDF2;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #fdc58b;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(253, 197, 139, 0.5); }
    .custom-select:focus::-ms-value {
      color: #464D69;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #EBEDF2;
    background-color: #F4F7FA; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #fdc58b;
    box-shadow: 0 0 0 0.2rem rgba(250, 135, 14, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #fdc58b; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #464D69;
  background-color: #fff;
  border: 1px solid #EBEDF2;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #464D69;
    content: "Browse";
    background: #EBEDF2 linear-gradient(180deg, #eceff3, #EBEDF2) repeat-x;
    border-left: 1px solid #EBEDF2;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #EBEDF2; }

.nav-tabs {
  border-bottom: 1px solid #EBEDF2; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #F4F7FA #F4F7FA #EBEDF2; }
    .nav-tabs .nav-link.disabled {
      color: #EBEDF2;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #A5A7B2;
    background-color: #F4F7FA;
    border-color: #EBEDF2 #EBEDF2 #F4F7FA; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #FA870E; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 12px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -12px;
      margin-left: -12px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 12px;
        margin-bottom: 0;
        margin-left: 12px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 12px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #F4F7FA;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #727891;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #727891; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #FA870E;
  background-color: #fff;
  border: 1px solid #EBEDF2; }
  .page-link:hover {
    color: #b86004;
    text-decoration: none;
    background-color: #F4F7FA;
    border-color: #EBEDF2; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(250, 135, 14, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #FA870E;
  border-color: #FA870E; }

.disabled .page-link {
  color: #EBEDF2;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #EBEDF2; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #FA870E;
  border-color: #FA870E; }

.page-item.disabled .page-link {
  color: #EBEDF2;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #EBEDF2; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-light-yellow {
  color: #464D69;
  background-color: #FFF59D; }
  .badge-light-yellow[href]:hover, .badge-light-yellow[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #fff06a; }

.badge-twitter {
  color: #464D69;
  background-color: #52AEFF; }
  .badge-twitter[href]:hover, .badge-twitter[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #1f96ff; }

.badge-facebook {
  color: #fff;
  background-color: #405DE6; }
  .badge-facebook[href]:hover, .badge-facebook[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1c3dd7; }

.badge-google {
  color: #fff;
  background-color: #F13A30; }
  .badge-google[href]:hover, .badge-google[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #df1a0f; }

.badge-pinterest {
  color: #fff;
  background-color: #d32f2f; }
  .badge-pinterest[href]:hover, .badge-pinterest[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ab2424; }

.badge-instagram {
  color: #fff;
  background-color: #8d6e63; }
  .badge-instagram[href]:hover, .badge-instagram[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #6f574e; }

.badge-rss {
  color: #464D69;
  background-color: #ff9800; }
  .badge-rss[href]:hover, .badge-rss[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #cc7a00; }

.badge-tumblr {
  color: #fff;
  background-color: #607d8b; }
  .badge-tumblr[href]:hover, .badge-tumblr[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #4b626d; }

.badge-linkedin {
  color: #fff;
  background-color: #0876DE; }
  .badge-linkedin[href]:hover, .badge-linkedin[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #065cad; }

.badge-dribbble {
  color: #fff;
  background-color: #f06292; }
  .badge-dribbble[href]:hover, .badge-dribbble[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ec3372; }

.badge-youtube {
  color: #fff;
  background-color: #d50000; }
  .badge-youtube[href]:hover, .badge-youtube[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #a20000; }

.badge-github {
  color: #fff;
  background-color: #424242; }
  .badge-github[href]:hover, .badge-github[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #292929; }

.badge-skype {
  color: #464D69;
  background-color: #4fc3f7; }
  .badge-skype[href]:hover, .badge-skype[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #1eb2f5; }

.badge-primary {
  color: #464D69;
  background-color: #FA870E; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #d16d04; }

.badge-secondary {
  color: #fff;
  background-color: #677080; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #505764; }

.badge-success {
  color: #fff;
  background-color: #00D014; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #009d0f; }

.badge-info {
  color: #fff;
  background-color: #00D0BD; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #009d8f; }

.badge-warning {
  color: #464D69;
  background-color: #FFB70F; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #db9900; }

.badge-danger {
  color: #fff;
  background-color: #FF3739; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ff0407; }

.badge-light {
  color: #464D69;
  background-color: #FAFAFA; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #e1e1e1; }

.badge-dark {
  color: #fff;
  background-color: #464D69; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #32374a; }

.badge-orange {
  color: #464D69;
  background-color: #FF7F50; }
  .badge-orange[href]:hover, .badge-orange[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #ff5a1d; }

.badge-purple {
  color: #fff;
  background-color: #9370DB; }
  .badge-purple[href]:hover, .badge-purple[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #7447d1; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #F4F7FA;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-light-yellow {
  color: #857f52;
  background: #fffdeb linear-gradient(180deg, #fdfced, #fffdeb) repeat-x;
  border-color: #fffce4; }
  .alert-light-yellow hr {
    border-top-color: #fff9cb; }
  .alert-light-yellow .alert-link {
    color: #65613f; }

.alert-twitter {
  color: #2b5a85;
  background: #dcefff linear-gradient(180deg, #e0f0fe, #dcefff) repeat-x;
  border-color: #cfe8ff; }
  .alert-twitter hr {
    border-top-color: #b6dcff; }
  .alert-twitter .alert-link {
    color: #1f405e; }

.alert-facebook {
  color: #213078;
  background: #d9dffa linear-gradient(180deg, #dde3fa, #d9dffa) repeat-x;
  border-color: #cad2f8; }
  .alert-facebook hr {
    border-top-color: #b3bff5; }
  .alert-facebook .alert-link {
    color: #162050; }

.alert-google {
  color: #7d1e19;
  background: #fcd8d6 linear-gradient(180deg, #fbdddb, #fcd8d6) repeat-x;
  border-color: #fbc8c5; }
  .alert-google hr {
    border-top-color: #f9b1ad; }
  .alert-google .alert-link {
    color: #531411; }

.alert-pinterest {
  color: #6e1818;
  background: #f6d5d5 linear-gradient(180deg, #f6dadb, #f6d5d5) repeat-x;
  border-color: #f3c5c5; }
  .alert-pinterest hr {
    border-top-color: #efb0b0; }
  .alert-pinterest .alert-link {
    color: #440f0f; }

.alert-instagram {
  color: #493933;
  background: #e8e2e0 linear-gradient(180deg, #eae5e4, #e8e2e0) repeat-x;
  border-color: #dfd6d3; }
  .alert-instagram hr {
    border-top-color: #d4c8c4; }
  .alert-instagram .alert-link {
    color: #2b221e; }

.alert-rss {
  color: #854f00;
  background: #ffeacc linear-gradient(180deg, #fdecd3, #ffeacc) repeat-x;
  border-color: #ffe2b8; }
  .alert-rss hr {
    border-top-color: #ffd89f; }
  .alert-rss .alert-link {
    color: #523100; }

.alert-tumblr {
  color: #324148;
  background: #dfe5e8 linear-gradient(180deg, #e2e8eb, #dfe5e8) repeat-x;
  border-color: #d2dbdf; }
  .alert-tumblr hr {
    border-top-color: #c3cfd4; }
  .alert-tumblr .alert-link {
    color: #1d262a; }

.alert-linkedin {
  color: #043d73;
  background: #cee4f8 linear-gradient(180deg, #d4e7f8, #cee4f8) repeat-x;
  border-color: #bad9f6; }
  .alert-linkedin hr {
    border-top-color: #a3cdf3; }
  .alert-linkedin .alert-link {
    color: #022342; }

.alert-dribbble {
  color: #7d334c;
  background: #fce0e9 linear-gradient(180deg, #fbe3ec, #fce0e9) repeat-x;
  border-color: #fbd3e0; }
  .alert-dribbble hr {
    border-top-color: #f9bcd0; }
  .alert-dribbble .alert-link {
    color: #592436; }

.alert-youtube {
  color: #6f0000;
  background: #f7cccc linear-gradient(180deg, #f7d2d3, #f7cccc) repeat-x;
  border-color: #f3b8b8; }
  .alert-youtube hr {
    border-top-color: #efa2a2; }
  .alert-youtube .alert-link {
    color: #3c0000; }

.alert-github {
  color: #222222;
  background: #d9d9d9 linear-gradient(180deg, #dddede, #d9d9d9) repeat-x;
  border-color: #cacaca; }
  .alert-github hr {
    border-top-color: #bdbdbd; }
  .alert-github .alert-link {
    color: #090909; }

.alert-skype {
  color: #296580;
  background: #dcf3fd linear-gradient(180deg, #e0f4fd, #dcf3fd) repeat-x;
  border-color: #ceeefd; }
  .alert-skype hr {
    border-top-color: #b6e6fc; }
  .alert-skype .alert-link {
    color: #1d4759; }

.alert-primary {
  color: #824607;
  background: #fee7cf linear-gradient(180deg, #fde9d5, #fee7cf) repeat-x;
  border-color: #feddbc; }
  .alert-primary hr {
    border-top-color: #fed0a3; }
  .alert-primary .alert-link {
    color: #522c04; }

.alert-secondary {
  color: #363a43;
  background: #e1e2e6 linear-gradient(180deg, #e4e5e9, #e1e2e6) repeat-x;
  border-color: #d4d7db; }
  .alert-secondary hr {
    border-top-color: #c6cacf; }
  .alert-secondary .alert-link {
    color: #1f2227; }

.alert-success {
  color: #006c0a;
  background: #ccf6d0 linear-gradient(180deg, #d2f6d6, #ccf6d0) repeat-x;
  border-color: #b8f2bd; }
  .alert-success hr {
    border-top-color: #a2eea9; }
  .alert-success .alert-link {
    color: #003905; }

.alert-info {
  color: #006c62;
  background: #ccf6f2 linear-gradient(180deg, #d2f6f3, #ccf6f2) repeat-x;
  border-color: #b8f2ed; }
  .alert-info hr {
    border-top-color: #a2eee8; }
  .alert-info .alert-link {
    color: #003934; }

.alert-warning {
  color: #855f08;
  background: #fff1cf linear-gradient(180deg, #fdf2d5, #fff1cf) repeat-x;
  border-color: #ffebbc; }
  .alert-warning hr {
    border-top-color: #ffe3a3; }
  .alert-warning .alert-link {
    color: #553d05; }

.alert-danger {
  color: #851d1e;
  background: #ffd7d7 linear-gradient(180deg, #fddcdc, #ffd7d7) repeat-x;
  border-color: #ffc7c8; }
  .alert-danger hr {
    border-top-color: #ffaeaf; }
  .alert-danger .alert-link {
    color: #5b1415; }

.alert-light {
  color: #828282;
  background: #fefefe linear-gradient(180deg, #fdfdfd, #fefefe) repeat-x;
  border-color: #fefefe; }
  .alert-light hr {
    border-top-color: #f1f1f1; }
  .alert-light .alert-link {
    color: dimgray; }

.alert-dark {
  color: #242837;
  background: #dadbe1 linear-gradient(180deg, #dedfe5, #dadbe1) repeat-x;
  border-color: #cbcdd5; }
  .alert-dark hr {
    border-top-color: #bdbfca; }
  .alert-dark .alert-link {
    color: #101218; }

.alert-orange {
  color: #85422a;
  background: #ffe5dc linear-gradient(180deg, #fde8e1, #ffe5dc) repeat-x;
  border-color: #ffdbce; }
  .alert-orange hr {
    border-top-color: #ffc8b5; }
  .alert-orange .alert-link {
    color: #5e2f1e; }

.alert-purple {
  color: #4c3a72;
  background: #e9e2f8 linear-gradient(180deg, #ebe5f8, #e9e2f8) repeat-x;
  border-color: #e1d7f5; }
  .alert-purple hr {
    border-top-color: #d2c3f0; }
  .alert-purple .alert-link {
    color: #352950; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #F4F7FA;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #FA870E;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #A5A7B2;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #A5A7B2;
    text-decoration: none;
    background-color: #FAFAFA; }
  .list-group-item-action:active {
    color: #464D69;
    background-color: #F4F7FA; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #EBEDF2;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #FA870E;
    border-color: #FA870E; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-light-yellow {
  color: #857f52;
  background-color: #fffce4; }
  .list-group-item-light-yellow.list-group-item-action:hover, .list-group-item-light-yellow.list-group-item-action:focus {
    color: #857f52;
    background-color: #fff9cb; }
  .list-group-item-light-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #857f52;
    border-color: #857f52; }

.list-group-item-twitter {
  color: #2b5a85;
  background-color: #cfe8ff; }
  .list-group-item-twitter.list-group-item-action:hover, .list-group-item-twitter.list-group-item-action:focus {
    color: #2b5a85;
    background-color: #b6dcff; }
  .list-group-item-twitter.list-group-item-action.active {
    color: #fff;
    background-color: #2b5a85;
    border-color: #2b5a85; }

.list-group-item-facebook {
  color: #213078;
  background-color: #cad2f8; }
  .list-group-item-facebook.list-group-item-action:hover, .list-group-item-facebook.list-group-item-action:focus {
    color: #213078;
    background-color: #b3bff5; }
  .list-group-item-facebook.list-group-item-action.active {
    color: #fff;
    background-color: #213078;
    border-color: #213078; }

.list-group-item-google {
  color: #7d1e19;
  background-color: #fbc8c5; }
  .list-group-item-google.list-group-item-action:hover, .list-group-item-google.list-group-item-action:focus {
    color: #7d1e19;
    background-color: #f9b1ad; }
  .list-group-item-google.list-group-item-action.active {
    color: #fff;
    background-color: #7d1e19;
    border-color: #7d1e19; }

.list-group-item-pinterest {
  color: #6e1818;
  background-color: #f3c5c5; }
  .list-group-item-pinterest.list-group-item-action:hover, .list-group-item-pinterest.list-group-item-action:focus {
    color: #6e1818;
    background-color: #efb0b0; }
  .list-group-item-pinterest.list-group-item-action.active {
    color: #fff;
    background-color: #6e1818;
    border-color: #6e1818; }

.list-group-item-instagram {
  color: #493933;
  background-color: #dfd6d3; }
  .list-group-item-instagram.list-group-item-action:hover, .list-group-item-instagram.list-group-item-action:focus {
    color: #493933;
    background-color: #d4c8c4; }
  .list-group-item-instagram.list-group-item-action.active {
    color: #fff;
    background-color: #493933;
    border-color: #493933; }

.list-group-item-rss {
  color: #854f00;
  background-color: #ffe2b8; }
  .list-group-item-rss.list-group-item-action:hover, .list-group-item-rss.list-group-item-action:focus {
    color: #854f00;
    background-color: #ffd89f; }
  .list-group-item-rss.list-group-item-action.active {
    color: #fff;
    background-color: #854f00;
    border-color: #854f00; }

.list-group-item-tumblr {
  color: #324148;
  background-color: #d2dbdf; }
  .list-group-item-tumblr.list-group-item-action:hover, .list-group-item-tumblr.list-group-item-action:focus {
    color: #324148;
    background-color: #c3cfd4; }
  .list-group-item-tumblr.list-group-item-action.active {
    color: #fff;
    background-color: #324148;
    border-color: #324148; }

.list-group-item-linkedin {
  color: #043d73;
  background-color: #bad9f6; }
  .list-group-item-linkedin.list-group-item-action:hover, .list-group-item-linkedin.list-group-item-action:focus {
    color: #043d73;
    background-color: #a3cdf3; }
  .list-group-item-linkedin.list-group-item-action.active {
    color: #fff;
    background-color: #043d73;
    border-color: #043d73; }

.list-group-item-dribbble {
  color: #7d334c;
  background-color: #fbd3e0; }
  .list-group-item-dribbble.list-group-item-action:hover, .list-group-item-dribbble.list-group-item-action:focus {
    color: #7d334c;
    background-color: #f9bcd0; }
  .list-group-item-dribbble.list-group-item-action.active {
    color: #fff;
    background-color: #7d334c;
    border-color: #7d334c; }

.list-group-item-youtube {
  color: #6f0000;
  background-color: #f3b8b8; }
  .list-group-item-youtube.list-group-item-action:hover, .list-group-item-youtube.list-group-item-action:focus {
    color: #6f0000;
    background-color: #efa2a2; }
  .list-group-item-youtube.list-group-item-action.active {
    color: #fff;
    background-color: #6f0000;
    border-color: #6f0000; }

.list-group-item-github {
  color: #222222;
  background-color: #cacaca; }
  .list-group-item-github.list-group-item-action:hover, .list-group-item-github.list-group-item-action:focus {
    color: #222222;
    background-color: #bdbdbd; }
  .list-group-item-github.list-group-item-action.active {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }

.list-group-item-skype {
  color: #296580;
  background-color: #ceeefd; }
  .list-group-item-skype.list-group-item-action:hover, .list-group-item-skype.list-group-item-action:focus {
    color: #296580;
    background-color: #b6e6fc; }
  .list-group-item-skype.list-group-item-action.active {
    color: #fff;
    background-color: #296580;
    border-color: #296580; }

.list-group-item-primary {
  color: #824607;
  background-color: #feddbc; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #824607;
    background-color: #fed0a3; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #824607;
    border-color: #824607; }

.list-group-item-secondary {
  color: #363a43;
  background-color: #d4d7db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #363a43;
    background-color: #c6cacf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #363a43;
    border-color: #363a43; }

.list-group-item-success {
  color: #006c0a;
  background-color: #b8f2bd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #006c0a;
    background-color: #a2eea9; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #006c0a;
    border-color: #006c0a; }

.list-group-item-info {
  color: #006c62;
  background-color: #b8f2ed; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #006c62;
    background-color: #a2eee8; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #006c62;
    border-color: #006c62; }

.list-group-item-warning {
  color: #855f08;
  background-color: #ffebbc; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #855f08;
    background-color: #ffe3a3; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #855f08;
    border-color: #855f08; }

.list-group-item-danger {
  color: #851d1e;
  background-color: #ffc7c8; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #851d1e;
    background-color: #ffaeaf; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #851d1e;
    border-color: #851d1e; }

.list-group-item-light {
  color: #828282;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #828282;
    background-color: #f1f1f1; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #828282;
    border-color: #828282; }

.list-group-item-dark {
  color: #242837;
  background-color: #cbcdd5; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #242837;
    background-color: #bdbfca; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #242837;
    border-color: #242837; }

.list-group-item-orange {
  color: #85422a;
  background-color: #ffdbce; }
  .list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
    color: #85422a;
    background-color: #ffc8b5; }
  .list-group-item-orange.list-group-item-action.active {
    color: #fff;
    background-color: #85422a;
    border-color: #85422a; }

.list-group-item-purple {
  color: #4c3a72;
  background-color: #e1d7f5; }
  .list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
    color: #4c3a72;
    background-color: #d2c3f0; }
  .list-group-item-purple.list-group-item-action.active {
    color: #fff;
    background-color: #4c3a72;
    border-color: #4c3a72; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #F4F7FA;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #F4F7FA; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Heebo", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Heebo", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #464D69; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #677080;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #677080;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-next {
  right: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23677080' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23677080' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 0;
    height: 0;
    margin-right: 0;
    margin-left: 0;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-light-yellow {
  background-color: #FFF59D !important; }

a.bg-light-yellow:hover, a.bg-light-yellow:focus,
button.bg-light-yellow:hover,
button.bg-light-yellow:focus {
  background-color: #fff06a !important; }

.bg-twitter {
  background-color: #52AEFF !important; }

a.bg-twitter:hover, a.bg-twitter:focus,
button.bg-twitter:hover,
button.bg-twitter:focus {
  background-color: #1f96ff !important; }

.bg-facebook {
  background-color: #405DE6 !important; }

a.bg-facebook:hover, a.bg-facebook:focus,
button.bg-facebook:hover,
button.bg-facebook:focus {
  background-color: #1c3dd7 !important; }

.bg-google {
  background-color: #F13A30 !important; }

a.bg-google:hover, a.bg-google:focus,
button.bg-google:hover,
button.bg-google:focus {
  background-color: #df1a0f !important; }

.bg-pinterest {
  background-color: #d32f2f !important; }

a.bg-pinterest:hover, a.bg-pinterest:focus,
button.bg-pinterest:hover,
button.bg-pinterest:focus {
  background-color: #ab2424 !important; }

.bg-instagram {
  background-color: #8d6e63 !important; }

a.bg-instagram:hover, a.bg-instagram:focus,
button.bg-instagram:hover,
button.bg-instagram:focus {
  background-color: #6f574e !important; }

.bg-rss {
  background-color: #ff9800 !important; }

a.bg-rss:hover, a.bg-rss:focus,
button.bg-rss:hover,
button.bg-rss:focus {
  background-color: #cc7a00 !important; }

.bg-tumblr {
  background-color: #607d8b !important; }

a.bg-tumblr:hover, a.bg-tumblr:focus,
button.bg-tumblr:hover,
button.bg-tumblr:focus {
  background-color: #4b626d !important; }

.bg-linkedin {
  background-color: #0876DE !important; }

a.bg-linkedin:hover, a.bg-linkedin:focus,
button.bg-linkedin:hover,
button.bg-linkedin:focus {
  background-color: #065cad !important; }

.bg-dribbble {
  background-color: #f06292 !important; }

a.bg-dribbble:hover, a.bg-dribbble:focus,
button.bg-dribbble:hover,
button.bg-dribbble:focus {
  background-color: #ec3372 !important; }

.bg-youtube {
  background-color: #d50000 !important; }

a.bg-youtube:hover, a.bg-youtube:focus,
button.bg-youtube:hover,
button.bg-youtube:focus {
  background-color: #a20000 !important; }

.bg-github {
  background-color: #424242 !important; }

a.bg-github:hover, a.bg-github:focus,
button.bg-github:hover,
button.bg-github:focus {
  background-color: #292929 !important; }

.bg-skype {
  background-color: #4fc3f7 !important; }

a.bg-skype:hover, a.bg-skype:focus,
button.bg-skype:hover,
button.bg-skype:focus {
  background-color: #1eb2f5 !important; }

.bg-primary {
  background-color: #FA870E !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #d16d04 !important; }

.bg-secondary {
  background-color: #677080 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #505764 !important; }

.bg-success {
  background-color: #00D014 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #009d0f !important; }

.bg-info {
  background-color: #00D0BD !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #009d8f !important; }

.bg-warning {
  background-color: #FFB70F !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #db9900 !important; }

.bg-danger {
  background-color: #FF3739 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff0407 !important; }

.bg-light {
  background-color: #FAFAFA !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e1e1e1 !important; }

.bg-dark {
  background-color: #464D69 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #32374a !important; }

.bg-orange {
  background-color: #FF7F50 !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #ff5a1d !important; }

.bg-purple {
  background-color: #9370DB !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #7447d1 !important; }

.bg-gradient-light-yellow {
  background: #FFF59D linear-gradient(180deg, #fdf5ab, #FFF59D) repeat-x !important; }

.bg-gradient-twitter {
  background: #52AEFF linear-gradient(180deg, #6ab9fe, #52AEFF) repeat-x !important; }

.bg-gradient-facebook {
  background: #405DE6 linear-gradient(180deg, #5b74e9, #405DE6) repeat-x !important; }

.bg-gradient-google {
  background: #F13A30 linear-gradient(180deg, #f1564e, #F13A30) repeat-x !important; }

.bg-gradient-pinterest {
  background: #d32f2f linear-gradient(180deg, #d84d4d, #d32f2f) repeat-x !important; }

.bg-gradient-instagram {
  background: #8d6e63 linear-gradient(180deg, #9c837a, #8d6e63) repeat-x !important; }

.bg-gradient-rss {
  background: #ff9800 linear-gradient(180deg, #fda626, #ff9800) repeat-x !important; }

.bg-gradient-tumblr {
  background: #607d8b linear-gradient(180deg, #768f9c, #607d8b) repeat-x !important; }

.bg-gradient-linkedin {
  background: #0876DE linear-gradient(180deg, #2b89e2, #0876DE) repeat-x !important; }

.bg-gradient-dribbble {
  background: #f06292 linear-gradient(180deg, #f178a2, #f06292) repeat-x !important; }

.bg-gradient-youtube {
  background: #d50000 linear-gradient(180deg, #da2526, #d50000) repeat-x !important; }

.bg-gradient-github {
  background: #424242 linear-gradient(180deg, #5d5d5e, #424242) repeat-x !important; }

.bg-gradient-skype {
  background: #4fc3f7 linear-gradient(180deg, #68cbf7, #4fc3f7) repeat-x !important; }

.bg-gradient-primary {
  background: #FA870E linear-gradient(180deg, #f99831, #FA870E) repeat-x !important; }

.bg-gradient-secondary {
  background: #677080 linear-gradient(180deg, #7c8492, #677080) repeat-x !important; }

.bg-gradient-success {
  background: #00D014 linear-gradient(180deg, #25d637, #00D014) repeat-x !important; }

.bg-gradient-info {
  background: #00D0BD linear-gradient(180deg, #25d6c6, #00D0BD) repeat-x !important; }

.bg-gradient-warning {
  background: #FFB70F linear-gradient(180deg, #fdc132, #FFB70F) repeat-x !important; }

.bg-gradient-danger {
  background: #FF3739 linear-gradient(180deg, #fd5456, #FF3739) repeat-x !important; }

.bg-gradient-light {
  background: #FAFAFA linear-gradient(180deg, #f9fafa, #FAFAFA) repeat-x !important; }

.bg-gradient-dark {
  background: #464D69 linear-gradient(180deg, #60677f, #464D69) repeat-x !important; }

.bg-gradient-orange {
  background: #FF7F50 linear-gradient(180deg, #fd916a, #FF7F50) repeat-x !important; }

.bg-gradient-purple {
  background: #9370DB linear-gradient(180deg, #a284e0, #9370DB) repeat-x !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #EBEDF2 !important; }

.border-top {
  border-top: 1px solid #EBEDF2 !important; }

.border-right {
  border-right: 1px solid #EBEDF2 !important; }

.border-bottom {
  border-bottom: 1px solid #EBEDF2 !important; }

.border-left {
  border-left: 1px solid #EBEDF2 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-light-yellow {
  border-color: #FFF59D !important; }

.border-twitter {
  border-color: #52AEFF !important; }

.border-facebook {
  border-color: #405DE6 !important; }

.border-google {
  border-color: #F13A30 !important; }

.border-pinterest {
  border-color: #d32f2f !important; }

.border-instagram {
  border-color: #8d6e63 !important; }

.border-rss {
  border-color: #ff9800 !important; }

.border-tumblr {
  border-color: #607d8b !important; }

.border-linkedin {
  border-color: #0876DE !important; }

.border-dribbble {
  border-color: #f06292 !important; }

.border-youtube {
  border-color: #d50000 !important; }

.border-github {
  border-color: #424242 !important; }

.border-skype {
  border-color: #4fc3f7 !important; }

.border-primary {
  border-color: #FA870E !important; }

.border-secondary {
  border-color: #677080 !important; }

.border-success {
  border-color: #00D014 !important; }

.border-info {
  border-color: #00D0BD !important; }

.border-warning {
  border-color: #FFB70F !important; }

.border-danger {
  border-color: #FF3739 !important; }

.border-light {
  border-color: #FAFAFA !important; }

.border-dark {
  border-color: #464D69 !important; }

.border-orange {
  border-color: #FF7F50 !important; }

.border-purple {
  border-color: #9370DB !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-25 {
  width: 25% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-75 {
  width: 75% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.h-5 {
  height: 5% !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-25 {
  height: 25% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-75 {
  height: 75% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-5 {
  margin: 0.3125rem !important; }

.mt-5,
.my-5 {
  margin-top: 0.3125rem !important; }

.mr-5,
.mx-5 {
  margin-right: 0.3125rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 0.3125rem !important; }

.ml-5,
.mx-5 {
  margin-left: 0.3125rem !important; }

.m-10 {
  margin: 0.625rem !important; }

.mt-10,
.my-10 {
  margin-top: 0.625rem !important; }

.mr-10,
.mx-10 {
  margin-right: 0.625rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 0.625rem !important; }

.ml-10,
.mx-10 {
  margin-left: 0.625rem !important; }

.m-15 {
  margin: 0.9375rem !important; }

.mt-15,
.my-15 {
  margin-top: 0.9375rem !important; }

.mr-15,
.mx-15 {
  margin-right: 0.9375rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 0.9375rem !important; }

.ml-15,
.mx-15 {
  margin-left: 0.9375rem !important; }

.m-20 {
  margin: 1.25rem !important; }

.mt-20,
.my-20 {
  margin-top: 1.25rem !important; }

.mr-20,
.mx-20 {
  margin-right: 1.25rem !important; }

.mb-20,
.my-20 {
  margin-bottom: 1.25rem !important; }

.ml-20,
.mx-20 {
  margin-left: 1.25rem !important; }

.m-25 {
  margin: 1.563rem !important; }

.mt-25,
.my-25 {
  margin-top: 1.563rem !important; }

.mr-25,
.mx-25 {
  margin-right: 1.563rem !important; }

.mb-25,
.my-25 {
  margin-bottom: 1.563rem !important; }

.ml-25,
.mx-25 {
  margin-left: 1.563rem !important; }

.m-30 {
  margin: 1.875rem !important; }

.mt-30,
.my-30 {
  margin-top: 1.875rem !important; }

.mr-30,
.mx-30 {
  margin-right: 1.875rem !important; }

.mb-30,
.my-30 {
  margin-bottom: 1.875rem !important; }

.ml-30,
.mx-30 {
  margin-left: 1.875rem !important; }

.m-40 {
  margin: 2.5rem !important; }

.mt-40,
.my-40 {
  margin-top: 2.5rem !important; }

.mr-40,
.mx-40 {
  margin-right: 2.5rem !important; }

.mb-40,
.my-40 {
  margin-bottom: 2.5rem !important; }

.ml-40,
.mx-40 {
  margin-left: 2.5rem !important; }

.m-50 {
  margin: 3.125rem !important; }

.mt-50,
.my-50 {
  margin-top: 3.125rem !important; }

.mr-50,
.mx-50 {
  margin-right: 3.125rem !important; }

.mb-50,
.my-50 {
  margin-bottom: 3.125rem !important; }

.ml-50,
.mx-50 {
  margin-left: 3.125rem !important; }

.m-60 {
  margin: 3.75rem !important; }

.mt-60,
.my-60 {
  margin-top: 3.75rem !important; }

.mr-60,
.mx-60 {
  margin-right: 3.75rem !important; }

.mb-60,
.my-60 {
  margin-bottom: 3.75rem !important; }

.ml-60,
.mx-60 {
  margin-left: 3.75rem !important; }

.m-70 {
  margin: 4.375rem !important; }

.mt-70,
.my-70 {
  margin-top: 4.375rem !important; }

.mr-70,
.mx-70 {
  margin-right: 4.375rem !important; }

.mb-70,
.my-70 {
  margin-bottom: 4.375rem !important; }

.ml-70,
.mx-70 {
  margin-left: 4.375rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-5 {
  padding: 0.3125rem !important; }

.pt-5,
.py-5 {
  padding-top: 0.3125rem !important; }

.pr-5,
.px-5 {
  padding-right: 0.3125rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 0.3125rem !important; }

.pl-5,
.px-5 {
  padding-left: 0.3125rem !important; }

.p-10 {
  padding: 0.625rem !important; }

.pt-10,
.py-10 {
  padding-top: 0.625rem !important; }

.pr-10,
.px-10 {
  padding-right: 0.625rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 0.625rem !important; }

.pl-10,
.px-10 {
  padding-left: 0.625rem !important; }

.p-15 {
  padding: 0.9375rem !important; }

.pt-15,
.py-15 {
  padding-top: 0.9375rem !important; }

.pr-15,
.px-15 {
  padding-right: 0.9375rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 0.9375rem !important; }

.pl-15,
.px-15 {
  padding-left: 0.9375rem !important; }

.p-20 {
  padding: 1.25rem !important; }

.pt-20,
.py-20 {
  padding-top: 1.25rem !important; }

.pr-20,
.px-20 {
  padding-right: 1.25rem !important; }

.pb-20,
.py-20 {
  padding-bottom: 1.25rem !important; }

.pl-20,
.px-20 {
  padding-left: 1.25rem !important; }

.p-25 {
  padding: 1.563rem !important; }

.pt-25,
.py-25 {
  padding-top: 1.563rem !important; }

.pr-25,
.px-25 {
  padding-right: 1.563rem !important; }

.pb-25,
.py-25 {
  padding-bottom: 1.563rem !important; }

.pl-25,
.px-25 {
  padding-left: 1.563rem !important; }

.p-30 {
  padding: 1.875rem !important; }

.pt-30,
.py-30 {
  padding-top: 1.875rem !important; }

.pr-30,
.px-30 {
  padding-right: 1.875rem !important; }

.pb-30,
.py-30 {
  padding-bottom: 1.875rem !important; }

.pl-30,
.px-30 {
  padding-left: 1.875rem !important; }

.p-40 {
  padding: 2.5rem !important; }

.pt-40,
.py-40 {
  padding-top: 2.5rem !important; }

.pr-40,
.px-40 {
  padding-right: 2.5rem !important; }

.pb-40,
.py-40 {
  padding-bottom: 2.5rem !important; }

.pl-40,
.px-40 {
  padding-left: 2.5rem !important; }

.p-50 {
  padding: 3.125rem !important; }

.pt-50,
.py-50 {
  padding-top: 3.125rem !important; }

.pr-50,
.px-50 {
  padding-right: 3.125rem !important; }

.pb-50,
.py-50 {
  padding-bottom: 3.125rem !important; }

.pl-50,
.px-50 {
  padding-left: 3.125rem !important; }

.p-60 {
  padding: 3.75rem !important; }

.pt-60,
.py-60 {
  padding-top: 3.75rem !important; }

.pr-60,
.px-60 {
  padding-right: 3.75rem !important; }

.pb-60,
.py-60 {
  padding-bottom: 3.75rem !important; }

.pl-60,
.px-60 {
  padding-left: 3.75rem !important; }

.p-70 {
  padding: 4.375rem !important; }

.pt-70,
.py-70 {
  padding-top: 4.375rem !important; }

.pr-70,
.px-70 {
  padding-right: 4.375rem !important; }

.pb-70,
.py-70 {
  padding-bottom: 4.375rem !important; }

.pl-70,
.px-70 {
  padding-left: 4.375rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-5 {
    margin: 0.3125rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 0.3125rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 0.3125rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 0.3125rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 0.3125rem !important; }
  .m-sm-10 {
    margin: 0.625rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 0.625rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 0.625rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 0.625rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 0.625rem !important; }
  .m-sm-15 {
    margin: 0.9375rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 0.9375rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 0.9375rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 0.9375rem !important; }
  .m-sm-20 {
    margin: 1.25rem !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 1.25rem !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 1.25rem !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 1.25rem !important; }
  .m-sm-25 {
    margin: 1.563rem !important; }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 1.563rem !important; }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 1.563rem !important; }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 1.563rem !important; }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 1.563rem !important; }
  .m-sm-30 {
    margin: 1.875rem !important; }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 1.875rem !important; }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 1.875rem !important; }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 1.875rem !important; }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 1.875rem !important; }
  .m-sm-40 {
    margin: 2.5rem !important; }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 2.5rem !important; }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 2.5rem !important; }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 2.5rem !important; }
  .m-sm-50 {
    margin: 3.125rem !important; }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 3.125rem !important; }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 3.125rem !important; }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 3.125rem !important; }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 3.125rem !important; }
  .m-sm-60 {
    margin: 3.75rem !important; }
  .mt-sm-60,
  .my-sm-60 {
    margin-top: 3.75rem !important; }
  .mr-sm-60,
  .mx-sm-60 {
    margin-right: 3.75rem !important; }
  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-60,
  .mx-sm-60 {
    margin-left: 3.75rem !important; }
  .m-sm-70 {
    margin: 4.375rem !important; }
  .mt-sm-70,
  .my-sm-70 {
    margin-top: 4.375rem !important; }
  .mr-sm-70,
  .mx-sm-70 {
    margin-right: 4.375rem !important; }
  .mb-sm-70,
  .my-sm-70 {
    margin-bottom: 4.375rem !important; }
  .ml-sm-70,
  .mx-sm-70 {
    margin-left: 4.375rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-5 {
    padding: 0.3125rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 0.3125rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 0.3125rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 0.3125rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 0.3125rem !important; }
  .p-sm-10 {
    padding: 0.625rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 0.625rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 0.625rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 0.625rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 0.625rem !important; }
  .p-sm-15 {
    padding: 0.9375rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 0.9375rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 0.9375rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 0.9375rem !important; }
  .p-sm-20 {
    padding: 1.25rem !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 1.25rem !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 1.25rem !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 1.25rem !important; }
  .p-sm-25 {
    padding: 1.563rem !important; }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 1.563rem !important; }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 1.563rem !important; }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 1.563rem !important; }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 1.563rem !important; }
  .p-sm-30 {
    padding: 1.875rem !important; }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 1.875rem !important; }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 1.875rem !important; }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 1.875rem !important; }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 1.875rem !important; }
  .p-sm-40 {
    padding: 2.5rem !important; }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 2.5rem !important; }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 2.5rem !important; }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 2.5rem !important; }
  .p-sm-50 {
    padding: 3.125rem !important; }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 3.125rem !important; }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 3.125rem !important; }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 3.125rem !important; }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 3.125rem !important; }
  .p-sm-60 {
    padding: 3.75rem !important; }
  .pt-sm-60,
  .py-sm-60 {
    padding-top: 3.75rem !important; }
  .pr-sm-60,
  .px-sm-60 {
    padding-right: 3.75rem !important; }
  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-60,
  .px-sm-60 {
    padding-left: 3.75rem !important; }
  .p-sm-70 {
    padding: 4.375rem !important; }
  .pt-sm-70,
  .py-sm-70 {
    padding-top: 4.375rem !important; }
  .pr-sm-70,
  .px-sm-70 {
    padding-right: 4.375rem !important; }
  .pb-sm-70,
  .py-sm-70 {
    padding-bottom: 4.375rem !important; }
  .pl-sm-70,
  .px-sm-70 {
    padding-left: 4.375rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-5 {
    margin: 0.3125rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 0.3125rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 0.3125rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 0.3125rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 0.3125rem !important; }
  .m-md-10 {
    margin: 0.625rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 0.625rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 0.625rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 0.625rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 0.625rem !important; }
  .m-md-15 {
    margin: 0.9375rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 0.9375rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 0.9375rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 0.9375rem !important; }
  .m-md-20 {
    margin: 1.25rem !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 1.25rem !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 1.25rem !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 1.25rem !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 1.25rem !important; }
  .m-md-25 {
    margin: 1.563rem !important; }
  .mt-md-25,
  .my-md-25 {
    margin-top: 1.563rem !important; }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 1.563rem !important; }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 1.563rem !important; }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 1.563rem !important; }
  .m-md-30 {
    margin: 1.875rem !important; }
  .mt-md-30,
  .my-md-30 {
    margin-top: 1.875rem !important; }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 1.875rem !important; }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 1.875rem !important; }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 1.875rem !important; }
  .m-md-40 {
    margin: 2.5rem !important; }
  .mt-md-40,
  .my-md-40 {
    margin-top: 2.5rem !important; }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 2.5rem !important; }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 2.5rem !important; }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 2.5rem !important; }
  .m-md-50 {
    margin: 3.125rem !important; }
  .mt-md-50,
  .my-md-50 {
    margin-top: 3.125rem !important; }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 3.125rem !important; }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 3.125rem !important; }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 3.125rem !important; }
  .m-md-60 {
    margin: 3.75rem !important; }
  .mt-md-60,
  .my-md-60 {
    margin-top: 3.75rem !important; }
  .mr-md-60,
  .mx-md-60 {
    margin-right: 3.75rem !important; }
  .mb-md-60,
  .my-md-60 {
    margin-bottom: 3.75rem !important; }
  .ml-md-60,
  .mx-md-60 {
    margin-left: 3.75rem !important; }
  .m-md-70 {
    margin: 4.375rem !important; }
  .mt-md-70,
  .my-md-70 {
    margin-top: 4.375rem !important; }
  .mr-md-70,
  .mx-md-70 {
    margin-right: 4.375rem !important; }
  .mb-md-70,
  .my-md-70 {
    margin-bottom: 4.375rem !important; }
  .ml-md-70,
  .mx-md-70 {
    margin-left: 4.375rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-5 {
    padding: 0.3125rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 0.3125rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 0.3125rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 0.3125rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 0.3125rem !important; }
  .p-md-10 {
    padding: 0.625rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 0.625rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 0.625rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 0.625rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 0.625rem !important; }
  .p-md-15 {
    padding: 0.9375rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 0.9375rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 0.9375rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 0.9375rem !important; }
  .p-md-20 {
    padding: 1.25rem !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 1.25rem !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 1.25rem !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 1.25rem !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 1.25rem !important; }
  .p-md-25 {
    padding: 1.563rem !important; }
  .pt-md-25,
  .py-md-25 {
    padding-top: 1.563rem !important; }
  .pr-md-25,
  .px-md-25 {
    padding-right: 1.563rem !important; }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 1.563rem !important; }
  .pl-md-25,
  .px-md-25 {
    padding-left: 1.563rem !important; }
  .p-md-30 {
    padding: 1.875rem !important; }
  .pt-md-30,
  .py-md-30 {
    padding-top: 1.875rem !important; }
  .pr-md-30,
  .px-md-30 {
    padding-right: 1.875rem !important; }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 1.875rem !important; }
  .pl-md-30,
  .px-md-30 {
    padding-left: 1.875rem !important; }
  .p-md-40 {
    padding: 2.5rem !important; }
  .pt-md-40,
  .py-md-40 {
    padding-top: 2.5rem !important; }
  .pr-md-40,
  .px-md-40 {
    padding-right: 2.5rem !important; }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 2.5rem !important; }
  .pl-md-40,
  .px-md-40 {
    padding-left: 2.5rem !important; }
  .p-md-50 {
    padding: 3.125rem !important; }
  .pt-md-50,
  .py-md-50 {
    padding-top: 3.125rem !important; }
  .pr-md-50,
  .px-md-50 {
    padding-right: 3.125rem !important; }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 3.125rem !important; }
  .pl-md-50,
  .px-md-50 {
    padding-left: 3.125rem !important; }
  .p-md-60 {
    padding: 3.75rem !important; }
  .pt-md-60,
  .py-md-60 {
    padding-top: 3.75rem !important; }
  .pr-md-60,
  .px-md-60 {
    padding-right: 3.75rem !important; }
  .pb-md-60,
  .py-md-60 {
    padding-bottom: 3.75rem !important; }
  .pl-md-60,
  .px-md-60 {
    padding-left: 3.75rem !important; }
  .p-md-70 {
    padding: 4.375rem !important; }
  .pt-md-70,
  .py-md-70 {
    padding-top: 4.375rem !important; }
  .pr-md-70,
  .px-md-70 {
    padding-right: 4.375rem !important; }
  .pb-md-70,
  .py-md-70 {
    padding-bottom: 4.375rem !important; }
  .pl-md-70,
  .px-md-70 {
    padding-left: 4.375rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-5 {
    margin: 0.3125rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 0.3125rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 0.3125rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 0.3125rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 0.3125rem !important; }
  .m-lg-10 {
    margin: 0.625rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 0.625rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 0.625rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 0.625rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 0.625rem !important; }
  .m-lg-15 {
    margin: 0.9375rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 0.9375rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 0.9375rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 0.9375rem !important; }
  .m-lg-20 {
    margin: 1.25rem !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 1.25rem !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 1.25rem !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 1.25rem !important; }
  .m-lg-25 {
    margin: 1.563rem !important; }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 1.563rem !important; }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 1.563rem !important; }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 1.563rem !important; }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 1.563rem !important; }
  .m-lg-30 {
    margin: 1.875rem !important; }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 1.875rem !important; }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 1.875rem !important; }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 1.875rem !important; }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 1.875rem !important; }
  .m-lg-40 {
    margin: 2.5rem !important; }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 2.5rem !important; }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 2.5rem !important; }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 2.5rem !important; }
  .m-lg-50 {
    margin: 3.125rem !important; }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 3.125rem !important; }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 3.125rem !important; }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 3.125rem !important; }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 3.125rem !important; }
  .m-lg-60 {
    margin: 3.75rem !important; }
  .mt-lg-60,
  .my-lg-60 {
    margin-top: 3.75rem !important; }
  .mr-lg-60,
  .mx-lg-60 {
    margin-right: 3.75rem !important; }
  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-60,
  .mx-lg-60 {
    margin-left: 3.75rem !important; }
  .m-lg-70 {
    margin: 4.375rem !important; }
  .mt-lg-70,
  .my-lg-70 {
    margin-top: 4.375rem !important; }
  .mr-lg-70,
  .mx-lg-70 {
    margin-right: 4.375rem !important; }
  .mb-lg-70,
  .my-lg-70 {
    margin-bottom: 4.375rem !important; }
  .ml-lg-70,
  .mx-lg-70 {
    margin-left: 4.375rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-5 {
    padding: 0.3125rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 0.3125rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 0.3125rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 0.3125rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 0.3125rem !important; }
  .p-lg-10 {
    padding: 0.625rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 0.625rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 0.625rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 0.625rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 0.625rem !important; }
  .p-lg-15 {
    padding: 0.9375rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 0.9375rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 0.9375rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 0.9375rem !important; }
  .p-lg-20 {
    padding: 1.25rem !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 1.25rem !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 1.25rem !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 1.25rem !important; }
  .p-lg-25 {
    padding: 1.563rem !important; }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 1.563rem !important; }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 1.563rem !important; }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 1.563rem !important; }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 1.563rem !important; }
  .p-lg-30 {
    padding: 1.875rem !important; }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 1.875rem !important; }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 1.875rem !important; }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 1.875rem !important; }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 1.875rem !important; }
  .p-lg-40 {
    padding: 2.5rem !important; }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 2.5rem !important; }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 2.5rem !important; }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 2.5rem !important; }
  .p-lg-50 {
    padding: 3.125rem !important; }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 3.125rem !important; }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 3.125rem !important; }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 3.125rem !important; }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 3.125rem !important; }
  .p-lg-60 {
    padding: 3.75rem !important; }
  .pt-lg-60,
  .py-lg-60 {
    padding-top: 3.75rem !important; }
  .pr-lg-60,
  .px-lg-60 {
    padding-right: 3.75rem !important; }
  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-60,
  .px-lg-60 {
    padding-left: 3.75rem !important; }
  .p-lg-70 {
    padding: 4.375rem !important; }
  .pt-lg-70,
  .py-lg-70 {
    padding-top: 4.375rem !important; }
  .pr-lg-70,
  .px-lg-70 {
    padding-right: 4.375rem !important; }
  .pb-lg-70,
  .py-lg-70 {
    padding-bottom: 4.375rem !important; }
  .pl-lg-70,
  .px-lg-70 {
    padding-left: 4.375rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-5 {
    margin: 0.3125rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 0.3125rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 0.3125rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 0.3125rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 0.3125rem !important; }
  .m-xl-10 {
    margin: 0.625rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 0.625rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 0.625rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 0.625rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 0.625rem !important; }
  .m-xl-15 {
    margin: 0.9375rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 0.9375rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 0.9375rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 0.9375rem !important; }
  .m-xl-20 {
    margin: 1.25rem !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 1.25rem !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 1.25rem !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 1.25rem !important; }
  .m-xl-25 {
    margin: 1.563rem !important; }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 1.563rem !important; }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 1.563rem !important; }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 1.563rem !important; }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 1.563rem !important; }
  .m-xl-30 {
    margin: 1.875rem !important; }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 1.875rem !important; }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 1.875rem !important; }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 1.875rem !important; }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 1.875rem !important; }
  .m-xl-40 {
    margin: 2.5rem !important; }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 2.5rem !important; }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 2.5rem !important; }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 2.5rem !important; }
  .m-xl-50 {
    margin: 3.125rem !important; }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 3.125rem !important; }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 3.125rem !important; }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 3.125rem !important; }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 3.125rem !important; }
  .m-xl-60 {
    margin: 3.75rem !important; }
  .mt-xl-60,
  .my-xl-60 {
    margin-top: 3.75rem !important; }
  .mr-xl-60,
  .mx-xl-60 {
    margin-right: 3.75rem !important; }
  .mb-xl-60,
  .my-xl-60 {
    margin-bottom: 3.75rem !important; }
  .ml-xl-60,
  .mx-xl-60 {
    margin-left: 3.75rem !important; }
  .m-xl-70 {
    margin: 4.375rem !important; }
  .mt-xl-70,
  .my-xl-70 {
    margin-top: 4.375rem !important; }
  .mr-xl-70,
  .mx-xl-70 {
    margin-right: 4.375rem !important; }
  .mb-xl-70,
  .my-xl-70 {
    margin-bottom: 4.375rem !important; }
  .ml-xl-70,
  .mx-xl-70 {
    margin-left: 4.375rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-5 {
    padding: 0.3125rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 0.3125rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 0.3125rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 0.3125rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 0.3125rem !important; }
  .p-xl-10 {
    padding: 0.625rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 0.625rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 0.625rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 0.625rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 0.625rem !important; }
  .p-xl-15 {
    padding: 0.9375rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 0.9375rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 0.9375rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 0.9375rem !important; }
  .p-xl-20 {
    padding: 1.25rem !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 1.25rem !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 1.25rem !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 1.25rem !important; }
  .p-xl-25 {
    padding: 1.563rem !important; }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 1.563rem !important; }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 1.563rem !important; }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 1.563rem !important; }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 1.563rem !important; }
  .p-xl-30 {
    padding: 1.875rem !important; }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 1.875rem !important; }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 1.875rem !important; }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 1.875rem !important; }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 1.875rem !important; }
  .p-xl-40 {
    padding: 2.5rem !important; }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 2.5rem !important; }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 2.5rem !important; }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 2.5rem !important; }
  .p-xl-50 {
    padding: 3.125rem !important; }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 3.125rem !important; }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 3.125rem !important; }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 3.125rem !important; }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 3.125rem !important; }
  .p-xl-60 {
    padding: 3.75rem !important; }
  .pt-xl-60,
  .py-xl-60 {
    padding-top: 3.75rem !important; }
  .pr-xl-60,
  .px-xl-60 {
    padding-right: 3.75rem !important; }
  .pb-xl-60,
  .py-xl-60 {
    padding-bottom: 3.75rem !important; }
  .pl-xl-60,
  .px-xl-60 {
    padding-left: 3.75rem !important; }
  .p-xl-70 {
    padding: 4.375rem !important; }
  .pt-xl-70,
  .py-xl-70 {
    padding-top: 4.375rem !important; }
  .pr-xl-70,
  .px-xl-70 {
    padding-right: 4.375rem !important; }
  .pb-xl-70,
  .py-xl-70 {
    padding-bottom: 4.375rem !important; }
  .pl-xl-70,
  .px-xl-70 {
    padding-left: 4.375rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-light-yellow {
  color: #FFF59D !important; }

a.text-light-yellow:hover, a.text-light-yellow:focus {
  color: #fff06a !important; }

.text-twitter {
  color: #52AEFF !important; }

a.text-twitter:hover, a.text-twitter:focus {
  color: #1f96ff !important; }

.text-facebook {
  color: #405DE6 !important; }

a.text-facebook:hover, a.text-facebook:focus {
  color: #1c3dd7 !important; }

.text-google {
  color: #F13A30 !important; }

a.text-google:hover, a.text-google:focus {
  color: #df1a0f !important; }

.text-pinterest {
  color: #d32f2f !important; }

a.text-pinterest:hover, a.text-pinterest:focus {
  color: #ab2424 !important; }

.text-instagram {
  color: #8d6e63 !important; }

a.text-instagram:hover, a.text-instagram:focus {
  color: #6f574e !important; }

.text-rss {
  color: #ff9800 !important; }

a.text-rss:hover, a.text-rss:focus {
  color: #cc7a00 !important; }

.text-tumblr {
  color: #607d8b !important; }

a.text-tumblr:hover, a.text-tumblr:focus {
  color: #4b626d !important; }

.text-linkedin {
  color: #0876DE !important; }

a.text-linkedin:hover, a.text-linkedin:focus {
  color: #065cad !important; }

.text-dribbble {
  color: #f06292 !important; }

a.text-dribbble:hover, a.text-dribbble:focus {
  color: #ec3372 !important; }

.text-youtube {
  color: #d50000 !important; }

a.text-youtube:hover, a.text-youtube:focus {
  color: #a20000 !important; }

.text-github {
  color: #424242 !important; }

a.text-github:hover, a.text-github:focus {
  color: #292929 !important; }

.text-skype {
  color: #4fc3f7 !important; }

a.text-skype:hover, a.text-skype:focus {
  color: #1eb2f5 !important; }

.text-primary {
  color: #FA870E !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #d16d04 !important; }

.text-secondary {
  color: #677080 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #505764 !important; }

.text-success {
  color: #00D014 !important; }

a.text-success:hover, a.text-success:focus {
  color: #009d0f !important; }

.text-info {
  color: #00D0BD !important; }

a.text-info:hover, a.text-info:focus {
  color: #009d8f !important; }

.text-warning {
  color: #FFB70F !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #db9900 !important; }

.text-danger {
  color: #FF3739 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ff0407 !important; }

.text-light {
  color: #FAFAFA !important; }

a.text-light:hover, a.text-light:focus {
  color: #e1e1e1 !important; }

.text-dark {
  color: #464D69 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #32374a !important; }

.text-orange {
  color: #FF7F50 !important; }

a.text-orange:hover, a.text-orange:focus {
  color: #ff5a1d !important; }

.text-purple {
  color: #9370DB !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #7447d1 !important; }

.text-muted {
  color: #727891 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

.rtl {
  direction: rtl;
  text-align: right; }
  .rtl .nav {
    padding-right: 0; }
  .rtl .navbar-nav .nav-item {
    float: right; }
  .rtl .navbar-nav .nav-item + .nav-item {
    margin-right: 1rem;
    margin-left: inherit; }
  .rtl th {
    text-align: right; }
  .rtl .alert-dismissible {
    padding-right: 1.25rem;
    padding-left: 4rem; }
  .rtl .dropdown-menu {
    right: 0;
    text-align: right; }
  .rtl .checkbox label {
    padding-right: 1.25rem;
    padding-left: inherit; }
  .rtl .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 0.25rem 0.25rem 0; }
  .rtl .btn-group > .btn:last-child:not(:first-child),
  .rtl .btn-group > .dropdown-toggle:not(:first-child) {
    border-radius: 0.25rem 0 0 0.25rem; }
  .rtl .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-radius: 0.25rem 0 0 0.25rem; }
  .rtl .custom-control-label::after,
  .rtl .custom-control-label::before {
    right: 0;
    left: inherit; }
  .rtl .custom-select {
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23677080' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center;
    background-size: 8px 10px; }
  .rtl .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .rtl .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .rtl .input-group > .input-group-append:not(:last-child) > .btn,
  .rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .rtl .input-group > .input-group-prepend > .btn,
  .rtl .input-group > .input-group-prepend > .input-group-text {
    border-radius: 0 0.25rem 0.25rem 0; }
  .rtl .input-group > .input-group-append > .btn,
  .rtl .input-group > .input-group-append > .input-group-text,
  .rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .rtl .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
  .rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
  .rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-radius: 0.25rem 0 0 0.25rem; }
  .rtl .input-group > .custom-select:not(:first-child),
  .rtl .input-group > .form-control:not(:first-child) {
    border-radius: 0.25rem 0 0 0.25rem; }
  .rtl .input-group > .custom-select:not(:last-child),
  .rtl .input-group > .form-control:not(:last-child) {
    border-radius: 0 0.25rem 0.25rem 0; }
  .rtl .input-group > .custom-select:not(:last-child):not(:first-child),
  .rtl .input-group > .form-control:not(:last-child):not(:first-child) {
    border-radius: 0; }
  .rtl .custom-control {
    padding-right: 1.5rem;
    padding-left: inherit;
    margin-right: inherit;
    margin-left: 1rem; }
  .rtl .custom-control-indicator {
    right: 0;
    left: inherit; }
  .rtl .custom-file-label::after {
    right: initial;
    left: -1px;
    border-radius: .25rem 0 0 .25rem; }
  .rtl .radio input,
  .rtl .radio-inline,
  .rtl .checkbox input,
  .rtl .checkbox-inline input {
    margin-right: -1.25rem;
    margin-left: inherit; }
  .rtl .list-group {
    padding-right: 0;
    padding-left: 40px; }
  .rtl .close {
    float: left; }
  .rtl .modal-header .close {
    margin: -15px auto -15px -15px; }
  .rtl .modal-footer > :not(:first-child) {
    margin-right: .25rem; }
  .rtl .alert-dismissible .close {
    right: inherit;
    left: 0; }
  .rtl .dropdown-toggle::after {
    margin-right: .255em;
    margin-left: 0; }
  .rtl .form-check-input {
    margin-right: -1.25rem;
    margin-left: inherit; }
  .rtl .form-check-label {
    padding-right: 1.25rem;
    padding-left: inherit; }
  .rtl .offset-1 {
    margin-right: 8.33333%;
    margin-left: 0; }
  .rtl .offset-2 {
    margin-right: 16.66667%;
    margin-left: 0; }
  .rtl .offset-3 {
    margin-right: 25%;
    margin-left: 0; }
  .rtl .offset-4 {
    margin-right: 33.33333%;
    margin-left: 0; }
  .rtl .offset-5 {
    margin-right: 41.66667%;
    margin-left: 0; }
  .rtl .offset-6 {
    margin-right: 50%;
    margin-left: 0; }
  .rtl .offset-7 {
    margin-right: 58.33333%;
    margin-left: 0; }
  .rtl .offset-8 {
    margin-right: 66.66667%;
    margin-left: 0; }
  .rtl .offset-9 {
    margin-right: 75%;
    margin-left: 0; }
  .rtl .offset-10 {
    margin-right: 83.33333%;
    margin-left: 0; }
  .rtl .offset-11 {
    margin-right: 91.66667%;
    margin-left: 0; }
  @media (min-width: 576px) {
    .rtl .offset-sm-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-sm-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-sm-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-sm-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-sm-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-sm-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-sm-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-sm-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-sm-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-sm-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-sm-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-sm-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 768px) {
    .rtl .offset-md-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-md-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-md-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-md-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-md-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-md-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-md-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-md-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-md-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-md-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-md-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-md-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 992px) {
    .rtl .offset-lg-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-lg-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-lg-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-lg-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-lg-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-lg-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-lg-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-lg-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-lg-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-lg-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-lg-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-lg-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 1200px) {
    .rtl .offset-xl-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-xl-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-xl-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-xl-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-xl-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-xl-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-xl-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-xl-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-xl-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-xl-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-xl-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-xl-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  .rtl .mr-0,
  .rtl .mx-0 {
    margin-left: 0 !important; }
  .rtl .ml-0,
  .rtl .mx-0 {
    margin-right: 0 !important; }
  .rtl .mr-5,
  .rtl .mx-5 {
    margin-left: 0.3125rem !important; }
  .rtl .ml-5,
  .rtl .mx-5 {
    margin-right: 0.3125rem !important; }
  .rtl .mr-10,
  .rtl .mx-10 {
    margin-left: 0.625rem !important; }
  .rtl .ml-10,
  .rtl .mx-10 {
    margin-right: 0.625rem !important; }
  .rtl .mr-15,
  .rtl .mx-15 {
    margin-left: 0.9375rem !important; }
  .rtl .ml-15,
  .rtl .mx-15 {
    margin-right: 0.9375rem !important; }
  .rtl .mr-20,
  .rtl .mx-20 {
    margin-left: 1.25rem !important; }
  .rtl .ml-20,
  .rtl .mx-20 {
    margin-right: 1.25rem !important; }
  .rtl .mr-25,
  .rtl .mx-25 {
    margin-left: 1.563rem !important; }
  .rtl .ml-25,
  .rtl .mx-25 {
    margin-right: 1.563rem !important; }
  .rtl .mr-30,
  .rtl .mx-30 {
    margin-left: 1.875rem !important; }
  .rtl .ml-30,
  .rtl .mx-30 {
    margin-right: 1.875rem !important; }
  .rtl .mr-40,
  .rtl .mx-40 {
    margin-left: 2.5rem !important; }
  .rtl .ml-40,
  .rtl .mx-40 {
    margin-right: 2.5rem !important; }
  .rtl .mr-50,
  .rtl .mx-50 {
    margin-left: 3.125rem !important; }
  .rtl .ml-50,
  .rtl .mx-50 {
    margin-right: 3.125rem !important; }
  .rtl .mr-60,
  .rtl .mx-60 {
    margin-left: 3.75rem !important; }
  .rtl .ml-60,
  .rtl .mx-60 {
    margin-right: 3.75rem !important; }
  .rtl .mr-70,
  .rtl .mx-70 {
    margin-left: 4.375rem !important; }
  .rtl .ml-70,
  .rtl .mx-70 {
    margin-right: 4.375rem !important; }
  .rtl .pr-0,
  .rtl .px-0 {
    padding-left: 0 !important; }
  .rtl .pl-0,
  .rtl .px-0 {
    padding-right: 0 !important; }
  .rtl .pr-5,
  .rtl .px-5 {
    padding-left: 0.3125rem !important; }
  .rtl .pl-5,
  .rtl .px-5 {
    padding-right: 0.3125rem !important; }
  .rtl .pr-10,
  .rtl .px-10 {
    padding-left: 0.625rem !important; }
  .rtl .pl-10,
  .rtl .px-10 {
    padding-right: 0.625rem !important; }
  .rtl .pr-15,
  .rtl .px-15 {
    padding-left: 0.9375rem !important; }
  .rtl .pl-15,
  .rtl .px-15 {
    padding-right: 0.9375rem !important; }
  .rtl .pr-20,
  .rtl .px-20 {
    padding-left: 1.25rem !important; }
  .rtl .pl-20,
  .rtl .px-20 {
    padding-right: 1.25rem !important; }
  .rtl .pr-25,
  .rtl .px-25 {
    padding-left: 1.563rem !important; }
  .rtl .pl-25,
  .rtl .px-25 {
    padding-right: 1.563rem !important; }
  .rtl .pr-30,
  .rtl .px-30 {
    padding-left: 1.875rem !important; }
  .rtl .pl-30,
  .rtl .px-30 {
    padding-right: 1.875rem !important; }
  .rtl .pr-40,
  .rtl .px-40 {
    padding-left: 2.5rem !important; }
  .rtl .pl-40,
  .rtl .px-40 {
    padding-right: 2.5rem !important; }
  .rtl .pr-50,
  .rtl .px-50 {
    padding-left: 3.125rem !important; }
  .rtl .pl-50,
  .rtl .px-50 {
    padding-right: 3.125rem !important; }
  .rtl .pr-60,
  .rtl .px-60 {
    padding-left: 3.75rem !important; }
  .rtl .pl-60,
  .rtl .px-60 {
    padding-right: 3.75rem !important; }
  .rtl .pr-70,
  .rtl .px-70 {
    padding-left: 4.375rem !important; }
  .rtl .pl-70,
  .rtl .px-70 {
    padding-right: 4.375rem !important; }
  .rtl .mr-auto {
    margin-right: inherit !important;
    margin-left: auto !important; }
  .rtl .ml-auto {
    margin-right: auto !important;
    margin-left: inherit !important; }
  @media (min-width: 576px) {
    .rtl .mr-sm-0,
    .rtl .mx-sm-0 {
      margin-left: 0 !important; }
    .rtl .ml-sm-0,
    .rtl .mx-sm-0 {
      margin-right: 0 !important; }
    .rtl .mr-sm-5,
    .rtl .mx-sm-5 {
      margin-left: 0.3125rem !important; }
    .rtl .ml-sm-5,
    .rtl .mx-sm-5 {
      margin-right: 0.3125rem !important; }
    .rtl .mr-sm-10,
    .rtl .mx-sm-10 {
      margin-left: 0.625rem !important; }
    .rtl .ml-sm-10,
    .rtl .mx-sm-10 {
      margin-right: 0.625rem !important; }
    .rtl .mr-sm-15,
    .rtl .mx-sm-15 {
      margin-left: 0.9375rem !important; }
    .rtl .ml-sm-15,
    .rtl .mx-sm-15 {
      margin-right: 0.9375rem !important; }
    .rtl .mr-sm-20,
    .rtl .mx-sm-20 {
      margin-left: 1.25rem !important; }
    .rtl .ml-sm-20,
    .rtl .mx-sm-20 {
      margin-right: 1.25rem !important; }
    .rtl .mr-sm-25,
    .rtl .mx-sm-25 {
      margin-left: 1.563rem !important; }
    .rtl .ml-sm-25,
    .rtl .mx-sm-25 {
      margin-right: 1.563rem !important; }
    .rtl .mr-sm-30,
    .rtl .mx-sm-30 {
      margin-left: 1.875rem !important; }
    .rtl .ml-sm-30,
    .rtl .mx-sm-30 {
      margin-right: 1.875rem !important; }
    .rtl .mr-sm-40,
    .rtl .mx-sm-40 {
      margin-left: 2.5rem !important; }
    .rtl .ml-sm-40,
    .rtl .mx-sm-40 {
      margin-right: 2.5rem !important; }
    .rtl .mr-sm-50,
    .rtl .mx-sm-50 {
      margin-left: 3.125rem !important; }
    .rtl .ml-sm-50,
    .rtl .mx-sm-50 {
      margin-right: 3.125rem !important; }
    .rtl .mr-sm-60,
    .rtl .mx-sm-60 {
      margin-left: 3.75rem !important; }
    .rtl .ml-sm-60,
    .rtl .mx-sm-60 {
      margin-right: 3.75rem !important; }
    .rtl .mr-sm-70,
    .rtl .mx-sm-70 {
      margin-left: 4.375rem !important; }
    .rtl .ml-sm-70,
    .rtl .mx-sm-70 {
      margin-right: 4.375rem !important; }
    .rtl .pr-sm-0,
    .rtl .px-sm-0 {
      padding-left: 0 !important; }
    .rtl .pl-sm-0,
    .rtl .px-sm-0 {
      padding-right: 0 !important; }
    .rtl .pr-sm-5,
    .rtl .px-sm-5 {
      padding-left: 0.3125rem !important; }
    .rtl .pl-sm-5,
    .rtl .px-sm-5 {
      padding-right: 0.3125rem !important; }
    .rtl .pr-sm-10,
    .rtl .px-sm-10 {
      padding-left: 0.625rem !important; }
    .rtl .pl-sm-10,
    .rtl .px-sm-10 {
      padding-right: 0.625rem !important; }
    .rtl .pr-sm-15,
    .rtl .px-sm-15 {
      padding-left: 0.9375rem !important; }
    .rtl .pl-sm-15,
    .rtl .px-sm-15 {
      padding-right: 0.9375rem !important; }
    .rtl .pr-sm-20,
    .rtl .px-sm-20 {
      padding-left: 1.25rem !important; }
    .rtl .pl-sm-20,
    .rtl .px-sm-20 {
      padding-right: 1.25rem !important; }
    .rtl .pr-sm-25,
    .rtl .px-sm-25 {
      padding-left: 1.563rem !important; }
    .rtl .pl-sm-25,
    .rtl .px-sm-25 {
      padding-right: 1.563rem !important; }
    .rtl .pr-sm-30,
    .rtl .px-sm-30 {
      padding-left: 1.875rem !important; }
    .rtl .pl-sm-30,
    .rtl .px-sm-30 {
      padding-right: 1.875rem !important; }
    .rtl .pr-sm-40,
    .rtl .px-sm-40 {
      padding-left: 2.5rem !important; }
    .rtl .pl-sm-40,
    .rtl .px-sm-40 {
      padding-right: 2.5rem !important; }
    .rtl .pr-sm-50,
    .rtl .px-sm-50 {
      padding-left: 3.125rem !important; }
    .rtl .pl-sm-50,
    .rtl .px-sm-50 {
      padding-right: 3.125rem !important; }
    .rtl .pr-sm-60,
    .rtl .px-sm-60 {
      padding-left: 3.75rem !important; }
    .rtl .pl-sm-60,
    .rtl .px-sm-60 {
      padding-right: 3.75rem !important; }
    .rtl .pr-sm-70,
    .rtl .px-sm-70 {
      padding-left: 4.375rem !important; }
    .rtl .pl-sm-70,
    .rtl .px-sm-70 {
      padding-right: 4.375rem !important; }
    .rtl .mr-sm-auto {
      margin-right: inherit !important;
      margin-left: auto !important; }
    .rtl .ml-sm-auto {
      margin-right: auto !important;
      margin-left: inherit !important; } }
  @media (min-width: 768px) {
    .rtl .mr-md-0,
    .rtl .mx-md-0 {
      margin-left: 0 !important; }
    .rtl .ml-md-0,
    .rtl .mx-md-0 {
      margin-right: 0 !important; }
    .rtl .mr-md-5,
    .rtl .mx-md-5 {
      margin-left: 0.3125rem !important; }
    .rtl .ml-md-5,
    .rtl .mx-md-5 {
      margin-right: 0.3125rem !important; }
    .rtl .mr-md-10,
    .rtl .mx-md-10 {
      margin-left: 0.625rem !important; }
    .rtl .ml-md-10,
    .rtl .mx-md-10 {
      margin-right: 0.625rem !important; }
    .rtl .mr-md-15,
    .rtl .mx-md-15 {
      margin-left: 0.9375rem !important; }
    .rtl .ml-md-15,
    .rtl .mx-md-15 {
      margin-right: 0.9375rem !important; }
    .rtl .mr-md-20,
    .rtl .mx-md-20 {
      margin-left: 1.25rem !important; }
    .rtl .ml-md-20,
    .rtl .mx-md-20 {
      margin-right: 1.25rem !important; }
    .rtl .mr-md-25,
    .rtl .mx-md-25 {
      margin-left: 1.563rem !important; }
    .rtl .ml-md-25,
    .rtl .mx-md-25 {
      margin-right: 1.563rem !important; }
    .rtl .mr-md-30,
    .rtl .mx-md-30 {
      margin-left: 1.875rem !important; }
    .rtl .ml-md-30,
    .rtl .mx-md-30 {
      margin-right: 1.875rem !important; }
    .rtl .mr-md-40,
    .rtl .mx-md-40 {
      margin-left: 2.5rem !important; }
    .rtl .ml-md-40,
    .rtl .mx-md-40 {
      margin-right: 2.5rem !important; }
    .rtl .mr-md-50,
    .rtl .mx-md-50 {
      margin-left: 3.125rem !important; }
    .rtl .ml-md-50,
    .rtl .mx-md-50 {
      margin-right: 3.125rem !important; }
    .rtl .mr-md-60,
    .rtl .mx-md-60 {
      margin-left: 3.75rem !important; }
    .rtl .ml-md-60,
    .rtl .mx-md-60 {
      margin-right: 3.75rem !important; }
    .rtl .mr-md-70,
    .rtl .mx-md-70 {
      margin-left: 4.375rem !important; }
    .rtl .ml-md-70,
    .rtl .mx-md-70 {
      margin-right: 4.375rem !important; }
    .rtl .pr-md-0,
    .rtl .px-md-0 {
      padding-left: 0 !important; }
    .rtl .pl-md-0,
    .rtl .px-md-0 {
      padding-right: 0 !important; }
    .rtl .pr-md-5,
    .rtl .px-md-5 {
      padding-left: 0.3125rem !important; }
    .rtl .pl-md-5,
    .rtl .px-md-5 {
      padding-right: 0.3125rem !important; }
    .rtl .pr-md-10,
    .rtl .px-md-10 {
      padding-left: 0.625rem !important; }
    .rtl .pl-md-10,
    .rtl .px-md-10 {
      padding-right: 0.625rem !important; }
    .rtl .pr-md-15,
    .rtl .px-md-15 {
      padding-left: 0.9375rem !important; }
    .rtl .pl-md-15,
    .rtl .px-md-15 {
      padding-right: 0.9375rem !important; }
    .rtl .pr-md-20,
    .rtl .px-md-20 {
      padding-left: 1.25rem !important; }
    .rtl .pl-md-20,
    .rtl .px-md-20 {
      padding-right: 1.25rem !important; }
    .rtl .pr-md-25,
    .rtl .px-md-25 {
      padding-left: 1.563rem !important; }
    .rtl .pl-md-25,
    .rtl .px-md-25 {
      padding-right: 1.563rem !important; }
    .rtl .pr-md-30,
    .rtl .px-md-30 {
      padding-left: 1.875rem !important; }
    .rtl .pl-md-30,
    .rtl .px-md-30 {
      padding-right: 1.875rem !important; }
    .rtl .pr-md-40,
    .rtl .px-md-40 {
      padding-left: 2.5rem !important; }
    .rtl .pl-md-40,
    .rtl .px-md-40 {
      padding-right: 2.5rem !important; }
    .rtl .pr-md-50,
    .rtl .px-md-50 {
      padding-left: 3.125rem !important; }
    .rtl .pl-md-50,
    .rtl .px-md-50 {
      padding-right: 3.125rem !important; }
    .rtl .pr-md-60,
    .rtl .px-md-60 {
      padding-left: 3.75rem !important; }
    .rtl .pl-md-60,
    .rtl .px-md-60 {
      padding-right: 3.75rem !important; }
    .rtl .pr-md-70,
    .rtl .px-md-70 {
      padding-left: 4.375rem !important; }
    .rtl .pl-md-70,
    .rtl .px-md-70 {
      padding-right: 4.375rem !important; }
    .rtl .mr-md-auto {
      margin-right: inherit !important;
      margin-left: auto !important; }
    .rtl .ml-md-auto {
      margin-right: auto !important;
      margin-left: inherit !important; } }
  @media (min-width: 992px) {
    .rtl .mr-lg-0,
    .rtl .mx-lg-0 {
      margin-left: 0 !important; }
    .rtl .ml-lg-0,
    .rtl .mx-lg-0 {
      margin-right: 0 !important; }
    .rtl .mr-lg-5,
    .rtl .mx-lg-5 {
      margin-left: 0.3125rem !important; }
    .rtl .ml-lg-5,
    .rtl .mx-lg-5 {
      margin-right: 0.3125rem !important; }
    .rtl .mr-lg-10,
    .rtl .mx-lg-10 {
      margin-left: 0.625rem !important; }
    .rtl .ml-lg-10,
    .rtl .mx-lg-10 {
      margin-right: 0.625rem !important; }
    .rtl .mr-lg-15,
    .rtl .mx-lg-15 {
      margin-left: 0.9375rem !important; }
    .rtl .ml-lg-15,
    .rtl .mx-lg-15 {
      margin-right: 0.9375rem !important; }
    .rtl .mr-lg-20,
    .rtl .mx-lg-20 {
      margin-left: 1.25rem !important; }
    .rtl .ml-lg-20,
    .rtl .mx-lg-20 {
      margin-right: 1.25rem !important; }
    .rtl .mr-lg-25,
    .rtl .mx-lg-25 {
      margin-left: 1.563rem !important; }
    .rtl .ml-lg-25,
    .rtl .mx-lg-25 {
      margin-right: 1.563rem !important; }
    .rtl .mr-lg-30,
    .rtl .mx-lg-30 {
      margin-left: 1.875rem !important; }
    .rtl .ml-lg-30,
    .rtl .mx-lg-30 {
      margin-right: 1.875rem !important; }
    .rtl .mr-lg-40,
    .rtl .mx-lg-40 {
      margin-left: 2.5rem !important; }
    .rtl .ml-lg-40,
    .rtl .mx-lg-40 {
      margin-right: 2.5rem !important; }
    .rtl .mr-lg-50,
    .rtl .mx-lg-50 {
      margin-left: 3.125rem !important; }
    .rtl .ml-lg-50,
    .rtl .mx-lg-50 {
      margin-right: 3.125rem !important; }
    .rtl .mr-lg-60,
    .rtl .mx-lg-60 {
      margin-left: 3.75rem !important; }
    .rtl .ml-lg-60,
    .rtl .mx-lg-60 {
      margin-right: 3.75rem !important; }
    .rtl .mr-lg-70,
    .rtl .mx-lg-70 {
      margin-left: 4.375rem !important; }
    .rtl .ml-lg-70,
    .rtl .mx-lg-70 {
      margin-right: 4.375rem !important; }
    .rtl .pr-lg-0,
    .rtl .px-lg-0 {
      padding-left: 0 !important; }
    .rtl .pl-lg-0,
    .rtl .px-lg-0 {
      padding-right: 0 !important; }
    .rtl .pr-lg-5,
    .rtl .px-lg-5 {
      padding-left: 0.3125rem !important; }
    .rtl .pl-lg-5,
    .rtl .px-lg-5 {
      padding-right: 0.3125rem !important; }
    .rtl .pr-lg-10,
    .rtl .px-lg-10 {
      padding-left: 0.625rem !important; }
    .rtl .pl-lg-10,
    .rtl .px-lg-10 {
      padding-right: 0.625rem !important; }
    .rtl .pr-lg-15,
    .rtl .px-lg-15 {
      padding-left: 0.9375rem !important; }
    .rtl .pl-lg-15,
    .rtl .px-lg-15 {
      padding-right: 0.9375rem !important; }
    .rtl .pr-lg-20,
    .rtl .px-lg-20 {
      padding-left: 1.25rem !important; }
    .rtl .pl-lg-20,
    .rtl .px-lg-20 {
      padding-right: 1.25rem !important; }
    .rtl .pr-lg-25,
    .rtl .px-lg-25 {
      padding-left: 1.563rem !important; }
    .rtl .pl-lg-25,
    .rtl .px-lg-25 {
      padding-right: 1.563rem !important; }
    .rtl .pr-lg-30,
    .rtl .px-lg-30 {
      padding-left: 1.875rem !important; }
    .rtl .pl-lg-30,
    .rtl .px-lg-30 {
      padding-right: 1.875rem !important; }
    .rtl .pr-lg-40,
    .rtl .px-lg-40 {
      padding-left: 2.5rem !important; }
    .rtl .pl-lg-40,
    .rtl .px-lg-40 {
      padding-right: 2.5rem !important; }
    .rtl .pr-lg-50,
    .rtl .px-lg-50 {
      padding-left: 3.125rem !important; }
    .rtl .pl-lg-50,
    .rtl .px-lg-50 {
      padding-right: 3.125rem !important; }
    .rtl .pr-lg-60,
    .rtl .px-lg-60 {
      padding-left: 3.75rem !important; }
    .rtl .pl-lg-60,
    .rtl .px-lg-60 {
      padding-right: 3.75rem !important; }
    .rtl .pr-lg-70,
    .rtl .px-lg-70 {
      padding-left: 4.375rem !important; }
    .rtl .pl-lg-70,
    .rtl .px-lg-70 {
      padding-right: 4.375rem !important; }
    .rtl .mr-lg-auto {
      margin-right: inherit !important;
      margin-left: auto !important; }
    .rtl .ml-lg-auto {
      margin-right: auto !important;
      margin-left: inherit !important; } }
  @media (min-width: 1200px) {
    .rtl .mr-xl-0,
    .rtl .mx-xl-0 {
      margin-left: 0 !important; }
    .rtl .ml-xl-0,
    .rtl .mx-xl-0 {
      margin-right: 0 !important; }
    .rtl .mr-xl-5,
    .rtl .mx-xl-5 {
      margin-left: 0.3125rem !important; }
    .rtl .ml-xl-5,
    .rtl .mx-xl-5 {
      margin-right: 0.3125rem !important; }
    .rtl .mr-xl-10,
    .rtl .mx-xl-10 {
      margin-left: 0.625rem !important; }
    .rtl .ml-xl-10,
    .rtl .mx-xl-10 {
      margin-right: 0.625rem !important; }
    .rtl .mr-xl-15,
    .rtl .mx-xl-15 {
      margin-left: 0.9375rem !important; }
    .rtl .ml-xl-15,
    .rtl .mx-xl-15 {
      margin-right: 0.9375rem !important; }
    .rtl .mr-xl-20,
    .rtl .mx-xl-20 {
      margin-left: 1.25rem !important; }
    .rtl .ml-xl-20,
    .rtl .mx-xl-20 {
      margin-right: 1.25rem !important; }
    .rtl .mr-xl-25,
    .rtl .mx-xl-25 {
      margin-left: 1.563rem !important; }
    .rtl .ml-xl-25,
    .rtl .mx-xl-25 {
      margin-right: 1.563rem !important; }
    .rtl .mr-xl-30,
    .rtl .mx-xl-30 {
      margin-left: 1.875rem !important; }
    .rtl .ml-xl-30,
    .rtl .mx-xl-30 {
      margin-right: 1.875rem !important; }
    .rtl .mr-xl-40,
    .rtl .mx-xl-40 {
      margin-left: 2.5rem !important; }
    .rtl .ml-xl-40,
    .rtl .mx-xl-40 {
      margin-right: 2.5rem !important; }
    .rtl .mr-xl-50,
    .rtl .mx-xl-50 {
      margin-left: 3.125rem !important; }
    .rtl .ml-xl-50,
    .rtl .mx-xl-50 {
      margin-right: 3.125rem !important; }
    .rtl .mr-xl-60,
    .rtl .mx-xl-60 {
      margin-left: 3.75rem !important; }
    .rtl .ml-xl-60,
    .rtl .mx-xl-60 {
      margin-right: 3.75rem !important; }
    .rtl .mr-xl-70,
    .rtl .mx-xl-70 {
      margin-left: 4.375rem !important; }
    .rtl .ml-xl-70,
    .rtl .mx-xl-70 {
      margin-right: 4.375rem !important; }
    .rtl .pr-xl-0,
    .rtl .px-xl-0 {
      padding-left: 0 !important; }
    .rtl .pl-xl-0,
    .rtl .px-xl-0 {
      padding-right: 0 !important; }
    .rtl .pr-xl-5,
    .rtl .px-xl-5 {
      padding-left: 0.3125rem !important; }
    .rtl .pl-xl-5,
    .rtl .px-xl-5 {
      padding-right: 0.3125rem !important; }
    .rtl .pr-xl-10,
    .rtl .px-xl-10 {
      padding-left: 0.625rem !important; }
    .rtl .pl-xl-10,
    .rtl .px-xl-10 {
      padding-right: 0.625rem !important; }
    .rtl .pr-xl-15,
    .rtl .px-xl-15 {
      padding-left: 0.9375rem !important; }
    .rtl .pl-xl-15,
    .rtl .px-xl-15 {
      padding-right: 0.9375rem !important; }
    .rtl .pr-xl-20,
    .rtl .px-xl-20 {
      padding-left: 1.25rem !important; }
    .rtl .pl-xl-20,
    .rtl .px-xl-20 {
      padding-right: 1.25rem !important; }
    .rtl .pr-xl-25,
    .rtl .px-xl-25 {
      padding-left: 1.563rem !important; }
    .rtl .pl-xl-25,
    .rtl .px-xl-25 {
      padding-right: 1.563rem !important; }
    .rtl .pr-xl-30,
    .rtl .px-xl-30 {
      padding-left: 1.875rem !important; }
    .rtl .pl-xl-30,
    .rtl .px-xl-30 {
      padding-right: 1.875rem !important; }
    .rtl .pr-xl-40,
    .rtl .px-xl-40 {
      padding-left: 2.5rem !important; }
    .rtl .pl-xl-40,
    .rtl .px-xl-40 {
      padding-right: 2.5rem !important; }
    .rtl .pr-xl-50,
    .rtl .px-xl-50 {
      padding-left: 3.125rem !important; }
    .rtl .pl-xl-50,
    .rtl .px-xl-50 {
      padding-right: 3.125rem !important; }
    .rtl .pr-xl-60,
    .rtl .px-xl-60 {
      padding-left: 3.75rem !important; }
    .rtl .pl-xl-60,
    .rtl .px-xl-60 {
      padding-right: 3.75rem !important; }
    .rtl .pr-xl-70,
    .rtl .px-xl-70 {
      padding-left: 4.375rem !important; }
    .rtl .pl-xl-70,
    .rtl .px-xl-70 {
      padding-right: 4.375rem !important; }
    .rtl .mr-xl-auto {
      margin-right: inherit !important;
      margin-left: auto !important; }
    .rtl .ml-xl-auto {
      margin-right: auto !important;
      margin-left: inherit !important; } }

.carousel-inner {
  max-height: 580px; }

.carousel-caption {
  right: 15%;
  top: 80px;
  left: 20%; }

.carousel-content-1, .carousel-content-3 {
  display: none;
  padding: 20px;
  font-size: 28px;
  line-height: 32px;
  font-weight: 800;
  background-color: #de543e;
  color: #ffffff; }

.carousel-content-2 {
  display: none; }
  .carousel-content-2 .title {
    display: flex; }
    .carousel-content-2 .title p {
      background-color: #4e5b6c;
      color: #ffffff;
      min-height: 0px;
      min-width: 0px;
      line-height: 26px;
      border-width: 0px;
      margin: 10px 0px;
      padding: 10px 30px;
      letter-spacing: 0px;
      font-size: 22px;
      font-weight: 800; }
  .carousel-content-2 .checkpoint {
    display: flex;
    flex-direction: row; }
    .carousel-content-2 .checkpoint .check {
      width: 40px;
      height: 40px;
      background-color: #4e5b6c;
      line-height: 20px;
      border-width: 0px;
      margin: 10px 10px 10px 0px;
      padding: 10px;
      letter-spacing: 0px;
      font-size: 20px; }
      .carousel-content-2 .checkpoint .check i {
        min-height: 0px;
        min-width: 0px;
        line-height: 20px;
        border-width: 0px;
        margin: auto;
        padding: 0px;
        letter-spacing: 0px;
        font-size: 20px; }
    .carousel-content-2 .checkpoint .point {
      background-color: #de543e;
      min-height: 0px;
      min-width: 0px;
      line-height: 36px;
      border-width: 0px;
      margin: 10px 0px;
      padding: 1px 10px 3px;
      letter-spacing: 0px;
      font-size: 30px;
      font-weight: 200; }
  .carousel-content-2 .more {
    display: flex;
    margin: 10px 0px; }
    .carousel-content-2 .more p {
      padding: 10px;
      background-color: #32c5d2; }
      .carousel-content-2 .more p:hover {
        background-color: #4e5b6c; }
      .carousel-content-2 .more p a {
        color: #ffffff;
        min-height: 0px;
        min-width: 0px;
        line-height: 17px;
        border-width: 1px;
        margin: 5px;
        padding: 14px 28px 13px;
        letter-spacing: 0px;
        font-size: 13px;
        font-weight: 600; }

@media only screen and (min-width: 768px) {
  .carousel-content-1, .carousel-content-2, .carousel-content-3 {
    display: block; } }
