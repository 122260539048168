/* 
    Document   : assan
    Author     : Design_mylife
    Description:assan Themes kit
    Version    : V2.3
*/
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,300,300italic,400italic,600,700,900);
@import url(../pe-icons/Pe-icon-7-stroke.css);
body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  color: #999;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  background-color: #fff;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

/*
/*-----------------------------------------------------------------------------------*/
/*	RESET
/*-----------------------------------------------------------------------------------*/
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:focus {
  outline: 0 !important;
}

iframe {
  border: none;
  width: 100%;
}

::selection {
  background: #fefac7;
  /* Safari */
  color: #555555;
}

::-moz-selection {
  background: #fefac7;
  /* Firefox */
  color: #555555;
}

strong, b {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

input,
button,
select,
textarea, label {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

hr {
  background-image: -webkit-linear-gradient(left, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent);
  border: 0;
  height: 1px;
  margin: 22px 0 22px 0;
}

.badge {
  font-weight: normal;
  margin-left: 5px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  background-color: #32c5d2;
  padding: 3px 6px;
}

a {
  color: #333;
  -moz-transition: all 200ms ease-in;
  -o-transition: all 200ms ease-in;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

a:hover,
a:focus {
  color: #32c5d2;
  text-decoration: none;
}

.colored-text {
  color: #32c5d2;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  color: #060606;
  font-weight: 700;
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 3.2em;
  line-height: 44px;
  margin: 0 0 44px 0;
}

h2 {
  font-size: 2.6em;
  font-weight: 700;
  line-height: 42px;
  margin: 0 0 32px 0;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 24px;
  margin-bottom: 15px;
}

/*use in section headings*/
h3.heading {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  margin: 0 0 20px;
  padding-bottom: 10px;
  position: relative;
  text-transform: capitalize;
  overflow: hidden;
  vertical-align: middle;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

h3.heading:after {
  position: relative;
  left: 18px;
  content: "";
  display: inline-block;
  width: 200%;
  vertical-align: middle;
  height: .50em;
  margin: 0 -200% 0 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

h4 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 27px;
  margin: 0 0 14px 0;
}

h5 {
  font-size: 1em;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 18px;
  margin: 0 0 14px 0;
}

h6 {
  color: #333;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 18px;
  margin: 0 0 14px 0;
}

/*parahraphs*/
p {
  color: #555;
  line-height: 24px;
  margin: 0 0 20px;
}

p.sub-text {
  font-style: normal;
  font-size: 16px;
  line-height: 29px;
  font-weight: 300;
  color: #555;
}

p.lead {
  font-size: 16px;
  font-weight: 400;
}

pre:after, pre:before {
  clear: both;
  display: table;
  content: "";
}

header {
  z-index: 100;
}

.no-border {
  border: none;
}
.hidden-mobile {
  display: none;
}
.hidden-desktop {
  display: block;
}

/*******section heading**********/
.center-heading {
  text-align: center;
  margin-bottom: 40px;
}

.center-heading h2 {
  margin-bottom: 0;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0px;
  color: #333;
  font-size: 20px;
}

.center-heading p {
  font-size: 16px;
  line-height: 30px;
}

.center-heading h2 strong {
  font-weight: 700;
}

.center-line {
  display: inline-block;
  width: 70px;
  height: 1px;
  border-top: 1px solid #bbb;
  /* border-bottom: 1px solid $skincolor; */
  margin: auto;
}

.center-heading p {
  margin-top: 10px;
}

.overflow-hidden {
  overflow: hidden;
}

/*-----------------------------------------------------------------------------------*/
/*	DIVIDERS
/*-----------------------------------------------------------------------------------*/
.divide0 {
  width: 100%;
  height: 0px;
}

.divide2 {
  width: 100%;
  height: 2px;
}

.divide3 {
  width: 100%;
  height: 3px;
}

.divide5 {
  width: 100%;
  height: 5px;
}

.divide10 {
  width: 100%;
  height: 10px;
}

.divide15 {
  width: 100%;
  height: 15px;
}

.divide20 {
  width: 100%;
  height: 20px;
}

.divide25 {
  width: 100%;
  height: 25px;
}

.divide30 {
  width: 100%;
  height: 30px;
}

.divide35 {
  width: 100%;
  height: 35px;
}

.divide40 {
  width: 100%;
  height: 40px;
}

.divide45 {
  width: 100%;
  height: 40px;
}

.divide50 {
  width: 100%;
  height: 50px;
}

.divide55 {
  width: 100%;
  height: 55px;
}

.divide60 {
  width: 100%;
  height: 60px;
}

.divide65 {
  width: 100%;
  height: 65px;
}

.divide70 {
  width: 100%;
  height: 70px;
}

.divide75 {
  width: 100%;
  height: 75px;
}

.divide80 {
  width: 100%;
  height: 80px;
}

.divide85 {
  width: 100%;
  height: 85px;
}

/*margins*/
.margin10 {
  margin-bottom: 10px;
}

.margin20 {
  margin-bottom: 20px;
}

.margin30 {
  margin-bottom: 30px;
}

.margin40 {
  margin-bottom: 40px;
}

.no-padding {
  padding: 0px;
}

.no-padding.gray {
  background-color: #f5f5f5;
}

.no-padding-inner h3 {
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 40px;
}

.no-padding-inner {
  padding: 60px;
}

@media (max-width: 568px) {
  .no-padding-inner {
    padding: 15px;
  }
}
.margin-0 {
  margin: 0px !important;
}

/*************************************************************/
/************************** Buttons **************************/
/*************************************************************/
.btn {
  font-weight: 600;
  letter-spacing: 0px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  font-size: 14px;
  text-transform: uppercase;
}

.btn-default:hover, .btn-default:focus {
  background-color: #333;
  border-color: #333;
  color: #fff;
}

.btn-lg {
  padding: 14px 28px 13px 28px;
  font-size: 13px;
}

.btn:focus, .btn:hover {
  outline: 0;
  color: #fff;
}

.btn-xs {
  font-size: 12px;
}

.btn-theme-bg {
  border-color: #32c5d2;
  color: #fff;
  background-color: #32c5d2;
}

.btn i {
  margin-right: 10px;
}

.btn-theme-bg:hover {
  background-color: #333;
  border-color: #333;
}

.btn-theme-bg-disabled, .btn-theme-bg-disabled:hover {
  border-color: #32c5d2;
  color: #fff;
  background-color: #32c5d2;
}

.btn-theme-dark {
  background-color: #333;
  color: #fff;
}

.btn-theme-dark:hover {
  color: #fff;
  background-color: #32c5d2;
}

/*********border-buttons**********/
.border-black {
  border: 1px solid #333;
  color: #000;
  background-color: transparent;
}

.border-black:hover {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.border-white {
  border: 1px solid #fff;
  color: #fff;
}

.border-white:hover {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}

.border-theme {
  border: 1px solid #32c5d2;
  color: #32c5d2;
}

.border-theme:hover {
  background-color: #32c5d2;
  border-color: #32c5d2;
  color: #fff;
}

.btn-3d {
  border-bottom: 3px solid rgba(0, 0, 0, 0.3);
}

.btn-ico i {
  margin-left: 5px;
}

/***********bootstrap navigation default overrides--*/

.navbar-default {
  border: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  margin-bottom: 0;
  width: 100%;
  min-height: 70px;
  padding: 10px 0;
  -webkit-box-shadow: rgba(0, 0, 0, 0.11765) 0px 1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.11765) 0px 1px 3px;
  -ms-box-shadow: rgba(0, 0, 0, 0.11765) 0px 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.11765) 0px 1px 3px;
  background-color: rgba(255, 255, 255, 0.9);
  clear: both;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.navbar-default .navbar-nav {
  display: flex;
  flex-direction: column;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #32c5d2;
  background-color: transparent;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: #32c5d2;
  background-color: transparent;
}

.navbar-brand {
  font-weight: 700;
  color: #000 !important;
  font-size: 30px;
  line-height: 20px;
  font-style: normal;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  width: 200px;
  height: 60px;
  background: url(../img/logo.png) no-repeat scroll left top;
}

.navbar-default .navbar-nav > li {
    background: none;
}

.navbar-default .navbar-nav > li > a > span {
  color: #687074;
  font-size: 14px;
  font-weight: 500;
  margin: 0 20px;
  text-transform: capitalize;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.navbar-default .navbar-nav > li.last > a > span {
  margin-right: 10px;
}

.navbar-default .navbar-nav > li > a:hover > span {
  color: #32c5d2;
  border-bottom: 1px solid #32c5d2;
  padding-bottom: 2px;
}

.navbar-brand img {
  width: 80px;
  height: auto;
}

/*============================*/
/*======dropdowm menu=========*/
/*============================*/
.navbar .dropdown-menu {
  padding: 0px;
  margin: 0;
  min-width: 200px;
  background-color: #fff;
  border: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  border: 1px solid #eee;
}

.search-dropdown {
  min-width: 244px !important;
}

.navbar .dropdown-menu li a {
  color: #777;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #f5f5f5;
  padding: 6px 16px;
  line-height: 1.42857143;
  text-transform: capitalize;
}

.dropdown-menu .label {
  margin-top: 6px;
}

.navbar .dropdown-menu li a:hover {
  background-color: #f5f5f5;
}

/*********************
TOP BARS
****************************/
.top-bar-dark {
  background-color: #333;
}

a .top-bar-dark {
  color: #ffffff;
}

.top-bar-light {
  background-color: #f3f3f3;
}
.top-bar-light .top-dark-right li {
  border-color: #ddd;
}

.top-bar-light .top-dark-right li a:hover {
  color: #32c5d2;
}

.top-bar-socials {
  line-height: 18px;
  padding-top: 5px;
}
.top-bar-socials:after {
  display: table;
  clear: both;
  content: "";
}
.top-bar-socials a {
  margin: 0px 3px;
}

.top-dark-right {
  margin: 0px;
  padding: 0px;
}

.top-dark-right li {
  line-height: 40px;
  /* border-left: 1px solid #444; */
  padding: 0px 10px;
}

.top-dark-right li, .top-dark-right li a {
  color: #bbb;
  font-size: 12px;
}

.top-dark-right li i {
  margin-right: 5px;
}

.top-dark-right li a:hover {
  color: #fff;
}

/***search top bar**/
.topbar-icons {
  display: block;
}

.topbar-icons i {
  margin: 0px !important;
  display: block;
}

.search {
  display: none;
  position: absolute;
  left: 0;
  right: 15px;
  top: 0;
  height: 100%;
  z-index: 99999;
}

.search .form-control {
  height: 100%;
  position: absolute;
  top: 0;
  width: 99%;
  right: 0;
  border: 0px;
  background-color: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}

.search-close {
  position: absolute;
  right: 9px;
  top: 5px;
  cursor: pointer;
}

/* Mega MENU */
.yamm-content {
  padding: 25px;
  box-sizing: border-box;
  background-color: #fff;
}

.dropdown-menu .divider {
  background-color: #222;
}

.yamm-content h3.heading {
  border-bottom: none;
  margin: 0 0 5px;
  color: #000;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
}

.yamm-content h3:before {
  content: "";
  display: none;
}

.yamm-content .mega-vertical-nav {
  margin-bottom: 30px;
}

.yamm-content .mega-vertical-nav li a {
  padding: 8px 0px;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  color: #888;
  border-bottom: 1px solid #fbfbfb;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  /*    border-bottom: 0px;*/
}

.nav.mega-vertical-nav li a:hover {
  background-color: transparent;
  color: #32c5d2;
}

.nav.mega-vertical-nav li a i {
  margin-right: 10px;
}

.mega-contact p {
  margin: 0;
}

.mega-contact i {
  color: #32c5d2;
  margin-right: 5px;
}

/*navbar search form***/
.dropdown-form {
  padding: 10px 27px;
  min-width: 213px;
}

.dropdown-form .form-control {
  height: 34px;
}

/***header top bar with search***/
.top-bar form {
  position: relative;
  margin: 0px;
  padding: 0px;
}

.top-bar button {
  border: 0px;
  background-color: transparent;
  position: absolute;
  top: 9px;
  right: 13px;
  margin: 0px;
  padding: 0px;
  width: auto;
  height: auto;
  line-height: 15px;
}

.top-bar form .form-control {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  margin-top: 6px;
  width: 150px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.top-bar .form-group {
  margin: 0px;
}

.top-bar .form-control:focus {
  width: 200px;
}

/****************************dark heder********************/
/***********bootstrap navigation default overrides--*/
.navbar-inverse {
  border: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  margin-bottom: 0;
  width: 100%;
  min-height: 70px;
  padding: 10px 0;
  -webkit-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #333;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
  color: #32c5d2;
  background-color: transparent;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color: #32c5d2;
  background-color: transparent;
}

.navbar-inverse .navbar-brand {
  font-weight: 700;
  color: #fff !important;
  font-size: 30px;
  line-height: 20px;
  font-style: normal;
  text-transform: uppercase;
}

.navbar-inverse .navbar-nav > li > a {
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
}

.navbar-inverse .navbar-nav > li > a:hover {
  color: #32c5d2;
}

#header-top.dark-header-top {
  background-color: #111;
  border-bottom-color: #222;
}

#header-top.dark-header-top .top-bar a i {
  color: #fff;
}

#header-top.dark-header-top .top-bar ul li {
  color: #fff;
}

/******************transparent header*****************************/
.navbar-inverse.transparent-header {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  padding: 10px 0px;
  min-height: 50px;
}

@media (max-width: 767px) {
  .navbar-inverse.transparent-header {
    background-color: #111 !important;
  }
}
/****************header center*****************/
.header-center {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f5f5;
  padding: 25px 0;
}

.header-center a {
  font-size: 30px;
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
}

.header-center span {
  display: block;
}

.navbar-default.menu-header-center {
  padding: 0px;
  min-height: 50px;
}

.navbar-default.menu-header-center .navbar-nav > li {
  border-left: 1px solid #ddd;
}

/***home boxed***/
#boxed {
  background-color: #f8f8f8;
}

@media (min-width: 1200px) {
  .boxed-wrapper {
    margin: 0  auto;
    width: 1170px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    -ms-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
  }
}
.boxed-wrapper .tp-banner-container {
  margin: 0px auto;
}

.boxed-wrapper .navbar-default {
  left: auto;
  width: auto;
  right: auto;
}

/***side panel*****/
.side-panel-page {
  overflow-x: hidden;
}

.side-panel {
  padding: 15px 0;
  background-color: #fff;
}
.side-panel .offcanvas-toggle-right.navbar-toggle {
  display: block;
  padding: 0px;
  margin: 0px;
  font-size: 18px;
}

.offcanvas-side-content .logo-side-nav {
  padding: 25px 15px;
  text-align: center;
}
.offcanvas-side-content .navmenu {
  width: 220px;
  padding: 0;
}
.offcanvas-side-content li.nav-header {
  padding: 15px;
}
.offcanvas-side-content .profile-element img {
  display: block;
  margin: 0 auto;
}
.offcanvas-side-content .profile-element .dropdown-menu {
  border: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  padding: 0px;
}
.offcanvas-side-content .profile-element .dropdown-menu > li:last-child a {
  border-bottom: 0px;
}
.offcanvas-side-content .profile-element .dropdown-menu > li > a {
  padding: 8px 15px;
  border-bottom: 1px solid #f5f5f5;
}
.offcanvas-side-content .profile-element .dropdown-menu > li > a i {
  margin-right: 5px;
}

.metismenu > li > a {
  border-bottom: 1px solid #eee;
  padding: 7px 15px;
}
.metismenu .arrow {
  float: right;
  position: absolute;
  right: 10px;
  top: 14px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.metismenu .arrow:before {
  content: "\f196";
}
.metismenu .active > a > .arrow:before {
  content: "\f147";
}
.metismenu .nav-second-level > li a {
  padding: 7px 15px;
  padding-left: 45px;
}
.metismenu .nav-third-level > li a {
  padding: 7px 15px;
  padding-left: 55px;
}

@media (max-width: 991px) {
  .navbar-default .nav > li > a {
    padding: 10px 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
  }
  .navbar-default .nav > li > a i {
    margin-right: 3px;
  }

  .navbar-inverse .nav > li > a {
    padding: 10px 11px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
  }
  .navbar-inverse .nav > li > a i {
    margin-right: 3px;
  }

  .yamm-content .mega-vertical-nav li a {
    font-size: 14px;
  }
}
/************************************************* css for footers****************************************/
/**footet dark**/
#footer {
  background: #111;
  font-size: 0.9em;
  padding: 20px;
  position: relative;
  clear: both;
}

.footer-col h3 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  font-style: normal;
}

.footer-col h3:first-letter {
  border-bottom: 1px solid #bbb;
}

.footer-col p {
  color: #aeaeae;
  font-size: 13px;
}

.contact p {
  margin-bottom: 10px;
}

.contact strong {
  margin-right: 10px;
  font-weight: bold;
}

.contact strong i {
  margin-right: 5px;
}

.contact a {
  color: #777;
}

.contact a:hover {
  color: #32c5d2;
}

.f2-work li {
  margin: 1px;
  padding: 0px;
}

.f2-work li a {
  display: block;
}

.f2-work li a img {
  width: 80px;
  border: 2px solid transparent;
  -moz-transition: 0.7s ease-in-out;
  -o-transition: 0.7s ease-in-out;
  -webkit-transition: 0.7s ease-in-out;
  transition: 0.7s ease-in-out;
}

.f2-work li a:hover img {
  border-color: #32c5d2;
}

.footer-btm {
  margin-top: 30px;
  padding: 20px;
  background-color: #0c0c0c;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.news-form .form-control {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  height: 30px;
}

@media (max-width: 767px) {
  .news-form {
    text-align: center !important;
  }

  .news-form .form-control {
    margin-bottom: 10px;
  }
}
.footer-social li {
  padding: 0px;
}

/****************footer 2****************/
#footer-option {
  background: #121214;
  border-top: 4px solid #e1e1e1;
  font-size: 0.9em;
  padding: 80px 0 0;
  position: relative;
  clear: both;
}

#footer-option .footer-col h3 {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}

#footer-option .footer-btm {
  background-color: #0c0c0c;
}

#footer-option .contact a {
  color: #777;
}

#footer-option .contact a:hover {
  color: #32c5d2;
}

/*******************footer 3********************/
.footer-col.footer-3 h3 {
  text-transform: uppercase;
  font-size: 35px;
}

/************twitter feed*****************/
.tweet ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.tweet ul li {
  color: #777;
  font-weight: 600;
  padding: 8px 0;
  font-size: 15px;
}

.tweet ul li:before {
  content: "\f099";
  font-family: 'FontAwesome';
  margin-right: 15px;
  font-size: 16px;
  color: #32c5d2;
}

.tweet ul li .date {
  padding: 3px 0;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

.tweet ul li img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin-right: 10px;
}

.tweet li a {
  color: #fff;
  font-size: 13px;
}

.tweet li a:hover {
  color: #32c5d2;
}

.latest-f-news li a {
  display: block;
  padding: 4px 0;
  color: #777;
}

.latest-f-news li a i {
  -moz-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.latest-f-news li a:hover i {
  margin-left: 5px;
}

.latest-f-news li a:hover {
  color: #fff;
}

/**************************

Footer light styles

******************************/
.footer-light-1 {
  padding-top: 60px;
  background-color: #f4f4f4;
}
.footer-light-1 h3 {
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 18px;
  font-size: 20px;
}
.footer-light-1 .menu-1 li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 3px 0;
}
.footer-light-1 .menu-1 li a {
  font-size: 14px;
  text-transform: uppercase;
}
.footer-light-1 .media img {
  margin-top: 5px;
}
.footer-light-1 .media .media-body p {
  line-height: 20px;
  font-size: 13px;
  margin-bottom: 0px;
}
.footer-light-1 .media .media-heading {
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 0px;
  font-weight: 600;
}
.footer-light-1 .f2-work li a img {
  height: 70px;
}
.footer-light-1 .info li {
  padding-bottom: 10px;
}
.footer-light-1 .info li i {
  color: #32c5d2;
  margin-right: 10px;
}
.footer-light-1 .subscribe-form .form-control {
  height: 49px;
}

.download-app li {
  padding: 0px;
}
.download-app li a {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  display: block;
  font-size: 35px;
}

.footer-copyright {
  font-size: 13px;
  color: #999;
  padding: 20px 15px;
  background-color: #ddd;
}

/****footer light option 2*****/
.footer-light-2 {
  padding-top: 60px;
  padding-bottom: 0px;
  background-color: #f5f5f5;
}
.footer-light-2 h3 {
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  margin-bottom: 23px;
  font-size: 18px;
}
.footer-light-2 p {
  color: #777;
}
.footer-light-2 .footer-work {
  clear: both;
}
.footer-light-2 .footer-work a {
  display: block;
  width: 80px;
  margin: 0px;
  float: left;
  margin: 1px;
}
.footer-light-2 .find-us p {
  margin-top: 0px;
  font-weight: 600;
  margin-bottom: 10px;
}
.footer-light-2 .footer-news li a {
  display: block;
  margin-bottom: 10px;
}
.footer-light-2 .footer-news li a:before {
  content: "\f105";
  font-family: "FontAwesome";
  margin-right: 10px;
}
.footer-light-2 .footer-bottom {
  padding-top: 30px;
  background-color: #eee;
}
.footer-light-2 .footer-bottom h3 {
  margin-bottom: 30px;
}
.footer-light-2 .footer-bottom ul {
  margin-bottom: 30px;
  padding: 0px;
}
.footer-light-2 .footer-bottom p {
  font-size: 12px;
  font-weight: 400;
  margin-top: 0px;
  text-transform: uppercase;
}

/****footer light option 3*****/
.footer-light-3 {
  padding-top: 60px;
  padding-bottom: 0px;
  background-color: #f5f5f5;
}
.footer-light-3 h3 {
  text-transform: uppercase;
  font-weight: 700;
  color: #666;
  margin-bottom: 20px;
}
.footer-light-3 p {
  color: #777;
}
.footer-light-3 .find-us p {
  margin-top: 0px;
  font-weight: 400;
  margin-bottom: 10px;
}
.footer-light-3 .find-us p strong {
  font-weight: 700;
  display: inline-block;
  margin-right: 5px;
}
.footer-light-3 .quick-links {
  padding-right: 40px;
}
.footer-light-3 .quick-links li {
  padding: 6px 0;
}
.footer-light-3 .quick-links li a {
  font-size: 14px;
  text-transform: capitalize;
}
.footer-light-3 .form-control {
  min-height: 45px;
  color: #999;
  text-transform: uppercase;
  font-size: 16px;
}

.footer-bottom {
  padding-top: 30px;
  background-color: #eee;
}
.footer-bottom h3 {
  margin-bottom: 30px;
}
.footer-bottom ul {
  margin-bottom: 30px;
  padding: 0px;
}
.footer-bottom p {
  font-size: 12px;
  font-weight: 400;
  margin-top: 0px;
  text-transform: capitalize;
}

.wide-img-showcase-row {
  position: relative;
}

.no-padding.img {
  /* background: url(../img/showcase-1.jpg) scroll center no-repeat; */
  background-size: cover;
  position: absolute;
  height: 100%;
}

.img-analyse {
  background: url(../img/analyse.jpg) scroll center no-repeat;
  background-size: cover;
  min-height: 300px;
}

@media (max-width: 767px) {
  .no-padding.img {
    position: relative;
    height: 300px;
  }
}
/************************image hover effect*******************/
.item-img-wrap {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.item-img-wrap img {
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 100%;
}

.item-img-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.item-img-overlay span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background: url(../img/plus.png) no-repeat center center rgba(0, 0, 0, 0.7); */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -moz-transition: opacity 250ms linear;
  -o-transition: opacity 250ms linear;
  -webkit-transition: opacity 250ms linear;
  transition: opacity 250ms linear;
}

.item-img-wrap:hover .item-img-overlay span {
  opacity: 1;
}

.item-img-wrap:hover img {
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*forms*/
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.form-control {
  border: 1px solid #ddd;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  font-size: 12px;
  color: #7a7a7a;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  background: #FFF;
  -moz-transition: all 200ms ease-in;
  -o-transition: all 200ms ease-in;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.form-control:focus {
  border-color: #bbb;
  background: #FFF;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

/*************parallax*************/
.parallax {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
}

/************

Carousel slider

***************/
.carousel-slider-wrapper {
  position: relative;
}

.carousel-slider-wrapper .item {
  overflow: hidden;
  width: 100%;
  height: 600px;
  background-color: #333 !important;
  padding: 100px 0 0;
}

.carousel-slider-wrapper .carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

#carousel-slide-1 {
  /* background-image: url(../img/bg-1.jpg); */
  width: 100%;
  background-size: cover;
}

#carousel-slide-2 {
  /* background-image: url(../img/bg-2.jpg); */
  width: 100%;
  background-size: cover;
}

#carousel-slide-3 {
  /* background-image: url(../img/bg-3.jpg); */
  width: 100%;
  background-size: cover;
}

.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: table;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.carousel-item-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: table-cell;
  overflow: hidden;
  vertical-align: middle;
  z-index: 3;
}

.carousel-item-content h1 {
  display: inline-block;
  padding: 10px 20px;
  line-height: 35px;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  background-color: #32c5d2;
  margin: 0px;
}

.carousel-item-content p {
  margin: 0px;
  padding: 6px 20px;
  color: #fff;
  background: #333;
  display: inline-block;
  text-transform: uppercase;
}

a.carousel-control {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  display: block;
  top: 50%;
  margin-top: -25px;
  background-image: none !important;
  font-size: 52px;
  font-weight: 400;
  cursor: pointer;
  z-index: 2;
}

/*flex slider*/
.slider-reg {
  width: 100%;
}

@media (max-width: 767px) {
  .slider-reg.transparent-header-slider {
    margin-top: 50px;
  }
}
.main-flex-slider {
  position: relative;
}

.main-flex-slider .flex-control-nav {
  bottom: 10px;
  margin: 0;
  height: 14px;
  z-index: 10;
}

.main-flex-slider .flex-control-paging li a {
  background-color: rgba(255, 255, 255, 0.7) !important;
  width: 26px;
  height: 8px;
  display: block;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

.main-flex-slider .flex-control-paging li a.flex-active {
  background-color: #32c5d2 !important;
}

.slider-overlay {
  background: rgba(0, 0, 0, 0.2);
  /* background-image: url("../img/pattern.png"); */
  background-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.slider-text {
  text-align: center;
  margin-top: 30px;
}

.slider-text h1 {
  font-size: 3.75em;
  color: #FFF;
  padding: 5px 10px 0px;
  line-height: 1.4;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-align: center;
}

.slider-text p {
  color: #fff;
  font-size: 20px;
  margin-bottom: 25px;
  font-weight: 400;
}

.slider-text p a {
  font-style: normal;
}

@media (min-width: 1200px) {
  .slider-text {
    margin-top: 250px;
  }
}
@media (max-width: 1199px) {
  .boxed-wrapper .navbar-default {
    left: 0;
    width: 100%;
    right: 0;
  }
}
@media (max-width: 1024px) {
  .slider-text {
    margin-top: 150px;
  }
}
@media (max-width: 768px) {
  .slider-text {
    margin-top: 90px;
  }

  .slider-text h1 {
    font-size: 2em;
  }
}
@media (max-width: 667px) {
  .slider-text {
    margin-top: 30px;
  }

  .slider-text h1 {
    font-size: 2em;
  }
}
@media (max-width: 533px) {
  .slider-text p a {
    display: none;
  }
}
@media (max-width: 414px) {
  .slider-text p {
    display: none;
  }

  .slider-text h1 {
    font-size: 1.5em;
    margin-top: 45px;
  }
}
@media (max-width: 384px) {
  .slider-text h1 {
    font-size: 1.5em;
    margin-top: 45px;
  }
}
/***********animation delays*****************/
.delay-1 {
  -webkit-animation-delay: 300ms;
  -moz-animation-delay: 300ms;
  animation-delay: 300ms;
}

.delay-2 {
  -webkit-animation-delay: 600ms;
  -moz-animation-delay: 600ms;
  animation-delay: 600ms;
}

.delay-3 {
  -webkit-animation-delay: 1200ms;
  -moz-animation-delay: 1200ms;
  animation-delay: 1200ms;
}

.delay-4 {
  -webkit-animation-delay: 1600ms;
  -moz-animation-delay: 1600ms;
  animation-delay: 1600ms;
}

/*intro text*/
.intro-text {
  padding: 60px 0;
  background-color: #eee;
}

.intro-text h2 {
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 25px;
}

.intro-text a {
  margin: 7px;
}

/*******************home parallax-bg----***********************/
#home-parallax {
  /* background: url("../img/showcase-4.jpg") no-repeat; */
  padding: 190px 0;
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
  color: #32c5d2;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.parallax-buttons {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.parallax-buttons a {
  display: inline-block;
  margin: 5px;
}

h1.typed-text {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 700;
}

.typed-text .element {
  color: #32c5d2;
}

#home-parallax p {
  color: #fff;
}

@media (max-width: 568px) {
  h1.typed-text {
    font-size: 25px;
  }
}
/*services*/
.services-wrap {
  padding-top: 60px;
  padding-bottom: 40px;
  background-color: #fff;
}

.services-box {
  clear: both;
}

.services-box-icon {
  background: transparent;
  width: 50px;
  height: 50px;
  display: inline-block;
  float: left;
  height: 35px;
  line-height: 35px;
  margin-right: 10px;
  position: relative;
  text-align: center;
  top: 5px;
}

.services-box-icon i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #32c5d2;
  color: #fff;
  font-size: 17px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.services-box-info {
  padding-left: 65px;
}

.services-box-info h4 {
  font-weight: 700;
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 5px;
}

/*services 2*/
.service-box i {
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  color: #32c5d2;
  font-size: 30px;
  background-color: #f7f9fa;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin-bottom: 25px;
  -moz-transition: 0.7s ease-in-out;
  -o-transition: 0.7s ease-in-out;
  -webkit-transition: 0.7s ease-in-out;
  transition: 0.7s ease-in-out;
}

.service-box h3 {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 600;
}

.service-box:hover i {
  -moz-transform: rotateY(360deg);
  -o-transform: rotateY(360deg);
  -ms-transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
  color: #fff;
  background-color: #32c5d2;
}

/***services3***/
#services-bg-wrap {
  padding: 90px 0;
  background-color: #f7f9fa;
}

.service-3-meta h1 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 25px;
}

.white-bg {
  background-color: #fff !important;
  padding-top: 90px !important;
  padding-bottom: 50px !important;
}

.service-4-meta h3 {
  font-weight: 700;
  margin-bottom: 5px;
}

.blue-bg {
  background-color: #32c5d2;
  padding-top: 70px;
  padding-bottom: 40px;
}

.blue-bg .services-box i {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  font-size: 50px;
  margin-right: 25px;
}

.blue-bg .services-box .services-box-info {
  margin-left: 30px;
}

.blue-bg .services-box .services-box-info h4 {
  color: #fff;
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 20px;
}

.blue-bg .services-box .services-box-info p {
  color: #eee;
}

/***special features***/
.special-feature .section-title hr {
  border-color: #151515;
}

.special-feature i {
  font-size: 65px;
  line-height: 75px;
  color: #32c5d2;
}

.special-feature h4 {
  font-size: 16px;
  margin-top: 25px;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  color: #777;
}

.special-feature .mask-top {
  width: 100%;
  background-color: #ffffff;
  padding: 55px 0;
  position: absolute;
  top: 0px;
  -moz-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.special-feature .mask-top h4:before {
  top: -18px;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  right: 0;
  margin: -2px auto;
  position: absolute;
  width: 40px;
}

.special-feature .s-feature-box:hover .mask-top {
  top: -200px;
  -moz-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.special-feature .s-feature-box {
  overflow: hidden;
  position: relative;
  height: 280px;
  border: 1px solid #f2f2f2;
  margin-bottom: 20px;
  cursor: pointer;
}

.special-feature .mask-bottom {
  color: #333333;
  padding: 20px 15px 20px 15px;
  width: 100%;
  position: absolute;
  bottom: -300px;
  height: 100%;
  -moz-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.special-feature .mask-bottom p {
  padding: 0px 5px;
  font-size: 14px;
}

.special-feature .mask-bottom h4 {
  margin: 15px 0px 17px;
}

.special-feature .s-feature-box:hover .mask-bottom {
  bottom: 0;
  -moz-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.special-feature .mask-bottom i {
  font-size: 38px;
  line-height: 50px;
}

/****services new*****/
.service-box-4 .service-ico {
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  font-size: 30px;
  line-height: 30px;
  margin-top: 3px;
  float: left;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.service-ico i {
  color: #32c5d2;
  font-size: 50px;
}

.service-box-4 .service-text {
  margin-left: 65px;
}

.service-text a {
  color: #32c5d2;
}

.service-text a i {
  margin-left: 5px;
  vertical-align: middle;
}

.service-text h4 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
}

/***colored boxed**************/
.colored-boxed {
  text-align: center;
}

.colored-boxed i {
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  border: 0px solid;
  background-color: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 50px;
  display: inline-block;
}

.colored-boxed.green i {
  color: #32c5d2;
  border-color: #32c5d2;
}

.colored-boxed.dark i {
  color: #333;
  border-color: #333;
}

.colored-boxed.blue i {
  color: #3b5998;
  border-color: #3b5998;
}

.colored-boxed.red i {
  color: #cb2027;
  border-color: #cb2027;
}

.colored-boxed h3 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.colored-boxed p {
  margin-top: 10px;
}

/**************work and portfolio whole*******/
/* mouse grab icon */
.grabbing {
  /* cursor: url(../img/grabbing.png) 8 8, move !important; */
}

.portfolio {
  padding: 60px 0;
  background-color: #f8f8f8;
}

.work-desc {
  width: 100%;
  padding: 10px 10px;
  background: #FFF;
  border-top: none;
  position: relative;
}

.work-desc:before {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  margin-left: 20px;
  width: 8px;
  height: 8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  z-index: 100;
}

.work-desc h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.owl-spaced .owl-item > div {
  margin: 5px;
}

.work-desc span {
  font-weight: 400;
  font-style: normal;
  text-transform: capitalize;
  font-size: 14px;
  color: #999;
}

.project-detail h3 {
  margin-bottom: 10px;
}

.project-detail em {
  display: block;
}

.full-width-3-work .work-desc {
  padding: 10px;
}

/****progress bars****/
/*progress bar*/
h3.heading-progress {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 6px;
  margin-top: 0px;
  text-transform: uppercase;
  color: #777;
}

.progress {
  height: 15px;
  line-height: 10px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  background: #E9E9E9;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.progress-bar {
  line-height: 10px;
  background: #32c5d2;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

/*news and blogs*/
.news-desc {
  padding: 15px 0;
}

.news-desc h4 {
  margin: 0px;
  font-weight: 600;
}

.news-desc span {
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
}

.news-desc span + span {
  padding-left: 30px;
}

/*latest news in home option*/
.latest-new {
  border: 1px solid #ddd;
}

.blog-date {
  width: 60px;
  height: 60px;
  padding: 6px 5px;
  margin: -40px auto 10px auto;
  line-height: 1;
  font-weight: 400;
  color: #000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  font-size: 36px;
  text-align: center;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.blog-date span {
  font-weight: 400;
  font-size: 15px;
  display: block;
}

.l-news-desc {
  padding: 10px 20px;
}

.l-news-desc h3 {
  margin-bottom: 15px;
}

ul.post-desc {
  padding: 0;
}

ul.post-desc li {
  font-size: 14px;
  text-transform: capitalize;
  color: #555;
}

ul.post-desc li + li {
  padding-left: 15px;
}

/**blog inner pages**/
.blog-post {
  border-bottom: 1px dashed #e5e5e5;
  padding-bottom: 30px;
  margin-bottom: 60px;
}

.post-detail {
  margin-top: 25px;
  margin-bottom: 0px;
  border-top: 1px solid #ebeaea;
  border-bottom: 1px solid #ebeaea;
  margin-left: 0px;
}

.post-detail li img {
  width: 26px;
  height: 26px;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  border-radius: 13px;
  float: left;
  margin-right: 10px;
}

.post-detail li + li {
  padding-left: 15px;
}

.post-detail li {
  font-size: 12px;
  vertical-align: middle;
  text-transform: capitalize;
}

.post-detail li i {
  margin-right: 5px;
}

.blog-post h2 {
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 15px;
  font-weight: 600;
  text-transform: capitalize;
}

.blog-left-img .post-detail {
  margin-top: 0px;
}

.about-author {
  background-color: #f5f5f5;
  padding: 30px;
  margin-bottom: 50px;
}

.about-author img {
  width: 120px;
  height: 120px;
  float: left;
  margin-right: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.about-author p {
  overflow: hidden;
}

.comment-list {
  padding-bottom: 5px;
  margin-bottom: 30px;
}

.comment-post h4 img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  float: left;
}

.comment-post p {
  overflow: hidden;
}

.comment-form .form-contact .form-control {
  background-color: #f5f5f5;
}

/*Timeline blog
------------------------------------*/
.timeline {
  padding: 20px 0;
  list-style: none;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #f5f5f5;
  left: 50%;
  margin-left: -1.5px;
}

.timeline > li {
  margin-bottom: 40px;
  position: relative;
  width: 50%;
  float: left;
  clear: left;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 94%;
  float: left;
  border: 1px solid #d4d4d4;
  /*border-radius: 2px;*/
  /*padding: 20px;*/
  position: relative;
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}

.timeline > li > .timeline-badge {
  color: #ccc;
  width: 24px;
  height: 24px;
  line-height: 50px;
  font-size: 1.4em;
  cursor: pointer;
  text-align: center;
  position: absolute;
  top: 19px;
  right: -12px;
  z-index: 9;
}

.timeline > li > .timeline-badge i:hover {
  color: #32c5d2;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline > li > .timeline-panel .timeline-heading {
  padding: 5px;
}

.timeline > li > .timeline-panel .timeline-heading img {
  width: 100%;
}

.timeline .timeline-body {
  padding: 25px 15px;
}

.timeline .timeline-body h2 {
  font-size: 20px;
  margin-bottom: 5px;
}

.timeline-body .post-detail {
  margin: 5px 0px;
}

.timeline > li.timeline-inverted {
  float: right;
  clear: right;
  margin-bottom: 40px;
}

.timeline > li:nth-child(2) {
  margin-top: 60px;
}

.timeline > li.timeline-inverted > .timeline-badge {
  left: -12px;
}

@media (max-width: 992px) {
  .timeline > li > .timeline-panel:before {
    top: 31px;
    right: -11px;
    border-top: 11px solid transparent;
    border-left: 11px solid #ccc;
    border-bottom: 11px solid transparent;
  }

  .timeline > li > .timeline-panel:after {
    top: 32px;
    right: -10px;
    border-top: 10px solid transparent;
    border-left: 10px solid #fff;
    border-bottom: 10px solid transparent;
  }

  .timeline > li.timeline-inverted > .timeline-panel:before {
    border-right-width: 11px;
    left: -11px;
  }

  .timeline > li.timeline-inverted > .timeline-panel:after {
    border-right-width: 10px;
    left: -10px;
  }
}
@media (max-width: 767px) {
  ul.timeline:before {
    left: 40px;
  }

  ul.timeline > li {
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    float: left;
    clear: left;
  }

  ul.timeline > li > .timeline-panel {
    width: calc(100% - 70px);
    width: -moz-calc(100% - 70px);
    width: -webkit-calc(100% - 70px);
  }

  ul.timeline > li > .timeline-badge {
    left: 28px;
    margin-left: 0;
    top: 16px;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    top: 27px;
    right: -12px;
    border-top: 12px solid transparent;
    border-left: 12px solid #ccc;
    border-bottom: 12px solid transparent;
  }

  ul.timeline > li > .timeline-panel:after {
    top: 28px;
    right: -11px;
    border-top: 11px solid transparent;
    border-left: 11px solid #fff;
    border-bottom: 11px solid transparent;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 12px;
    left: -12px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 11px;
    left: -11px;
    right: auto;
  }

  .timeline > li.timeline-inverted {
    float: left;
    clear: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .timeline > li.timeline-inverted > .timeline-badge {
    left: 28px;
  }
}
/*testimonials parallax*/
.testimonials {
  /* background: url(../img/bg-4.jpg) no-repeat; */
  padding: 80px 0;
}

.testimonials .center-heading h2 {
  color: #fff;
}

.testimonials h4 {
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  font-style: normal;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.testimonials h4 i {
  color: #32c5d2;
  margin-right: 5px;
}

.testimonials p {
  color: #32c5d2;
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
}

.customer-img {
  max-width: 92px;
  margin: auto;
  margin-bottom: 25px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

/**owl**/
.owl-theme .owl-controls .owl-page span {
  display: block;
  width: 8px !important;
  height: 8px !important;
  margin: 5px 7px;
  filter: Alpha(Opacity=50);
  opacity: 0.5;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: #444 !important;
}

/*********************testmonials v-2*****************/
.testimonials-v-2 {
  padding: 100px 0;
  overflow: hidden;
}

.testi-slide {
  text-align: center;
}

.testi-slide img {
  width: 92px;
  height: 92px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.testi-slide p {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  font-style: italic;
}

.testi-slide i {
  color: #32c5d2;
  margin-right: 10px;
}

.testi-slide h4 {
  font-weight: 400;
  font-size: 16px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
  font-style: italic;
}

.testi-slide .flex-control-paging li a {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  background: transparent !important;
  border: 2px solid #ccc;
  width: 8px;
  height: 8px;
}

.testi-slide .flex-control-paging li a.flex-active {
  background: transparent !important;
  border: 2px solid #32c5d2;
}

.quote {
  position: relative;
}

.quote blockquote {
  padding: 0px;
  border: 0;
  margin: 0;
  font-size: 14px;
  font-style: italic;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}

.quote blockquote p {
  color: #fff;
  padding-top: 25px;
  padding-bottom: 45px;
  padding-left: 30px;
  padding-right: 30px;
}

.quote blockquote:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 90px;
  width: 0;
  height: 0;
  border-top: 0.83333em solid #ccc;
  border-right: 0.86667em solid transparent;
}

.quote.green blockquote {
  background-color: #32c5d2;
}

.quote.green blockquote:before {
  border-top-color: #32c5d2;
}

.quote.dark blockquote {
  background-color: #555;
}

.quote.dark blockquote:before {
  border-top-color: #555;
}

.quote-footer {
  margin: 10px 0;
}

.quote-footer .quote-author-img img {
  float: left;
  max-width: 90px;
  width: 90px;
  height: 90px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin-left: -5px;
  margin-top: -40px;
  position: relative;
  z-index: 1;
  padding: 5px;
  background-color: #fff;
}

.quote-footer h4 {
  font-size: 14px;
  margin-bottom: 4px;
}

.quote-footer p {
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
}

/***********bootstrap collapse overrides*/
.panel-group .panel {
  margin-bottom: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}

.panel-default > .panel-heading {
  color: #000;
  background-color: #fff;
  border-color: #f5f5f5 !important;
  padding: 7px 12px;
}

.panel-title i {
  margin-right: 10px;
  color: #32c5d2;
}

/*************soical icons**********/
.social-1 {
  margin: 0;
  padding: 0;
}

.social-1 li {
  padding: 0px;
}

.social-1 li a i {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  -moz-transition: all 200ms ease-in;
  -o-transition: all 200ms ease-in;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.social-1 li a i:hover {
  opacity: 0.8;
}

.social-1 li a i.fa-facebook {
  background-color: #3b5998;
}

.social-1 li a i.fa-twitter {
  background-color: #0084b4;
}

.social-1 li a i.fa-google-plus {
  background-color: #C63D2D;
}

.social-1 li a i.fa-pinterest {
  background-color: #cb2027;
}

.social-1 li a i.fa-dribbble {
  background-color: #ea4c89;
}

/**************countdown*************/
.countdown_section {
  display: inline-block;
  width: 120px;
  height: 120px;
  padding-top: 13px;
  margin: 10px;
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.subscribe-form .form-control {
  height: 46px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

/*purchase section*/
.purchase-sec {
  padding: 30px 0;
  background: #32c5d2;
}

.purchase-sec span {
  line-height: 40px;
  color: #fff;
  font-size: 25px;
  font-weight: 400;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

/****fun facts*****/
.fun-fact-wrap {
  /* background: url(../img/showcase-4.jpg); */
  background-repeat: repeat !important;
  padding-top: 100px;
  padding-bottom: 70px;
}

.facts-in {
  text-align: center;
}

.facts-in h3 {
  margin-bottom: 25px;
  font-size: 50px;
  font-weight: 400;
  color: #fff;
}

.facts-in h3 i {
  font-size: 40px;
  margin-right: 20px;
  color: #32c5d2;
}

.facts-in h4 {
  margin-bottom: 0px;
  color: #fff;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-transform: capitalize;
}

@media (max-width: 992px) {
  .facts-in {
    margin-bottom: 70px;
  }
}
/******owl carousel*******/
.owl-theme .owl-controls .owl-page span {
  display: block;
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  filter: Alpha(Opacity=50);
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  background: #32c5d2 !important;
}

/**********static background with transparent header*/
.static-bg {
  /* background: url(../img/showcase-3.jpg); */
  padding-top: 230px;
  padding-bottom: 200px;
}

.static-wrap {
  text-align: center;
}

.static-wrap h3 {
  text-transform: uppercase;
  letter-spacing: 0px;
  padding: 0 0 16px 0;
  margin: 0 0 35px 0;
  font-weight: 400;
  font-size: 44px;
  border-bottom: solid 1px #cccccc;
  position: relative;
  color: #fff;
  line-height: 44px;
}

.static-bg h3:before {
  content: "";
  height: 3px;
  background: #009ada;
  background: -moz-linear-gradient(45deg, #009ada 0%, #04bfbf 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #009ada), color-stop(100%, #04bfbf));
  background: -webkit-linear-gradient(45deg, #009ada 0%, #04bfbf 100%);
  background: -o-linear-gradient(45deg, #009ada 0%, #04bfbf 100%);
  background: -ms-linear-gradient(45deg, #009ada 0%, #04bfbf 100%);
  background: linear-gradient(45deg, #009ada 0%, #04bfbf 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009ada', endColorstr='#04bfbf',GradientType=1 );
  width: 60%;
  max-width: 100%;
  display: block;
  left: 0%;
  bottom: -2px;
  position: absolute;
}

.static-bg h3:before {
  left: 20%;
}

.slider-flex h1 {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

/****************************breadcrumb***************************/
.breadcrumb-wrap {
  padding: 30px 0;
  /* background-image: url(../img/shadow-bg.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #f6f6f6;
  border-top: 1px solid #d2d3d4;
  border-bottom: 1px solid #d2d3d4;
}

.breadcrumb-wrap h4 {
  margin: 0px;
  font-size: 16px;
  color: #333;
  font-weight: 700;
  letter-spacing: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

ol.breadcrumb {
  margin: 0;
  padding: 0;
  background-color: transparent;
  color: #333;
}

ol.breadcrumb li {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 1px;
}

ol.breadcrumb li a {
  color: #333;
}

/***list of highlight**/
.highlight-list li {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #777;
  line-height: 45px;
}

.highlight-list li i {
  color: #32c5d2;
  margin-right: 10px;
}

/************team section css here*****/
.team-wrap {
  padding: 3px 3px 20px;
  border: 1px solid #eee;
  text-align: center;
}
.team-wrap li {
  padding: 0px;
}

.team-wrap h4 {
  margin-bottom: 3px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 17px;
}

.team-wrap span {
  font-size: 10px;
  text-transform: uppercase;
  color: #777;
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
}

/**team-v2**/
.person-v2 {
  margin-bottom: 30px;
}

.our-team-v-2 {
  padding-top: 70px;
  background-color: #f5f5f5;
  padding-bottom: 40px;
}

.person-v2 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.person-v2 h3 {
  margin: 5px 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 24px;
}

.person-desc-v2 em {
  display: block;
  color: #777;
  margin-bottom: 10px;
}

/******************pricing tables***********************/
/**pricing simple **/
.pricing-simple {
  border: 1px solid #D0D6DF;
  margin-bottom: 30px;
}
.pricing-simple h4 {
  border-bottom: 1px solid #D0D6DF;
  margin: 0px;
  color: #666;
  padding: 20px;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
}
.pricing-simple h3 {
  margin: 0px;
  padding: 30px 20px;
  border-bottom: 1px solid #D0D6DF;
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  background-color: #f4f4f4;
}
.pricing-simple h3 sup, .pricing-simple h3 sub {
  font-size: 14px;
  color: #D0D6DF;
  font-weight: 400;
}
.pricing-simple h3 sup {
  vertical-align: top;
  left: 10px;
}
.pricing-simple h3 sub {
  vertical-align: bottom;
}
.pricing-simple ul {
  padding-top: 30px;
}
.pricing-simple ul li {
  padding: 6px 20px;
  border-bottom: 1px solid #f5f5f5;
  color: #aeaeae;
}
.pricing-simple ul li:last-child {
  border-bottom: 0px;
}
.pricing-simple ul li i {
  color: #32c5d2;
  margin-right: 10px;
}
.pricing-simple .bottom {
  padding: 20px 10px;
  text-align: center;
}
.pricing-simple p {
  color: #aeaeae;
  padding: 15px 20px;
  text-align: center;
  padding-bottom: 0px;
}
.pricing-simple .circle {
  width: 150px;
  height: 150px;
  padding: 0px 0 0;
  margin: 30px auto;
  margin-bottom: 0px;
  display: table;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  border: 5px solid #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #f4f4f4;
}
.pricing-simple .circle .price {
  text-align: center;
  font-size: 30px;
  display: table-cell;
  vertical-align: middle;
}
.pricing-simple .circle .price sub, .pricing-simple .circle .price sup {
  font-size: 14px;
  color: #777;
}
.pricing-simple .circle .price sup {
  vertical-align: top;
}
.pricing-simple .circle .price sub {
  vertical-align: bottom;
}

.popular {
  position: relative;
  overflow: hidden;
}
.popular .ribbon {
  position: absolute;
  left: -55px;
  bottom: 5px;
  font-size: 11px;
  text-align: center;
  width: 150px;
  color: #fff;
  text-transform: capitalize;
  padding: 1px 12px;
  display: block;
  -webkit-box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: #32c5d2;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.no-space-pricing {
  margin: 0px;
}

/**dark pricing tables**/
.pricing-simple.dark-pricing {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #333;
}
.pricing-simple.dark-pricing h4 {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.pricing-simple.dark-pricing .circle {
  background-color: #444;
  border-color: #333;
}
.pricing-simple.dark-pricing .circle .price {
  color: #fff;
}
.pricing-simple.dark-pricing ul li {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

/***************about me*********************/
.me-intro {
  /* background: url(../img/bg-4.jpg); */
  padding: 200px 0;
}

.me-intro h2 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.me-intro p {
  color: #fff;
}

.me-hobbies h4 i {
  margin-right: 10px;
  color: #32c5d2;
  font-size: 25px;
}

.services-me li {
  padding: 10px 0;
  font-size: 16px;
}

.services-me li i {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 15px;
  background-color: #e5e5e5;
  color: #32c5d2;
}

.contact-me {
  background: #eee url('../img/mj-contact.jpg');
  color: #fff;
}

.contact-me h3, .contact-me h4, .contact-me p, .contact-me span, .contact-me h2 {
    color: #ddd;
}
.contact-me-grey {
    padding: 40px 0;
    background-color: rgba(0,0,0, 0.70);
}


/**dropcaps**/
p.dropcap:first-letter {
  display: block;
  float: left;
  font-size: 50px;
  line-height: 51px;
  margin: -1px 8px -2px 0;
  color: #32c5d2;
}

/*contact us*/
/*gmap*/
#map-canvas {
  width: 100%;
  height: 300px;
}

#contactForm h3
{
  color: #ffffff;
}

#contactForm .form-control {
  min-height: 45px;
  background-color: transparent;
  color: #ffffff;
}

.form-contact form label {
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
}

.contact-info li a {
  color: #ffffff;
}

.help-block ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.help-block ul li {
  color: red;
}

label span {
  color: red;
  margin-left: 5px;
}

.required span {
  color: red;
}

/***error page****/
.error-bg {
  background-color: #eee;
}

.error-404 {
  padding-top: 40px;
}
.error-404 h1 {
  text-transform: uppercase;
  color: #aaa;
  font-size: 160px;
  line-height: 160px;
  margin-bottom: 20px;
}
.error-404 h3 {
  color: #777;
  text-transform: uppercase;
  line-height: 40px;
}
.error-404 .form-control {
  font-size: 20px;
}

/********************************sidebar***********************/
.sidebar-box h4 {
  font-size: 16px;
  font-weight: 700;
  color: #444;
  text-transform: capitalize;
}

.search-widget {
  position: relative;
}

.search-widget i {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #777;
}

.cat-list li {
  padding: 5px 0;
  border-bottom: 1px solid #e5e5e5;
}

.cat-list li a {
  color: #888;
  margin-right: 10px;
}

.cat-list li i {
  color: #888;
  margin-right: 10px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cat-list li:hover i {
  margin-left: 5px;
}

.cat-list li a:hover {
  color: #32c5d2;
}

.popular-post li {
  padding-bottom: 20px;
}

.popular-img {
  float: left;
  margin-right: 10px;
}

.popular-img a img {
  width: 60px;
  height: 60px;
}

.popular-desc {
  display: table;
}

.popular-desc h5 {
  font-size: 17px;
  margin-bottom: 6px;
}

.popular-desc h5 a {
  color: #000;
  font-weight: 600;
  font-size: 14px;
}

.popular-desc h5 a:hover {
  color: #32c5d2;
}

.popular-desc h6 {
  color: #777;
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
}

.tag-list a {
  display: inline-block;
  text-transform: uppercase;
  margin-right: 0px;
  margin-bottom: 3px;
  padding: 3px 6px;
  color: #777;
  font-size: 14px !important;
  background-color: #e5e5e5;
}

.tag-list a:hover {
  background-color: #32c5d2;
  color: #fff;
}

/****************testimonials***********************/
.quote-text {
  background-color: #f6f6f6;
  border-left: none;
  color: rgba(85, 94, 106, 0.8);
  font-size: 14px;
  line-height: 1.6;
  margin-top: 30px;
  padding: 26px;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 0 #35bdf6;
  -moz-box-shadow: 0 3px 0 #35bdf6;
  -ms-box-shadow: 0 3px 0 #35bdf6;
  box-shadow: 0 3px 0 #35bdf6;
}

.quote-text:before {
  border: solid 14px transparent;
  z-index: 3;
  border-top-color: #f6f6f6;
  top: auto;
  right: auto;
  bottom: -28px;
  left: 30px;
}

.quote-text:after {
  border: solid 14px transparent;
  z-index: 2;
  border-width: 18px;
  border-top-color: #35bdf6;
  top: auto;
  right: auto;
  bottom: -36px;
  left: 26px;
}

.quote-text:before, .quote-text:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
}

.quote-pic {
  float: left;
  margin-right: 12px;
  margin-left: 28px;
  width: 32px;
  height: 32px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  border-radius: 16px;
}

.quote-author {
  display: inline-block;
  font-size: 14px !important;
  line-height: 1.3 !important;
}

.quote-author strong {
  font-size: 14px;
  font-weight: 700;
  display: block;
}

/***********************sign in or sign up css****************/
.login-form {
  padding: 20px;
  background-color: #f5f5f8;
}

.login-form h3 span {
  color: #777;
  font-size: 15px;
}

.btn-fb-login {
  background-color: #3b5998;
  color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.login-form form a {
  display: inline-block;
  margin-left: 5px;
}

@media (max-width: 320px) {
  .login-form form a {
    margin-top: 10px;
  }
}
/****clients***/
#clients-carousel {
  background-color: #424242;
  padding: 90px 0;
}

#clients-carousel.light {
  background-color: #222;
}

#clients-carousel.light h3.heading {
  color: #fff;
}

#clients-carousel .center-heading h2 {
  color: #fff;
}

#clients-carousel .item {
  text-align: center;
}

#clients-carousel p {
  color: #bbb;
}

#cta-1 {
  padding: 40px 0;
  background-color: #32c5d2;
  text-align: center;
}

#cta-1 h1 {
  color: #fff;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

#cta-2 {
  padding: 30px 0;
  background-color: #f2f2f2;
  text-align: center;
}

#cta-2 h1 {
  color: #333;
  margin-bottom: 15px;
  font-weight: 400;
}

/*****accordions****/
/*** Accordion ***/
.panel-group {
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
}

.panel-group .panel {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

.panel-group .panel + .panel {
  margin-top: -2px;
}

.panel-group .panel-heading {
  padding: 0;
  background: white;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

.panel-group .panel-body {
  font-size: 14px;
  background: #f9f9f9;
  -webkit-box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.03);
  -ms-box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.03);
  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.03);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.panel-group .panel-heading a {
  position: relative;
  display: block;
  padding: 8px 20px 8px 10px;
  font-size: 15px;
  color: #505050;
  line-height: 1.5;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: 400;
}

/***acordions icon*****/
.panel-ico {
  position: relative;
}

.panel-ico:after {
  content: '\f067';
  color: #888;
  display: block;
  font-family: 'FontAwesome';
  position: absolute;
  right: 15px;
  top: 8px;
}

.panel-ico.active:after {
  content: '\f068';
  color: #32c5d2;
}

.panel-ico.active a {
  color: #32c5d2;
}

/**primary tabs***/
.panel-primary {
  border: 0px;
}
.panel-primary .panel-heading {
  background: #32c5d2;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  border-color: rgba(0, 0, 0, 0.1);
}
.panel-primary .panel-heading + .panel-collapse > .panel-body {
  border-top: 0px;
}
.panel-primary .panel-ico.active a {
  color: #fff;
}
.panel-primary .panel-ico.active:after {
  color: #fff;
}
.panel-primary .panel-heading a {
  color: #eee;
}
.panel-primary .panel-ico:after {
  color: #eee;
}

.simple-collapse .panel-body {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  background-color: #fff;
}

.simple-collapse.panel-group {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

/****************elements******************/
.grid-wrap h4 {
  display: block;
  line-height: 35px;
  text-align: center;
  background-color: #f9f9fa;
  border: 1px solid #ddd;
}

.element-buttons a {
  margin-bottom: 10px;
  margin-right: 5px;
}

/*****************bootstrap tabs overrides*****************************/
/*tabs*/
.nav-tabs {
  border: 0px;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  border: 0px;
  background-color: #32c5d2;
  color: #fff;
}

.nav-tabs > li > a {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  background-color: #f4f4f4;
  border: 0px;
}

.nav-tabs > li > a:hover {
  border: 0px;
}

.tabs .nav-tabs {
  border-bottom: none;
}

.tabs .nav-tabs li {
  margin-bottom: 0;
}

.tab-content {
  overflow: hidden;
  padding: 15px 0;
}

/*****vertical left tabs--*****/
.tabs-left .nav-tabs {
  float: left;
  margin-right: 19px;
}
.tabs-left .nav-tabs > li {
  float: none;
  margin-bottom: 2px;
}
.tabs-left .tab-content {
  padding: 0px;
}

/*****vertical right tabs--*****/
.tabs-right .nav-tabs {
  float: right;
  margin-left: 19px;
}
.tabs-right .nav-tabs > li {
  float: none;
  margin-bottom: 2px;
}
.tabs-right .tab-content {
  padding: 0px;
}

@media (max-width: 767px) {
  .tabs .nav-tabs li {
    width: 100%;
    float: none;
    margin-bottom: 2px;
  }

  .tabs .nav-tabs li a {
    margin-right: 0 !important;
  }

  .tabs .nav-tabs li.active a {
    border-bottom: 2px solid #dcdcdc !important;
  }
}
@media (max-width: 568px) {
  .tabs-right .nav-tabs {
    float: none;
    margin-left: 0;
  }

  .tabs-left .nav-tabs {
    float: none;
    margin-left: 0;
  }
}
.tabs .nav-tabs li a {
  background: #f7f7f7;
  margin-right: 2px;
  border-left: 1px solid #f7f7f7;
  border-right: 1px solid #f7f7f7;
  border-bottom: none !important;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  padding: 12px 20px;
  color: #6d6d6d;
  position: relative;
}

.tabs .nav-tabs li a:after {
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  height: 3px;
}

.tabs .nav-tabs li a:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
}

.tabs .nav-tabs li a:hover {
  border-color: #f7f7f7;
}

.tabs .nav-tabs > li > a .fa {
  margin-right: 4px;
}

.tabs .nav-tabs li.active a {
  color: #32c5d2;
  border-left-color: #eee;
  border-right-color: #eee;
  background: #fff;
}

.tabs .nav-tabs li.active a:after {
  background: #32c5d2;
}

.tabs .nav-tabs li.active a:before {
  background: #fff;
}

.tabs .nav-tabs li.active a:hover {
  border-bottom-color: #fff;
}

.tabs .tab-content {
  padding: 15px;
  background: #fff;
}

.tab-content p {
  overflow: hidden;
}

.tabs .tab-content:before,
.tabs .tab-content:after {
  content: " ";
  display: table;
}

.tabs .tab-content:after {
  clear: both;
}

.tabs .tab-content p:last-child {
  margin-bottom: 0;
}

.form-element .form-control {
  height: 31px;
}

/***************************video home********************/
/*****************home ********
******************************/
#video-wrap {
  display: table;
  width: 100%;
  height: 580px;
  overflow-x: hidden;
  overflow-y: hidden;
  vertical-align: middle;
  z-index: 1;
}

.fallback-image {
  /* background-image: url(../img/showcase-5.jpg); */
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*.parallax-overlay{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../img/pattern.png);
    background-repeat: repeat;
    background-color: rgba(44,62,80,0.3);
    z-index: 2;
}*/
.video-wrap-content {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  z-index: 3;
}

.video-wrap-content h1 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 50px;
}

.video-wrap-content p {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 30px;
  margin-top: 25px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

@media (max-width: 433px) {
  #video-wrap {
    background-color: #444;
    display: table;
    width: 100%;
    height: 100%;
    height: 400px;
    overflow-x: hidden;
    overflow-y: hidden;
    vertical-align: middle;
    z-index: 1;
  }

  .video-wrap-content h1 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 30px;
  }

  .video-wrap-content p {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}
.popover-test button {
  margin: 5px;
}

/****************************************************************

Version v1.1 css*

****************************************************************/
.latest-tweets .tweet li {
  font-weight: 400;
}

.latest-tweets .tweet li a {
  color: #32c5d2;
}

/**********************sidenav*********************/
.side-nav li {
  border-bottom: 1px solid #e9e9e9;
}

.side-nav li a {
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.side-nav li a.active {
  color: #32c5d2;
}

.side-nav li a i {
  margin-right: 10px;
}

/*************pagination***********/
.gallery-bottom .pagination {
  margin-top: 0px;
}

.pagination > li > a, .pagination > li > span {
  background-color: #ccc;
  padding: 3px 9px;
  color: #fff;
  border: 0px;
}

.pagination > li > a {
  margin-right: 5px;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #32c5d2;
}

.jobs-row h4.panel-title {
  font-weight: 400;
  text-align: left;
}

.jobs-row h4.panel-title a {
  font-size: 15px;
}

.jobs-row h4.panel-title em {
  font-weight: 400;
  font-size: 12px;
  color: #999;
  display: block;
  margin-top: 0px;
}

/*****************search results*******************/
.search-area {
  padding: 20px 0;
  background-color: #f5f5f5;
}

.search-area .form-control {
  height: 38px;
}

.search-area .btn-lg {
  padding: 10px 17px 10px 25px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

span.results-number {
  display: block;
  color: #999;
  margin-bottom: 10px;
}

.results-box h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}

.results-box h3 a {
  color: #32c5d2;
}

.results-box h3 a:hover {
  text-decoration: underline;
}

.results-box .btn {
  top: -1px;
  padding: 0;
  position: relative;
  color: #999;
  border: none;
  font-size: 14px;
}

.results-box .btn:hover, .results-box .btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

.link-ul {
  padding: 0;
  margin-bottom: 0px;
}

.link-ul li a, .link-ul li a.btn-link {
  color: #999;
  font-size: 14px;
  margin-right: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  text-transform: capitalize;
}

.link-ul li {
  font-size: 12px;
}

.link-ul li a:hover {
  color: #32c5d2;
  text-decoration: underline;
}

.results-box p {
  margin: 10px 0;
  color: #666;
  font-weight: 400;
}

.results-sidebar-box ul li a {
  color: #999;
}

.results-sidebar-box ul li a:hover {
  text-decoration: underline;
  color: #32c5d2;
}

.results-sidebar-box h4 {
  font-size: 14px;
  color: #333;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.view-all {
  color: #999;
  font-size: 12px;
  display: block;
}

/*-----------------------------------------------------------------------------------*/
/*	Process
/*-----------------------------------------------------------------------------------*/
.process h2 {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 22px;
}

.process-step {
  margin: 0 auto;
  width: 700px;
  overflow: hidden;
  list-style: none;
  padding: 0;
  position: relative;
}

.process-border {
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #dadada;
  height: 100%;
}

.step {
  width: 700px;
  float: right;
  text-align: left;
  margin: 0 0 10px;
  padding: 10px 0 0 405px;
  position: relative;
}

.step.even {
  float: left;
  text-align: right;
  padding: 10px 405px 0 0;
}

.step h5 {
  color: #3f8dbf;
}

.step .icon-square {
  position: relative;
  margin-left: 10px;
}

.step.even .icon-square {
  margin-right: 10px;
}

.step .icon-square:before {
  content: "";
  display: block;
  width: 65px;
  border-bottom: 1px solid #dadada;
  position: absolute;
  top: 35px;
  left: -65px;
}

.step.even .icon-square:before {
  left: auto;
  right: -65px;
}

.icon-square {
  display: inline-block;
}

.icon-square i {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  text-align: center;
  color: #ddd;
  font-size: 40px;
  padding: 0;
  margin: 0 0 10px 0;
  display: table;
  width: 70px;
  height: 70px;
  border: 2px solid #ddd;
  -moz-transition: all 200ms ease-in;
  -o-transition: all 200ms ease-in;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.step:hover .icon-square i {
  background-color: #32c5d2;
  color: #FFF;
}

.icon-square i:before {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.step h5 {
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  font-size: 20px;
}

@media (max-width: 767px) {
  .process-step {
    width: 100%;
  }

  .step {
    width: 100%;
    float: none;
    text-align: center;
    margin: 0 0 30px;
    padding: 0;
    position: relative;
  }

  .step.even {
    float: none;
    text-align: center;
    padding: 0;
  }

  .step .icon-square:before {
    border: 0px;
  }

  .process-border {
    border: 0;
  }
}
/*******************Process inline*****/
.process-inline {
  margin-top: 50px;
  padding-top: 80px;
  background-color: #eee;
}

.Process-col {
  padding: 80px 30px;
  text-align: center;
}

.Process-col.one {
  background-color: rgba(0, 0, 0, 0.3);
}

.Process-col.two {
  background-color: rgba(0, 0, 0, 0.5);
}

.Process-col.three {
  background-color: rgba(0, 0, 0, 0.7);
}

.Process-col.four {
  background-color: black;
}

.Process-col i {
  font-size: 40px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #fff;
  color: #fff;
}

.Process-col h4 {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0;
}

/**********************invoice page************************/
body.invoice {
  background-color: #ECEEF4;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}

@media (min-width: 992px) {
  .container.invoice-outer {
    width: 970px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
  }
}
.invoice-outer {
  background-color: #fff;
  min-height: 400px;
  padding: 15px;
  line-height: 22px;
  border: 1px solid #ddd;
}

.invoice-header h2 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 26px;
}

@media (min-width: 768px) {
  .invoice-header-right {
    text-align: right;
  }

  .invoice-header-right h5 {
    margin-bottom: 0px;
  }
}
.invoice-header-right h5 {
  font-size: 17px;
}

.about-client ul strong {
  font-weight: 700;
  margin-right: 5px;
}

.invoice .panel-default > .panel-heading {
  border-color: #ddd !important;
}

.copyright-info {
  text-align: center;
  display: block;
  color: #999;
  font-size: 12px;
}

/****************login register calssic****************/
.login-regiter-tabs .nav-tabs {
  border: 0px;
}
.login-regiter-tabs .nav-tabs > li.active > a, .login-regiter-tabs .nav-tabs > li.active > a:hover, .login-regiter-tabs .nav-tabs > li.active > a:focus {
  color: #fff;
  cursor: default;
  background-color: #fff;
  border-bottom-color: #32c5d2;
  background-color: #32c5d2;
  border-color: #32c5d2;
}
.login-regiter-tabs .nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  text-transform: uppercase;
  border: 1px solid #ddd;
  padding: 6px 45px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  margin-right: 20px;
}
@media (max-width: 568px) {
  .login-regiter-tabs .nav-tabs > li > a {
    padding: 6px 25px;
  }
}
.login-regiter-tabs .nav-tabs > li > a:hover {
  border-color: #32c5d2;
  background-color: transparent;
}
.login-regiter-tabs .tab-content {
  margin-top: 40px;
  padding: 20px 0;
}
.login-regiter-tabs .tab-content p a {
  text-transform: uppercase;
  font-size: 10px;
  text-decoration: underline;
}

/************maintenance page***************/
.maintenance-page {
  background-color: #ddd;
}

.maintenance-page .main-text {
  font-size: 40px;
  font-weight: 400;
  font-style: normal;
}

.maintenance-page .main-text span {
  font-size: 20px;
  font-weight: 400;
  display: block;
  font-style: normal;
  margin-top: 30px;
}

.maintenance-box h4 {
  color: #000;
  font-weight: 400;
}

.maintenance-box i {
  font-size: 40px;
  margin-bottom: 20px;
}

/****dropdown login***/
.dropdown-login-box {
  overflow: hidden;
  padding: 15px !important;
  min-width: 300px !important;
  background-color: #fff !important;
}

.dropdown-login-box .btn {
  margin-top: 10px;
}

.dropdown-login-box h4 {
  margin: 5px 0 10px 0;
}

.dropdown-login-box h4 em {
  font-size: 12px;
  color: #32c5d2;
  font-weight: 400;
}

.dropdown-login-box input[type=checkbox] {
  margin: 5px -20px 0;
  line-height: normal;
}

.dropdown-login-box p {
  margin: 0;
}

.dropdown-login-box p a {
  color: #32c5d2;
}

.dropdown-login-box p a:hover {
  color: #32c5d2;
  text-decoration: underline;
}

/************************************ version v1.2.1 css *****************/
/********************

VERSION V1.3 css start here

*************************************************/
/******************

MULTI LEVEL DROPDOWN CSS

*********************/
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: -2px;
  left: 100%;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}

@media (max-width: 767px) {
  .dropdown-submenu > .dropdown-menu {
    padding-left: 20px;
  }
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  top: 7px;
  right: 9px;
  font-size: 11px;
  content: "\f105";
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}

@media (max-width: 767px) {
  .dropdown-submenu > .dropdown-menu {
    display: block;
    margin-left: 15px;
  }
}
/*******************new contact pages************************/
.sky-form-columns .btn-lg {
  padding: 14px 28px 13px 28px !important;
}

.sky-form-columns .sky-form {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

.sky-form-columns .sky-form footer {
  border: 0px;
  background-color: transparent;
  padding: 0px;
}

.sky-form-columns .sky-form fieldset {
  padding: 0px;
}

.contact-option-circle {
  text-align: center;
  background-color: #f5f5f5;
  padding: 25px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.contact-option-circle i {
  font-size: 35px;
  color: #888;
  display: block;
  margin-bottom: 10px;
}

.sky-form-columns .sky-form fieldset + fieldset {
  border-top: 0px;
}

/*******************dark dropdown code here*****************/
.navbar .dropdown-menu.dark-dropdown {
  border: 1px solid #444;
}

.navbar .dropdown-menu.dark-dropdown li a {
  color: #bbb;
}

.navbar .dropdown-menu.dark-dropdown li a:hover {
  color: #32c5d2;
  background-color: transparent;
}

.navbar .dropdown-menu.dark-dropdown, .navbar .dropdown-menu.dark-dropdown .yamm-content {
  background-color: #333;
}

.navbar .dropdown-menu.dark-dropdown li a {
  border-color: #000;
}

.navbar .dropdown-menu.dark-dropdown .yamm-content h3 {
  color: #fff;
}

.navbar .dropdown-menu.dark-dropdown .dropdown-submenu .dropdown-menu li a {
  border-color: #444 !important;
}

/**************intro text**************/
.intro-text-default {
  padding: 80px 0;
}

.intro-text-default h4 {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 35px;
  margin-bottom: 30px;
}

@media (max-width: 375px) {
  .intro-text-default h4 {
    font-size: 28px;
    line-height: 40px;
  }
}
.intro-text-default p {
  font-size: 16px;
}

.intro-text-1 {
  background: #05101b;
  padding: 40px 0;
}

.intro-text-1 h4 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
}

.intro-text-1 h4 strong {
  color: #32c5d2;
  font-weight: 700;
}

.intro-text-1 p {
  font-size: 16px;
  font-weight: 400;
  color: #999;
}

.intro-text-1 a {
  margin-top: 15px;
}

.intro-text-1.light {
  background-color: #f5f5f5;
}

.intro-text-1.light h4 {
  color: #333;
  text-transform: uppercase;
  font-weight: 700;
}

.intro-2nd h3 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 50px;
  font-size: 40px;
}

.intro-2nd p {
  font-size: 15px;
  line-height: 35px;
  font-weight: 400;
  color: #777;
  margin: 30px 0;
}

.testimonials-light {
  padding: 80px 0;
  background-color: #f8f8f8;
}

.testimonials-light h4 {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #000;
  font-style: normal;
}

/*******************portfolio with new hover style********************/
.featured-work {
  background-color: #f5f5f5;
  padding-top: 60px;
  position: relative;
}

.featured-work .owl-theme .owl-controls {
  margin-top: 10px;
  text-align: right;
  position: absolute;
  right: 20px;
  bottom: 0px;
}

.featured-work .owl-theme .owl-controls .owl-buttons div {
  color: #FFF;
  display: inline-block;
  zoom: 1;
  margin: 5px;
  padding: 3px 10px;
  font-size: 12px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  background: #32c5d2;
  filter: Alpha(Opacity=50);
  opacity: 0.5;
}

.work-wrap {
  position: relative;
  display: block;
}

.work-wrap .img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  text-align: center;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  overflow: hidden;
  display: block;
}

.work-wrap .img-overlay .inner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  margin-top: -51px;
}

.work-wrap .img-overlay .inner-overlay h2 {
  color: #434343;
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 18px;
  color: #32c5d2;
  margin-bottom: 6px;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  -moz-transform: translate3d(0, -400%, 0);
  -o-transform: translate3d(0, -400%, 0);
  -ms-transform: translate3d(0, -400%, 0);
  -webkit-transform: translate3d(0, -400%, 0);
  transform: translate3d(0, -400%, 0);
}

.work-wrap .img-overlay .inner-overlay p {
  color: #333;
  margin-bottom: 20px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -moz-transform: translate3d(0, -400%, 0);
  -o-transform: translate3d(0, -400%, 0);
  -ms-transform: translate3d(0, -400%, 0);
  -webkit-transform: translate3d(0, -400%, 0);
  transform: translate3d(0, -400%, 0);
}

.work-wrap .img-overlay .inner-overlay a.link {
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.work-wrap .img-overlay .inner-overlay a.zoom {
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.work-wrap .img-overlay .inner-overlay a {
  display: inline-block;
  text-decoration: none;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #32c5d2;
  text-align: center;
  margin: 0 5px;
  -moz-transform: translate3d(0, 400%, 0);
  -o-transform: translate3d(0, 400%, 0);
  -ms-transform: translate3d(0, 400%, 0);
  -webkit-transform: translate3d(0, 400%, 0);
  transform: translate3d(0, 400%, 0);
}

.work-wrap .img-overlay .inner-overlay a i {
  font-size: 14px;
  color: #32c5d2;
  line-height: 40px;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.work-wrap:hover .img-overlay {
  opacity: 1;
}

.work-wrap:hover .img-overlay .inner-overlay h2 {
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.work-wrap:hover .img-overlay .inner-overlay p {
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.work-wrap:hover .img-overlay .inner-overlay a {
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.fun-fact-wrap-light {
  background-color: #eee;
  padding-top: 100px;
  padding-bottom: 70px;
}

.fun-fact-wrap-light .facts-in h3 .counter {
  color: #000;
}

.fun-fact-wrap-light .facts-in h4 {
  color: #000;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-transform: lowercase;
  font-style: normal;
}

.fun-facts-bg {
  background: #32c5d2;
}

/***theme features box***/
.assan-box {
  margin-bottom: 30px;
}

.assan-box img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.assan-box h4 {
  padding: 0px 15px;
  text-transform: capitalize;
  font-weight: 400;
  color: #000;
  margin: 0px;
  line-height: 55px;
}

.assan-box p {
  padding: 0px 20px;
}

/************************home events**********************/
.events-bg {
  /* background: url(../img/showcase-1.jpg); */
  padding: 150px 0;
}

.events-bg h2 {
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 700;
}

.events-bg h4 {
  color: #fff;
  margin-bottom: 30px;
  font-weight: 400;
}

.event-digit-box h1 {
  font-weight: 400;
  color: #777;
  margin-bottom: 25px;
}

.event-digit-box h4 {
  color: #000;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
}

.event-speakers {
  padding-top: 60px;
  padding-bottom: 30px;
  background-color: #f7f7f7;
}

/***schedule**/
.event-box {
  padding: 15px;
  background-color: #fff;
  border: 2px solid #f5f5f5;
  margin-bottom: 15px;
}

.event-box .author {
  color: #000;
  font-weight: 700;
  display: block;
}

.event-box .title {
  margin: 15px 0;
}

.event-box .time {
  padding: 6px 12px;
  display: inline-block;
  color: #fff;
  background-color: #32c5d2;
}

.event-box p {
  font-size: 14px;
}

.event-gallery {
  padding: 80px 0;
  background-color: #eee;
}

.register-event {
  /* background: url(../img/showcase-4.jpg); */
  padding: 100px 0;
}

.register-event .center-heading h2 {
  color: #fff;
}

.register-event .center-heading p {
  color: #fff;
}

.event-price-box {
  padding: 45px 15px;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
}

.event-price-box h3 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 25px;
}

.event-price-box h5 {
  font-size: 20px;
  margin: 0px;
  text-transform: capitalize;
}

.event-price-box h3, .event-price-box h5 {
  color: #fff;
}

.register-text-box {
  text-align: center;
}

.register-text-box h3 {
  color: #fff;
}

/************no space columns end******/
.col-one-fourth {
  width: 25%;
  float: left;
}

@media (max-width: 767px) {
  .col-one-fourth {
    width: 50%;
  }
}
@media (max-width: 568px) {
  .col-one-fourth {
    width: 100%;
  }
}
/****massonary****/
.mas-boxes-inner {
  padding: 0px;
  margin-bottom: 30px;
  border: 1px solid #eee;
  border-bottom: 3px solid #f5f5f5;
  padding: 5px;
}

.mas-blog-inner {
  padding: 20px;
  background-color: #fff;
}

.mas-blog-inner h3 {
  text-transform: capitalize;
}

.mas-blog-inner .post-detail {
  margin-top: 0px;
  margin-bottom: 10px;
}

.mas-blog-inner h3 {
  margin-bottom: 15px;
  font-weight: 400;
}

/*************NEW PORTFOLIO IN V1.4****************/
.filter {
  text-align: center;
  margin-bottom: 30px;
}

.filter li a.active {
  color: #32c5d2;
  border-color: #32c5d2;
}

.filter li a {
  display: inline-block;
  margin: 3px 5px;
  text-transform: uppercase;
  font-size: 11px;
  font-style: normal;
  color: #bbb;
  font-weight: 400;
  padding: 4px 12px;
  border: 2px solid #f5f5f5;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}

.filter li a:hover {
  color: #32c5d2;
  border-color: #32c5d2;
}

.col-2-space .project-post {
  width: 50%;
  padding: 15px;
}

.col-2-no-space .project-post {
  width: 50%;
  padding: 0;
  margin: 0;
}

.col-3-space .project-post {
  width: 33.33%;
  padding: 15px;
}

.col-3-no-space .project-post {
  width: 33.333%;
  padding: 0;
  margin: 0;
}

.col-4-space .project-post {
  width: 25%;
  padding: 15px;
}

.col-4-no-space .project-post {
  width: 25%;
  padding: 0;
  margin: 0;
}

.col-5-no-space .project-post {
  width: 20%;
  padding: 0;
  margin: 0;
}

@media (max-width: 767px) {
  .col-2-space .project-post, .col-2-no-space .project-post, .col-3-space .project-post, .col-3-no-space .project-post, .col-4-space .project-post, .col-4-no-space .project-post, .col-5-no-space .project-post {
    width: 50%;
  }
}
@media (max-width: 568px) {
  .col-2-space .project-post, .col-2-no-space .project-post, .col-3-space .project-post, .col-3-no-space .project-post, .col-4-space .project-post, .col-4-no-space .project-post, .col-5-no-space .project-post {
    width: 100%;
  }
}
/***new icon overlay***/
.img-icon {
  position: relative;
  overflow: hidden;
}

.img-icon img {
  width: 100%;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.img-icon-overlay {
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.img-icon-overlay p {
  position: absolute;
  width: 100%;
  margin-top: -20px;
  top: 50%;
  text-align: center;
}

.img-icon-overlay p a {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.99);
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 5px;
  text-align: center;
  color: #333;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  font-size: 20px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.img-icon-overlay p a img {
  width: 20px;
  height: auto;
}

.img-icon-overlay p a:hover {
  color: #32c5d2;
}

.img-icon:hover .img-icon-overlay {
  opacity: 1;
}

.img-icon:hover img {
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

/****construction page css***/
.construction-service-box {
  margin-bottom: 30px;
}

.service-thumb {
  position: relative;
}

.construction-service-box .services-desc h3 {
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
}

.construction-service-box .services-desc p {
  margin-bottom: 5px;
  line-height: 27px;
}

.constro-cta {
  /* background: url(../img/construction/constro-bg-2.jpg); */
  padding: 80px 0;
  text-align: center;
}

.constro-cta p {
  color: #fff;
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 20px;
}

.constro-cta h1 {
  color: #fff;
  text-transform: uppercase;
}

.constro-cta i {
  display: block;
  color: #fff;
  font-size: 50px;
}

.constro-cta a {
  margin-top: 40px;
}

.constro-cta a i {
  font-size: 12px;
  margin-left: 5px;
  display: inline-block;
}

.construction.testimonials-v-2 {
  background-color: #f5f5f5;
}

/****cube portfolio*******/
.cube-masonry .cbp-l-filters-alignCenter .cbp-filter-item {
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
  font-size: 15px;
}

.cube-masonry .cbp-l-filters-alignCenter .cbp-filter-item.cbp-filter-item-active {
  color: #32c5d2;
}

.cube-masonry .cbp-l-filters-alignCenter .cbp-filter-item:hover {
  color: #32c5d2;
}

.portfolio-cube .cbp-l-caption-buttonLeft, .portfolio-cube .cbp-l-caption-buttonRight {
  background-color: #32c5d2;
}

.portfolio-cube .cbp-l-caption-buttonLeft:hover, .portfolio-cube .cbp-l-caption-buttonRight:hover {
  background-color: #32c5d2;
}

.cube-masonry .cbp-l-filters-alignRight .cbp-filter-item.cbp-filter-item-active {
  color: #FFFFFF;
  background-color: #32c5d2;
  border-color: #32c5d2;
  cursor: default;
}

.cube-masonry .cbp-l-filters-alignRight .cbp-filter-counter {
  background: #32c5d2;
}

.cube-masonry .cbp-l-filters-alignRight .cbp-filter-counter:before {
  border-top-color: #32c5d2;
}

.btn-sweet-alert {
  margin: 5px;
}

.portfolio-cube .cbp-l-grid-projects-title {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

.portfolio-cube .cbp-l-filters-button .cbp-filter-item {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

.portfolio-cube .cbp-l-filters-button .cbp-filter-item.cbp-filter-item-active {
  background-color: #32c5d2;
  color: #fff;
  border-color: #32c5d2;
  cursor: default;
}

.portfolio-cube {
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
}

.cube-masonry .cbp-l-caption-title {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

.cube-masonry .cbp-l-caption-desc {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

/************pace style for page pre-loader********/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #32c5d2;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #32c5d2, 0 0 5px #32c5d2;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #32c5d2;
  border-left-color: #32c5d2;
  border-radius: 10px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  -moz-animation: pace-spinner 400ms linear infinite;
  -ms-animation: pace-spinner 400ms linear infinite;
  -o-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite;
}

@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/****************

SOCIAL BUTTONS

**********************/
/**social icons default size**/
.social-icon {
  margin: 0 5px 5px 0;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px !important;
  color: #555;
  text-shadow: none;
  border-radius: 3px;
  overflow: hidden;
  display: block;
  float: left;
  text-align: center;
  border: 1px solid #AAA;
}
.social-icon:hover {
  border-color: transparent;
}

.social-icon i {
  display: block;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  line-height: 40px;
  position: relative;
}
.social-icon i:last-child {
  color: #FFF !important;
}

.social-icon:hover i:first-child {
  margin-top: -40px;
}

/***social icons lg (big)***/
.social-icon-lg {
  margin: 0 5px 5px 0;
  width: 60px;
  height: 60px;
  font-size: 30px;
  line-height: 60px !important;
  color: #555;
  text-shadow: none;
  border-radius: 3px;
  overflow: hidden;
  display: block;
  float: left;
  text-align: center;
  border: 1px solid #AAA;
}
.social-icon-lg:hover {
  border-color: transparent;
}

.social-icon-lg i {
  display: block;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  line-height: 60px;
  position: relative;
}
.social-icon-lg i:last-child {
  color: #FFF !important;
}

.social-icon-lg:hover i:first-child {
  margin-top: -60px;
}

/***social icons small***/
.social-icon-sm {
  margin: 0 5px 5px 0;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px !important;
  color: #555;
  text-shadow: none;
  border-radius: 3px;
  overflow: hidden;
  display: block;
  float: left;
  text-align: center;
  border: 1px solid #AAA;
}
.social-icon-sm:hover {
  border-color: transparent;
}

.social-icon-sm i {
  display: block;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  line-height: 30px;
  position: relative;
}
.social-icon-sm i:last-child {
  color: #FFF !important;
}

.social-icon-sm:hover i:first-child {
  margin-top: -30px;
}

si-border {
  border: 1px solid #AAA !important;
}

.si-border-round {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.si-dark-round {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.si-gray-round {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.si-gray {
  background: #f3f3f3;
  border: 0px;
}

.si-dark {
  background-color: #333;
  border: 0px !important;
  color: #fff !important;
}

/**icons hover colored**/
.si-colored-facebook, .si-facebook:hover {
  background-color: #3B5998 !important;
}

.si-colored-twitter, .si-twitter:hover {
  background-color: #00ACEE !important;
}

.si-colored-google-plus, .si-g-plus:hover {
  background-color: #DD4B39 !important;
}

.si-colored-skype, .si-skype:hover {
  background-color: #00AFF0 !important;
}

.si-linkedin:hover, .si-colored-linkedin {
  background-color: #0E76A8 !important;
}

.si-pin:hover, .si-colored-pinterest {
  background-color: #C8232C !important;
}

.si-rss:hover, .si-colored-rss {
  background-color: #EE802F !important;
}

.si-pinterest:hover, .si-colored-pinterest {
  background-color: #C8232C !important;
}

.si-tumblr:hover, .si-colored-tumblr {
  background-color: #34526F !important;
}

.si-vimeo:hover, .si-colored-vimeo {
  background-color: #86C9EF !important;
}

.si-digg:hover, .si-colored-digg {
  background-color: #191919 !important;
}

.si-instagram:hover, .si-colored-instagram {
  background-color: #3F729B !important;
}

.si-flickr:hover, .si-colored-flickr {
  background-color: #FF0084 !important;
}

.si-paypal:hover, .si-colored-paypal {
  background-color: #00588B !important;
}

.si-yahoo:hover, .si-colored-yahoo {
  background-color: #720E9E !important;
}

.si-android:hover, .si-colored-andriod {
  background-color: #A4C639 !important;
}

.si-appstore:hover, .si-colored-apple {
  background-color: #000 !important;
}

.si-dropbox:hover {
  background-color: #3D9AE8 !important;
}

.si-dribbble:hover, .si-colored-dribbble {
  background-color: #EA4C89 !important;
}

.si-soundcloud:hover, .si-colored-soundcoloud {
  background-color: #F70 !important;
}

.si-xing:hover, .si-colored-xing {
  background-color: #126567 !important;
}

.si-phone:hover, .si-colored-phone {
  background-color: #444 !important;
}

.si-behance:hover, .si-colored-behance {
  background-color: #053eff !important;
}

.si-github:hover, .si-colored-github {
  background-color: #171515 !important;
}

.si-stumbleupon:hover, .si-colored-stumbleupon {
  background-color: #F74425 !important;
}

.si-email:hover, .si-colored-email {
  background-color: #6567A5 !important;
}

.si-wordpress:hover, .si-colored-wordpress {
  background-color: #1E8CBE !important;
}

.social-shortcodes .left-heading {
  font-size: 30px;
  text-transform: uppercase;
}

.grid-boxed {
  border: 1px solid transparent;
  padding: 20px;
}

.grid-boxed:hover {
  border: 1px dashed #999;
}

/**********************

UNDER CUNSTRUCTION PAGES v1.9

***********************/
.coming-soon {
  /* background: url(../img/bg-3.jpg); */
  padding: 100px 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 0;
}
.coming-soon .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.coming-soon h1 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 60px;
  text-transform: uppercase;
  font-size: 40px;
  line-height: 55px;
}

.counts {
  width: 120px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 30px;
  display: inline-block;
  margin-bottom: 10px;
}
.counts span {
  color: #fff;
  font-size: 50px;
  display: block;
  margin-bottom: 20px;
}
.counts p {
  text-transform: uppercase;
  color: #fff;
}

.count-down-1 {
  position: relative;
}
.count-down-1 .counts {
  width: 120px;
  text-align: center;
  display: inline-block;
  padding-top: 25px;
  padding-bottom: 12px;
  position: relative;
}
.count-down-1 span {
  color: #fff;
  text-shadow: 1px 2px rgba(255, 255, 255, 0.1);
  display: block;
  font-size: 50px;
  margin-bottom: 30px;
}
.count-down-1 p {
  margin-bottom: 0px;
  color: #eee;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
}

.soon-inner h3 {
  color: #000;
  text-transform: uppercase;
}
.soon-inner .form-control {
  height: 48px;
  font-size: 15px;
}
.soon-inner .form-control.error {
  border-color: red;
  color: red;
}
.soon-inner .form-control.success {
  border-color: green;
  color: green;
}

/****coming soon fullscreen***/
body.coming-soon-full {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.coming-soon-fullscreen {
  width: 100%;
  /* background: url(../img/bg-4.jpg); */
  background-attachment: fixed;
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: table;
  min-height: 100%;
  position: relative;
  z-index: 1;
}
.coming-soon-fullscreen .v-middle {
  display: table-cell;
  vertical-align: middle;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
.coming-soon-fullscreen .v-middle h1 {
  font-size: 40px;
  text-transform: uppercase;
}

.pe-icon-box {
  font-size: 50px;
  width: 90px;
  height: 90px;
  line-height: 90px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #eee;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
  color: #666;
}

.buttons {
  margin-bottom: 60px;
}
.buttons a {
  margin: 5px;
}
.buttons h2 {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 25px;
}

/**features list at home default**/
.more-features {
  padding: 60px 0;
  background-color: #333;
  color: #fff;
}
.more-features li {
  padding: 12px 0;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.more-features li i {
  margin-right: 12px;
  vertical-align: middle;
}

.font-box .font_awesome a {
  padding: 10px 0;
  color: #a6a8a9;
  display: block;
}
.font-box .font_awesome a i {
  font-size: 25px;
  display: block;
  color: #676a6c;
}
.font-box h3 {
  margin-bottom: 20px;
  font-weight: 600;
}
.font-box > div {
  padding-bottom: 50px;
}

.social-icons:after, .social-icons:before > .divide10:after, .social-icons .divide10:before {
  display: table;
  content: "";
  width: 100%;
  clear: both;
}

/*********revolution slider 5 css*****/
.tp-caption.NotGeneric-Title, rev-btn, .NotGeneric-Title, .tp-caption.NotGeneric-SubTitle, .NotGeneric-SubTitle, .tp-caption.Gym-Display, .Gym-Display, .tp-caption.Gym-Subline, .Gym-Subline, .tp-caption.Gym-SmallText, .Gym-SmallText, .tp-caption.Gym-Button, .Gym-Button, .rev-btn {
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
}

.tp-caption.Gym-Button, .Gym-Button {
  background-color: #32c5d2;
}

.new-label {
  color: #32c5d2;
}

/***self hosted video css***/
/**video css new**/
.video-section {
  width: 100%;
  height: 600px;
  position: relative;
  display: table;
  overflow: hidden;
}

.video-section .overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video-section .fs-background-container {
  left: 0;
}

.video-section .video-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  z-index: 2;
}

.video-section .video-overlay h1 {
  font-weight: 700;
  color: #fff;
  font-size: 70px;
  line-height: 70px;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.video-section .video-overlay p {
  color: #fff;
  margin-bottom: 25px;
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .hidden-mobile {
    display: block;
  }
  .hidden-desktop {
    display: none;
  }
  .navbar-default .navbar-nav {
    display: flex;
    flex-direction: row;
  }
  .navbar-default .navbar-nav > li {
    background: url(../img/nav-bg.png) no-repeat scroll right center;
  }
  .navbar-default .navbar-nav > li.last {
      background: none;
  }
  #footer {
    padding: 40px 0 0 0;
  }
}