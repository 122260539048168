.carousel-inner {
    max-height: 580px;
}

.carousel-caption {
    right: 15%;
    top: 80px;
    left: 20%;
}

.carousel-content-1, .carousel-content-3 {
    display: none;
    padding: 20px;
    font-size: 28px;
    line-height: 32px;
    font-weight: 800;
    background-color: #de543e;
    color: #ffffff;
}

.carousel-content-2 {
    display: none;
    .title {
        display: flex;
        p {
            background-color: #4e5b6c;
            color: #ffffff;
            min-height: 0px; 
            min-width: 0px; 
            line-height: 26px; 
            border-width: 0px; 
            margin: 10px 0px; 
            padding: 10px 30px; 
            letter-spacing: 0px; 
            font-size: 22px; 
            font-weight: 800;
        }
    }    
    .checkpoint {
        display: flex;
        flex-direction: row;
        .check {
            width: 40px;
            height: 40px;
            background-color: #4e5b6c;
            line-height: 20px; 
            border-width: 0px; 
            margin: 10px 10px 10px 0px; 
            padding: 10px; 
            letter-spacing: 0px; 
            font-size: 20px; 
            i {
                min-height: 0px; 
                min-width: 0px; 
                line-height: 20px; 
                border-width: 0px; 
                margin: auto; 
                padding: 0px; 
                letter-spacing: 0px; 
                font-size: 20px;
            }
        }
        .point {
            background-color: #de543e;
            min-height: 0px; 
            min-width: 0px; 
            line-height: 36px; 
            border-width: 0px; 
            margin: 10px 0px; 
            padding: 1px 10px 3px; 
            letter-spacing: 0px; 
            font-size: 30px; 
            font-weight: 200;
        }
    }
    .more {
        display: flex;
        margin: 10px 0px;
        p {
            padding: 10px;                  
            background-color: #32c5d2;
            &:hover {
                background-color: #4e5b6c;
            }
            a {
                color: #ffffff;
                min-height: 0px; 
                min-width: 0px; 
                line-height: 17px; 
                border-width: 1px; 
                margin: 5px; 
                padding: 14px 28px 13px; 
                letter-spacing: 0px; 
                font-size: 13px;
                font-weight: 600;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .carousel-content-1, .carousel-content-2, .carousel-content-3 {
        display: block;
    }
}